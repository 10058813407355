import React, { useEffect, useState, useRef } from "react";
import { Pagination, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";

//Components
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import RenderNoData from "components/RenderNoData";
import ShowPerPage from "components/ShowPerPage";
import PopupRatingProduct from "components/Popups/PopupRatingProduct/PopupRatingProduct";

//Utils
import useRetailers from "../../../../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";
import { formatDate } from "utils/formatDate";
import { specificationsTrendAnalysis } from "utils/ratingHelper";
import { getTimePeriod } from "utils/getTimePeriod";
import useFilters from "../../../../../hooks/useFilters";

//Images
import searchIcon from "assets/images/search.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import star from "assets/images/star.svg";

//Actions
import { fetchTrendAnalysisTable, fetchTrendAnalysisTableAll } from "store/rating/trendAnalysisTable/actions";
import {
  fetchRatingSummaryProduct,
  fetchRatingSummaryReviews,
  fetchRatingSummaryReviewsAll,
} from "store/rating/ratingSummaryProduct/actions";

const TrendAnalysisBottom = ({ arrayOfDesiredBrand }) => {
  //Selects
  const { statusFilters, mainLastFilter: lastFilter } = useFilters();
  const { trendAnalysisTable, trendAnalysisTableAll, status } = useSelector((state) => state.trendAnalysisTable);
  const { filter } = useSelector((state) => state.trendAnalysis);
  const {
    ratingSummaryProduct,
    ratingSummaryReviews,
    ratingSummaryReviewsAll,
    status: ratingSummaryProductStatus,
  } = useSelector((state) => state.ratingSummaryProduct);

  //States
  const [queryParams, setQueryParams] = useState({});
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortId, setSortId] = useState("title");
  const [popupData, setPopupData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [avgRating, setAvgRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [combinedCoreRetailers, setCombinedCoreRetailers] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");

  //Constants
  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();
  const debounceTimeout = useRef(null);

  const baseQuery = {
    search: "",
    limit: 10,
    page: 1,
    sort: "title|asc",
  };
  const byRetailer = filter[1].value === "retailer";
  const byBrand = filter[1].value === "brand";
  const byTotal = filter[1].value === "total";
  const key = byBrand ? "byBrand" : byRetailer ? "byRetailer" : "byTotal";

  // form query
  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    const brands = byBrand && arrayOfDesiredBrand.length ? arrayOfDesiredBrand.map(({ id }) => id).join("|") : "All";
    const retailers =
      byRetailer && arrayOfDesiredBrand.length ? arrayOfDesiredBrand.map(({ id }) => id).join("|") : "All";

    setQueryParams((prevState) => ({
      ...prevState,
      ...baseQuery,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      brands,
      retailers,
    }));
    setSearch("");
  }, [lastFilter, arrayOfDesiredBrand, statusFilters]);

  useEffect(() => {
    setSearch("");
  }, [arrayOfDesiredBrand, filter[1].value]);

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    if (!filter[2].value) {
      if (Object.keys(queryParams).length) {
        dispatch(fetchTrendAnalysisTable(queryParams));
      }
    } else {
      if (Object.keys(queryParams).length) {
        dispatch(fetchTrendAnalysisTableAll(queryParams));
      }
    }
  }, [queryParams, statusFilters, filter, dispatch]);

  useEffect(() => {
    if (filter[2].value) {
      if (Object.keys(queryParams).length) {
        dispatch(fetchTrendAnalysisTableAll(queryParams));
      }
    } else {
      if (Object.keys(queryParams).length) {
        dispatch(fetchTrendAnalysisTable(queryParams));
      }
    }
  }, [queryParams, dispatch, filter[2].value]);
  
  const handleSearch = (value) => {
    setSearch(value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      setDebouncedSearch(value);
    }, 800); 
  };

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      search: debouncedSearch,
    }));
  }, [debouncedSearch]);

  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const changeLimitHandler = (limit) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      limit,
    }));
  };

  const changePageHandler = (page) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const fetchProduct = (id) => {
    const data = {
      coreRetailerIds: id.join("|"),
      limit: "10",
      page: "1",
      sort: "date|desc",
      timePeriod: getTimePeriod(lastFilter.date),
    };
    dispatch(fetchRatingSummaryProduct({ id: id[0] }));
    if (filter[2].value) {
      dispatch(fetchRatingSummaryReviewsAll(data));
    } else {
      dispatch(fetchRatingSummaryReviews(data));
    }

    setShowPopup(true);
  };

  const popupContent = (el) => {
    return (
      <div className="popup-wrapper">
        <div className="rating-popup-trend-bottom">
          <span>Last Review</span>
          <span>{formatDate(el.recentReview)}</span> 
        </div>
        <span
          onClick={() => {
            const ids = el.combinedCoreRetailers || [el.coreRetailers];
            setAvgRating(el.avgRating);
            setReviewCount(el.reviewCount);
            setCombinedCoreRetailers(el.combinedCoreRetailers);
            fetchProduct(ids);
          }}
        >
          Show Reviews
        </span>
      </div>
    );
  };

  const renderCellItem = (el) => {
    return specificationsTrendAnalysis.map(({ key }, index) => {
      if (byTotal && key === "groupName") {
        return;
      } else {
        return (
          <Popover
            content={() => popupContent(el)}
            title={el.title}
            trigger="hover"
            overlayStyle={{ width: 200 }}
            key={index}
          >
            <div className="table-item">
              {key === "groupName" && byRetailer ? (
                <span className="groupNameImg">
                  <img src={getRemoteLogo(el[key])} alt="" />
                </span>
              ) : key === "groupName" && byBrand ? (
                <span className="groupName">
                  <span className="groupNameColor" style={{ background: el.color }}></span>
                  <span>{el[key]}</span>
                </span>
              ) : null}
              {key === "avgRating" ? (
                <span>
                  {el[key]}
                  <img src={star} alt="" />
                </span>
              ) : null}

              {key !== "groupName" && key !== "avgRating" ? <span>{+el[key] === 0 ? "" : +el[key]}</span> : null}
            </div>
          </Popover>
        );
      }
    });
  };

  const renderTotalItem = (el) => {
    return specificationsTrendAnalysis.map(({ key }, index) => {
      if (byTotal && key === "groupName") {
        return;
      } else {
        return (
          <div key={index} className="table-item total">
            {key === "groupName" ? (
              <span>
                {el.groupCount} {byRetailer ? "retailer" : byBrand ? "brand" : "total"}
                {el.groupCount > 1 ? "s" : ""}
              </span>
            ) : null}
            {key === "avgRating" ? (
              <span>
                {el[key]}
                <img src={star} alt="" />
              </span>
            ) : null}
            {key !== "groupName" && key !== "avgRating" ? <span>{el[key]}</span> : null}
          </div>
        );
      }
    });
  };

  return (
    <>
      <Styles>
        <div className="current-table-box">
          <div className="wrapper-table-box">
            <div className="wrapper-table">
              <div className="box-wrapper">
                <div className="title-search">
                  <div className="search">
                    <img src={searchIcon} alt="search" />
                    <input
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search for a Product"
                      value={search}
                    />
                    {(filter[2].value
                      ? trendAnalysisTableAll[key]?.total?.productCount
                      : trendAnalysisTable[key]?.total?.productCount) > 0 && (
                      <img
                        src={sortId === "title" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                        alt="sort"
                        className="search-sort-btn"
                        onClick={() => handleSort("title")}
                      />
                    )}
                  </div>
                </div>
                <div className="wrapper">
                  {filter[2].value
                    ? trendAnalysisTableAll[key]?.result.length > 0
                      ? specificationsTrendAnalysis.map((item, index) => {
                          let key;
                          key = item.key;
                          if (byTotal && key === "groupName") {
                            return null;
                          } else {
                            return (
                              <div
                                className="table-item sort"
                                key={index}
                                onClick={
                                  trendAnalysisTableAll[key]?.total?.productCount === 0
                                    ? () => {}
                                    : () => handleSort(key)
                                }
                              >
                                <span className="">
                                  {item.key === "groupName" && byRetailer
                                    ? "Retailer"
                                    : item.key === "groupName" && byBrand
                                    ? "Brand"
                                    : item.key === "groupName" && byTotal
                                    ? "Total"
                                    : item.value}
                                </span>
                                {item.key === "groupName" ||
                                item.key === "reviewCount" ||
                                item.key === "avgRating" ? null : (
                                  <img src={star} alt="" />
                                )}
                                <img
                                  src={sortId === key ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                                  alt="sort"
                                />
                              </div>
                            );
                          }
                        })
                      : null
                    : trendAnalysisTable[key]?.result.length > 0
                    ? specificationsTrendAnalysis.map((item, index) => {
                        let key;
                        key = item.key;
                        if (byTotal && key === "groupName") {
                          return null;
                        } else {
                          return (
                            <div
                              className="table-item sort"
                              key={index}
                              onClick={
                                trendAnalysisTable[key]?.total?.productCount === 0 ? () => {} : () => handleSort(key)
                              }
                            >
                              <span className="">
                                {item.key === "groupName" && byRetailer
                                  ? "Retailer"
                                  : item.key === "groupName" && byBrand
                                  ? "Brand"
                                  : item.key === "groupName" && byTotal
                                  ? "Total"
                                  : item.value}
                              </span>
                              {item.key === "groupName" ||
                              item.key === "reviewCount" ||
                              item.key === "avgRating" ? null : (
                                <img src={star} alt="" />
                              )}
                              <img
                                src={sortId === key ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                                alt="sort"
                              />
                            </div>
                          );
                        }
                      })
                    : null}
                </div>
              </div>
            </div>
            <div className="wrapper-table">
              <div className="wrapper-box-relative">
                {filter[2].value ? (
                  trendAnalysisTableAll[key]?.total?.productCount > 0 ? (
                    <div className="box-wrapper">
                      <div className="title total">
                        <span className="total">Total</span>
                      </div>
                      <div className="wrapper">{renderTotalItem(trendAnalysisTableAll[key].total)}</div>
                    </div>
                  ) : null
                ) : trendAnalysisTable[key]?.total?.productCount > 0 ? (
                  <div className="box-wrapper">
                    <div className="title total">
                      <span className="total">Total</span>
                    </div>
                    <div className="wrapper">{renderTotalItem(trendAnalysisTable[key].total)}</div>
                  </div>
                ) : null}
                {filter[2].value ? (
                  trendAnalysisTableAll[key]?.total?.productCount > 0 ? (
                    trendAnalysisTableAll[key].result.map((el, index) => (
                      <div className="box-wrapper" key={index}>
                        <div className="title">
                          <img className="image" src={el.image} alt="banner" />
                          <span>{el.title}</span>
                        </div>

                        <div onMouseOver={() => setPopupData(el)} className="wrapper">
                          {renderCellItem(el)}
                        </div>
                      </div>
                    ))
                  ) : +trendAnalysisTableAll[key]?.total?.productCount === 0 && trendAnalysisTableAll.success ? (
                    <RenderNoData />
                  ) : null
                ) : trendAnalysisTable[key]?.total?.productCount > 0 ? (
                  trendAnalysisTable[key].result.map((el, index) => (
                    <div className="box-wrapper" key={index}>
                      <div className="title">
                        <img className="image" src={el.image} alt="banner" />
                        <span>{el.title}</span>
                      </div>

                      <div onMouseOver={() => setPopupData(el)} className="wrapper">
                        {renderCellItem(el)}
                      </div>
                    </div>
                  ))
                ) : +trendAnalysisTable[key]?.total?.productCount === 0 && trendAnalysisTable.success ? (
                  <RenderNoData />
                ) : null}
                {status === STATE_STATUSES.PENDING ||
                (!ratingSummaryProduct.success && ratingSummaryProductStatus !== STATE_STATUSES.INIT) ? (
                  <LoaderBox />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div>
          {filter[2].value ? (
            trendAnalysisTableAll[key]?.total?.productCount > 0 ? (
              <div className="pagination-with-per-page">
                <Pagination
                  className="pagination-controls"
                  onChange={changePageHandler}
                  current={queryParams.page ? queryParams.page : 1}
                  pageSize={queryParams.limit ? queryParams.limit : 10}
                  total={trendAnalysisTableAll[key]?.total?.productCount}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                />
                <ShowPerPage setLimit={changeLimitHandler} value={queryParams.limit ? queryParams.limit : 10} />
              </div>
            ) : null
          ) : trendAnalysisTable[key]?.total?.productCount > 0 ? (
            <div className="pagination-with-per-page">
              <Pagination
                className="pagination-controls"
                onChange={changePageHandler}
                current={queryParams.page ? queryParams.page : 1}
                pageSize={queryParams.limit ? queryParams.limit : 10}
                total={trendAnalysisTable[key]?.total?.productCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
              <ShowPerPage setLimit={changeLimitHandler} value={queryParams.limit ? queryParams.limit : 10} />
            </div>
          ) : null}
        </div>
      </Styles>
      {showPopup && ratingSummaryProduct.success ? (
        <PopupRatingProduct
          product={ratingSummaryProduct}
          reviews={filter[2].value ? ratingSummaryReviewsAll : ratingSummaryReviews}
          closePopup={() => setShowPopup(false)}
          showRetailer={true}
          avgRating={avgRating}
          combinedCoreRetailers={combinedCoreRetailers}
          timePeriod={getTimePeriod(lastFilter.date)}
          showAllReviews={filter[2].value}
        />
      ) : null}
    </>
  );
};

export default TrendAnalysisBottom;