import React from 'react'
import { Tabs } from 'antd'

import LocationAnalysisTab from './LocationAnalysisTab/LocationAnalysisTab'

import about from 'assets/images/about.svg'
import { TabWithIcon } from 'assets/icons/icons'

const NavigationBottomTabs = ({ activeTabBottom, setActiveTab }) => {
    const { TabPane } = Tabs

    function callback(key) {
        setActiveTab(key)
    }

    return (
        <div className="main-box">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab={<TabWithIcon tabName={'Location Analysis'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="1">
                    <LocationAnalysisTab
                        isActive={activeTabBottom === 1}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default NavigationBottomTabs
