import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

//Components
import RowOfBrands from "components/RowOfBrands";
import RenderTooMoreData from "components/RenderTooMoreData";
import RenderNoData from "components/RenderNoData";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";
import { Checkbox } from "antd";

//Constants
import { RATING } from "constants/constants";
import { changeNameRetailer } from "utils/changeNameRetailer";

HC_more(Highcharts);

(function (H) {
  H.wrap(H.seriesTypes.bubble.prototype, "applyJitter", function (proceed) {
    var series = this,
      jitter = this.options.jitter,
      len = this.points.length,
      sLen = this.chart.series.length,
      seriesOffset = series.index / (sLen + 1);

    function unrandom(seed) {
      var rand = Math.sin(seed) * 10000;
      return rand - Math.floor(rand);
    }

    if (jitter) {
      this.points.forEach(function (point, i) {
        ["x", "y"].forEach(function (dim, j) {
          var axis,
            plotProp = "plot" + dim.toUpperCase(),
            min,
            max,
            translatedJitter;
          if (jitter[dim] && !point.isNull) {
            axis = series[dim + "Axis"];
            translatedJitter = jitter[dim] * axis.transA;
            if (axis && !axis.isLog) {
              min = Math.max(0, point[plotProp] - translatedJitter);
              max = Math.min(axis.len, point[plotProp] + translatedJitter);
              point[plotProp] = min + (max - min) * unrandom(i + j * len + seriesOffset);
              if (dim === "x") {
                point.clientX = point.plotX;
              }
            }
          }
        });
      });
    }
  });
})(Highcharts);

const SummaryTabBubble = (props) => {
  //Props
  const {
    data,
    currentChart,
    discountValues,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    hoverEl,
    setHoverEl,
    retailer,
    setRetailer,
    type,
  } = props;
  //Contsants
  const isBrand = currentChart[2].value === "byBrand";
  const isDiscountInPercents = currentChart[1].value === "percent";
  const suffix = isDiscountInPercents ? "%" : "";
  // const prefix = isDiscountInPercents ? "" : "£";
  const labelsStep = 10;
  const desiredArr = isBrand ? arrayOfDesiredBrand : retailer;
  const setFunc = isBrand ? setArrayOfDesiredBrand : setRetailer;
  //States
  const [pricings, setPricings] = useState([]);
  const [names, setNames] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [minZvalue, setMinZValue] = useState(0);
  const [minMaxYValue, setMinMaxYValue] = useState({ min: 0, max: 0 });
  const [minMaxXValue, setMinMaxXValue] = useState({ min: 0, max: 0 });
  const [allValuesAreEqual, setAllValuesAreEqual] = useState(false);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  const { formatCurrencyNumber } = useCountry();
  const [isShrinked, setIsShrinked] = useState(false);

  //Refs

  const inputRef = useRef(null);
  //Format data
  useEffect(() => {
    const reatailerObj =
      !isBrand && retailer.length && data[currentChart[2].value].data.find(({ title }) => title === retailer[0]);

    const dataToFormat = Object.keys(reatailerObj).length ? reatailerObj.brands.data : data[currentChart[2].value].data;

    let key;
    const pricing = dataToFormat.map((el, index) => {
      const isIncluded = isBrand && arrayOfDesiredBrand.includes(el.title);
      const isHovered = hoverEl === el.title;

      key = isDiscountInPercents ? "averageDiscountPercent" : "averageDiscount";
      return {
        x: el[key],
        y: el.products,
        name: el.title,
        color: el.color,
        z: el.duration,
        promotions: el.promotions,
        labelrank: el.duration,
        isIncluded: isIncluded,
        className:
          isBrand && !isHovered && !isIncluded
            ? "not-hightlighted"
            : (isIncluded && isBrand) || isHovered
            ? "hightlighted"
            : (arrayOfDesiredBrand.length && isBrand) || hoverEl.length
            ? "not-hightlighted"
            : !isBrand && !isHovered && !isIncluded && retailer.length
            ? "not-hightlighted"
            : null,
        dataLabels: {
          allowOverlap:
            !arrayOfDesiredBrand.length && isHovered
              ? true
              : !arrayOfDesiredBrand.length
              ? false
              : arrayOfDesiredBrand.length && isIncluded && isBrand
              ? false
              : true,
          className:
            isBrand && !isHovered && !isIncluded
              ? "not-hightlighted-label"
              : (isIncluded && isBrand) || isHovered
              ? "hightlighted-label"
              : (arrayOfDesiredBrand.length && isBrand) || hoverEl.length
              ? "not-hightlighted-label"
              : !isBrand && !isHovered && !isIncluded && retailer.length
              ? "not-hightlighted-label"
              : null,
        },
      };
    });

    setPricings(pricing);

    const namesArr = data[currentChart[2].value].data.map((el) => {
      return { name: el.title, color: el.color };
    });
    setNames(namesArr);
  }, [data, currentChart, discountValues, arrayOfDesiredBrand, hoverEl, retailer, isBrand, isDiscountInPercents]);
  //set minimal Zvalue
  useEffect(() => {
    if (pricings.length) {
      const valuesForZAxis = pricings.map(({ z }) => z);
      const minZValue = Math.min(...valuesForZAxis);
      const minXValue = Math.min(...pricings.map(({ x }) => x));
      const minYValue = Math.min(...pricings.map(({ y }) => y));
      const maxXValue = Math.max(...pricings.map(({ x }) => x));
      const maxYValue = Math.max(...pricings.map(({ y }) => y));

      setMinZValue(minZValue);
      setMinMaxXValue({ min: minXValue, max: maxXValue });
      setMinMaxYValue({ min: minYValue, max: maxYValue });

      const allValuesAreEqual = allEqual(pricings.map(({ z }) => z));
      setAllValuesAreEqual(allValuesAreEqual);
    }
  }, [pricings]);

  const allEqual = (arr) => arr.every((val) => val === arr[0]);

  //check for empty array
  useEffect(() => {
    if (pricings.length) {
      setEmptyData(false);
    } else {
      setEmptyData(true);
    }
  }, [pricings]);

  useEffect(() => {
    if (names.length > labelsStep && isBrand) {
      if (!arrayOfDesiredBrand.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, isBrand, arrayOfDesiredBrand]);

  const setMinValue = (minValue) => {
    return minValue - minValue / 2;
  };

  const setMaxValue = (maxValue) => {
    return maxValue + maxValue / 2;
  };
  const tooltipWithOptions = (thisObj, averageDiscountString) => {
    let key;
    if (thisObj.points) {
      key = thisObj.points[0].point;
    }
    if (thisObj.point) {
      key = thisObj.point;
    }

    const tooltipContent = `<div class="wrapper-category-discount-cut">
    <div class='title'>${changeNameRetailer(key.name)}</div>
    <div class='wrapper-box-promotion'>
    
    <div class='wrapper'>
    <div class='box'>
      <div class='name'>No. of Products</div>
    </div>
    <div class='price'>${thisObj.y}</div>
    </div>
    
    <div class='wrapper'>
    <div class='box'>
    <div class='name'>Average Discount</div>
    </div>
    <div class='price'>${averageDiscountString}</div>
    </div>
    
    <div class='wrapper'>
    <div class='box'>
    <div class='name'>Duration</div>
    </div>
    <div class='price'>${key.z} d</div>
    </div>
    
    <div class='wrapper'>
    <div class='box'>
    <div class='name'>No. of Promotions</div>
    </div>
    <div class='price'>${key.promotions}</div>
    </div>
    
    </div>
    </div>`;

    return tooltipContent;
  };

  const toggleBubbleSize = useCallback(() => {
    if (inputRef.current && inputRef.current.chart) {
      const newSize = isShrinked ? "125" : "25";
      inputRef.current.chart.series[0].update({ maxSize: newSize }, true);
      setIsShrinked(!isShrinked);
    }
  }, [isShrinked]);

  //Options
  const options = useMemo(
    () => ({
      chart: {
        type: "bubble",
        animation: false,
        style: {
          fontFamily: "Gilroy-Medium",
          fontSize: "10px",
        },
      },

      legend: {
        enabled: false,
      },

      title: {
        text: "",
      },

      xAxis: {
        min: pricings.length === 1 || allEqual(pricings.map(({ x }) => x)) ? setMinValue(+minMaxXValue.min) : null,
        max: pricings.length === 1 || allEqual(pricings.map(({ x }) => x)) ? setMaxValue(+minMaxXValue.max) : null,

        lineColor: "#90909",
        tickColor: "transparent",
        labels: {
          formatter: function () {
            return `${formatCurrencyNumber(
              this.value,
              isDiscountInPercents ? NUMBER_TYPE.DECIMAL : NUMBER_TYPE.CURRENCY
            )} ${suffix}`;
          },
          style: {
            fontSize: "10px",
          },
        },
        style: {
          fontFamily: "Gilroy-Medium",
        },
      },

      yAxis: {
        min: pricings.length === 1 || allEqual(pricings.map(({ y }) => y)) ? setMinValue(+minMaxYValue.min) : null,
        max: pricings.length === 1 || allEqual(pricings.map(({ y }) => y)) ? setMaxValue(+minMaxYValue.max) : null,
        allowDecimals: false,
        title: {
          offset: 15,
          x: 25,
          text: "No. of products",
        },
        offset: 30,
        labels: { style: { fontSize: "10px" } },
        style: {
          fontFamily: "Gilroy-Medium",
        },
      },

      tooltip: {
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        formatter: function (e) {
          if (this) {
            return tooltipWithOptions(
              this,
              `${formatCurrencyNumber(
                this.x,
                isDiscountInPercents ? NUMBER_TYPE.DECIMAL : NUMBER_TYPE.CURRENCY
              )}${suffix}`
            );
          } else {
            return false;
          }
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            useHTML: true,
            allowOverlap: false,

            style: {
              textOverflow: "clip",

              fontWeight: 800,
              color: "#fff",
              fontSize: "12px",
            },
            enabled: true,
            format: "{point.z}d",
          },
          animation: false,
          states: {
            hover: {
              halo: null,
              lineWidth: 0,
            },
          },
        },
        bubble: {
          minSize: 25,
          maxSize: 125,
          marker: {
            fillOpacity: 1,
          },
        },
      },
      series: [
        {
          zMin: minZvalue,
          data: pricings,
          jitter: {
            x: 0.125,
            y: 0.125,
          },
        },
      ],
    }),
    [
      allValuesAreEqual,
      formatCurrencyNumber,
      isDiscountInPercents,
      minMaxXValue.max,
      minMaxXValue.min,
      minMaxYValue.max,
      minMaxYValue.min,
      minZvalue,
      pricings,
      suffix,
    ]
  );

  return (
    <div className="chart-price-wrapper" style={{ width: `calc(100% - 402px)` }}>
      {isTooMoreData ? <RenderTooMoreData style={{ top: 58 }} /> : null}
      <div className="chart-title-desc-promo">
        <p className="chart-desc">
          Overall we found {data.products} Products on Promotion, with an average{" "}
          {type === RATING ? "reviews" : "discount"} of{" "}
          {isDiscountInPercents
            ? `${data.averageDiscountPercent}${type === RATING ? "" : "%"}`
            : `${
                type === RATING
                  ? formatCurrencyNumber(data.averageDiscount, NUMBER_TYPE.DECIMAL)
                  : formatCurrencyNumber(data.averageDiscount, NUMBER_TYPE.CURRENCY)
              }`}
          , based on the selected filters.
        </p>
        <div className="shrink-btn">
          <Checkbox checked={isShrinked} onChange={toggleBubbleSize}>
            Shrink Bubbles
          </Checkbox>
        </div>
      </div>
      {emptyData ? <RenderNoData /> : <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />}
      <div className="brand-labesl-row">
        <RowOfBrands
          names={names}
          arrayOfDesiredBrand={desiredArr}
          setArrayOfDesiredBrand={setFunc}
          selectSingle={!isBrand}
          labelsStep={labelsStep}
        />
      </div>
    </div>
  );
};

export default SummaryTabBubble;
