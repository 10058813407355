import React, { useRef, useState, useEffect, useCallback } from "react";
import _ from "lodash";
import useColorTheme from "../../../../hooks/useColorTheme";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

import Highcharts from "highcharts";
import LoaderBox from "components/LoaderBox";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import { Slider } from "antd";
import { useDispatch } from "react-redux";
//Actions
import { addToData } from "store/promotions/promotionsCalendar/actions";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import moment from "moment";
import {
  formatToStartEndValues,
  formatDateToTimeStamp,
  formatToDate,
  START,
  END,
  getDiscount,
  PRICE_CUT,
  MULTIBUY,
  OTHER,
} from "utils/calendarPromotionsHelper";
import { changeNameRetailer } from "utils/changeNameRetailer";
import { roundingNumbers } from "utils/roundingNumbers";

import RenderNoData from "components/RenderNoData";

HC_rounded(Highcharts);

const PromotionsCalendarChart = ({
  data,
  controlData,
  clickedArray,
  setClickedArray,
  currentChart,
  status,
  maxMinYValue,
  setMaxMinYValue,
  sliderValue,
  onSliderChange,
  setIsRedraw,
  isEmpty,
  isNew,
  setIsNew,
}) => {
  //Refs
  const inputRef = useRef(null);
  //Const
  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();
  const { formatCurrencyNumber } = useCountry();

  const RETAILERS = "retailers";
  const BRANDS = "brands";
  const PRODUCT = "product";
  const BRAND = "brand";
  const RETAILER = "retailer";
  const sevenDaysInterval = 24 * 3600 * 1000 * 7;
  const oneDayInterval = 24 * 3600 * 1000;
  const interval = maxMinYValue.max - maxMinYValue.min >= sevenDaysInterval ? sevenDaysInterval : oneDayInterval;

  const isDiscountInPercents = currentChart[1].value === "percent";
  const suffix = isDiscountInPercents ? "%" : "";
  const discount = isDiscountInPercents ? "discountPercent" : "discountValue";

  //States
  const [pricings, setPricings] = useState([]);
  const [retailersLabel, setRetailersLabel] = useState([]);
  const [options, setOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  //Set min max values when loading is done
  const dataRows = data.rows;
  useEffect(() => {
    if (status === STATE_STATUSES.READY && !!dataRows && dataRows.length > 0 && !!isNew) {
      setIsNew(false);
      const periods = dataRows.map((el) => {
        return Object.entries(el.period);
      });
      if (periods.length) {
        const min = formatDateToTimeStamp(periods[0][0][0]);
        const max = formatDateToTimeStamp(periods[0].reverse()[0][0]);
        setMaxMinYValue({ min, max });
      }
    }
  }, [status, setMaxMinYValue, isNew, setIsNew, dataRows]);

  const renderDataLabels = useCallback(
    (options, barWidth) => {
      const { discount, name, valType, mechanic } = options;
      const packedName = name.length > barWidth ? `${name.substring(0, barWidth)} ...` : name;

      const content =
        valType === RETAILER
          ? `${formatCurrencyNumber(
              roundingNumbers(discount),
              isDiscountInPercents ? NUMBER_TYPE.DECIMAL : NUMBER_TYPE.CURRENCY
            )}${suffix}`
          : valType === BRAND
          ? mechanic
          : packedName;
      return `<span  class="calendar-bar-label">${content ? content : "No Info"}</span>`;
    },
    [formatCurrencyNumber, isDiscountInPercents, suffix]
  );

  const tooltipContent = useCallback(
    (product, tips, low, high, valType, promotedPrice, discount) => {
      return `<div class="wrapper-category-discount-cut">
    <div class="title" style="margin-bottom:4px">${product ? product : ""}</div>
    <div class="title">${tips ? changeNameRetailer(tips) : "No Info"}</div>
    <div class="wrapper-box-promotion"></div>
    <div class="wrapper">
      <div class="box">
        <div class="name">Start Date</div>
      </div>
      <div class="price">${formatToDate(low)}</div>
    </div>
    <div class="wrapper">
      <div class="box">
        <div class="name">End Date</div>
      </div>
      <div class="price">${formatToDate(high)}</div>
    </div>
    <div class="wrapper">
      <div class="box">
        <div class="name">
          ${valType === PRODUCT ? "Discount" : "Average Discount"} 
        </div>
      </div>
      <div class="price">
        ${formatCurrencyNumber(
          roundingNumbers(discount),
          isDiscountInPercents ? NUMBER_TYPE.DECIMAL : NUMBER_TYPE.CURRENCY
        )}${suffix}
      </div>
    </div>
    ${
      valType === PRODUCT
        ? `<div class="wrapper">
          <div class="box">
            <div class="name">Promoted Price</div>
          </div>
          <div class="price">${formatCurrencyNumber(roundingNumbers(promotedPrice), NUMBER_TYPE.CURRENCY)}</div>
        </div>`
        : ""
    }
  </div>`;
    },
    [formatCurrencyNumber, isDiscountInPercents, suffix]
  );

  const renderTooltip = useCallback(
    (options) => {
      const { high, low, promotedPrice, discount, valType, product, isMultipromotions, promotions, tips } = options;
      if (isMultipromotions) {
        let initialData = promotions.map((el) => {
          return tooltipContent(el.product, el.name, el.low, el.high, el.valType, el.promotedPrice, el.discount);
        });

        initialData = initialData.join(" ");

        return `<div class="wrapper-category-discount-cut">
                ${initialData}
              </div>`;
      } else {
        return tooltipContent(product, tips, low, high, valType, promotedPrice, discount);
      }
    },
    [tooltipContent]
  );

  //Chart options
  useEffect(() => {
    const additionalHeight = retailersLabel.length === 1 && !clickedArray.length ? 60 : 49;

    setOptions({
      chart: {
        style: {
          fontFamily: "Gilroy-Medium",
          fontSize: "10px",
        },
        animation: false,
        type: "columnrange",
        inverted: true,
        height: retailersLabel.length * 48 + additionalHeight,

        events: {
          load: function () {
            setIsRedraw(true);
          },
        },
      },

      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      xAxis: {
        tickColor: "transparent",
        labels: {
          enabled: false,
        },
        lineColor: "#e9e9e9",
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 48,
        min: 0,
        max: retailersLabel.length,
        zoomEnabled: false,
        top: retailersLabel.length === 1 && !clickedArray.length ? 28 : 34,
        tickPositions: retailersLabel.map((el, i) => i),
      },

      yAxis: {
        type: "datetime",
        opposite: true,
        tickInterval: interval,
        showLastLabel: false,
        tickPositioner: function (min, max) {
          let arr = [];
          let minVal = min;

          while (minVal <= max) {
            arr.push(minVal);
            minVal += interval;
          }
          arr.push(max);

          return arr;
        },

        max: maxMinYValue.max,
        min: maxMinYValue.min,
        title: { text: "" },
        labels: {
          formatter: function () {
            return formatToDate(this.value);
          },
          style: { fontSize: "10px" },
        },
        style: {
          fontFamily: "Gilroy-Medium",
        },
      },

      tooltip: {
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        followPointer: true,
        formatter: function () {
          return renderTooltip(this.point.options);
        },
        followTouchMove: true,
      },

      plotOptions: {
        series: {
          clip: false,
          pointRange: 1,
          groupPadding: 0.47,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        columnrange: {
          groupping: false,
          animation: false,
          pointPadding: 0,
          pointWidth: 16,
          borderWidth: 0,

          dataLabels: {
            useHTML: true,
            enabled: true,
            align: "center",
            color: "#fff",
            inside: true,
            allowOverlap: true,
            formatter: function () {
              const barWidth = parseInt((this.point.plotLow - this.point.plotHigh) / 10);
              return renderDataLabels(this.point.options, barWidth);
            },
          },
          states: {
            hover: {
              halo: null,
            },
          },
        },
      },

      legend: {
        enabled: false,
      },

      series: pricings,
    });
  }, [
    retailersLabel,
    maxMinYValue,
    pricings,
    clickedArray,
    currentChart,
    interval,
    renderDataLabels,
    renderTooltip,
    setIsRedraw,
  ]);

  //Converting data
  useEffect(() => {
    const brandsColors = {
      "Price Cut": "#56bfc4",
      Multibuy: "#ea5f94",
      Other: "#ffb14e",
    };

    const brandsColorsOpacity = {
      "Price Cut": "rgba(86, 191, 196, 0.5)",
      Multibuy: "rgba(234, 95, 148, 0.5)",
      Other: "rgba(255, 177, 78, 0.5)",
    };

    if (data.rows.length) {
      //Set count of  opened labels to calculate charts height
      const retailersLabel = data.rows
        .map(({ label, id, children }) => {
          if (children.length) {
            return { label, id };
          } else return null;
        })
        .filter((el) => el !== null);

      setRetailersLabel(retailersLabel);
      if (clickedArray.length) {
        //If clicked on plus btn put new item in order
        for (let index = 0; index < clickedArray.length; index++) {
          const indexOfMatchedEl = retailersLabel.findIndex(({ id }) => id === clickedArray[index].id);
          const labels = clickedArray[index].children.map(({ name, id }) => ({
            label: name,
            id,
          }));

          for (let i = 0; i < labels?.length; i++) {
            retailersLabel.splice(indexOfMatchedEl + i + 1, 0, labels[i]);
          }
        }
      }
      //Function to create data for chart
      const formatToPricing = (
        start,
        end,
        data,
        chartColor,
        type,
        promotion,
        label,
        valType,
        discounts,
        promotedPrices,
        product
      ) => {
        const results = start.map((el, i) => {
          const promotedPrice = promotedPrices ? promotedPrices[i] : null;
          const discount = discounts[i];
          const name =
            type === RETAILERS && valType === PRODUCT
              ? label[i].label
              : type === RETAILERS && valType !== PRODUCT
              ? label
              : data[i].label;

          const { id } = type === RETAILERS ? data : data[i];
          const x = retailersLabel.findIndex((el) => el.id === id);
          const mechanic = promotion ? promotion[i] : null;
          const color =
            mechanic && brandsColors[mechanic] && valType !== PRODUCT
              ? brandsColors[mechanic]
              : mechanic && brandsColorsOpacity[mechanic] && valType === PRODUCT
              ? brandsColorsOpacity[mechanic]
              : chartColor;
          if (x > -1) {
            return {
              low: el,
              high: moment(end[i]).add(23, "hour").valueOf(),
              x,
              name,
              color,
              mechanic,
              id,
              valType,
              discount,
              promotedPrice,
              product,
            };
          }
        });

        const resultsSorted = _.orderBy(results, ["low"], ["asc"]);
        return resultsSorted.map((el, i) => ({
          ...el,
          name: resultsSorted.slice(0, i).find((checkEl) => checkEl.high > el.low) ? " " : el.name,
          tips: el.name,
        }));
      };

      const periods = data.rows.map((el) => {
        return Object.entries(el.period);
      });

      let discounts = [];
      for (let index = 0; index < data.rows.length; index++) {
        const res = getDiscount(
          data.rows[index].children.map(({ children }) => {
            let priceCut = [];
            let multiBuy = [];
            let other = [];
            const arr = children.map(({ promotions }) => {
              return promotions.map((el) => {
                if (el.mechanic === PRICE_CUT) {
                  priceCut.push(+el[discount]);
                } else if (el.mechanic === MULTIBUY) {
                  multiBuy.push(+el[discount]);
                } else if (el.mechanic === OTHER) {
                  other.push(+el[discount]);
                }
                return +el[discount];
              });
            });
            const priceCutDiscount = getDiscount(priceCut);
            const multiBuyDiscount = getDiscount(multiBuy);
            const otherDiscount = getDiscount(other);

            const brandDiscount = getDiscount([priceCutDiscount, multiBuyDiscount, otherDiscount]);

            return brandDiscount;
          })
        );
        discounts.push(res);
      }

      const arrOfStartingValues = formatToStartEndValues(periods, START);

      const arrOfEndingValues = formatToStartEndValues(periods, END);

      const pricing = formatToPricing(
        arrOfStartingValues,
        arrOfEndingValues,
        data.rows,
        primaryColor,
        BRANDS,
        null,
        null,
        RETAILER,
        discounts
      );
      setPricings([
        {
          data: pricing,
          borderRadius: "4px",
          name: "AllRetailers",
          pointPlacement: 0,
        },
      ]);

      //Set data to chart in desired order
      if (clickedArray.length) {
        for (let index = 0; index < clickedArray.length; index++) {
          const isProduct = typeof clickedArray[index].id === "string" ? true : false;

          const periods = clickedArray[index].children.map(({ promotions }) => {
            return promotions.map(({ period }) => {
              return period;
            });
          });

          const mechanics = clickedArray[index].children.map(({ promotions }) => {
            return promotions.map(({ mechanic }) => mechanic);
          });

          const discounts = clickedArray[index].children.map(({ promotions }) => {
            return promotions.map((el) => +el.discount);
          });

          const promotedPrices = clickedArray[index].children.map(({ promotions }) => {
            return promotions.map((el) => +el.promotedPrice);
          });

          const pricing = clickedArray[index].children.map((el, inx) => {
            const arrOfStartingValues = formatToStartEndValues(periods[inx], START);
            const arrOfEndingValues = formatToStartEndValues(periods[inx], END);

            const childrensPricing = formatToPricing(
              arrOfStartingValues,
              arrOfEndingValues,
              clickedArray[index].children[inx],
              "rgba(86, 191, 196, 0.6)",
              RETAILERS,
              mechanics[inx],
              isProduct ? clickedArray[index].children[inx].promotions : clickedArray[index].children[inx].name,
              isProduct ? PRODUCT : BRAND,
              discounts[inx],
              isProduct ? promotedPrices[inx] : null,
              isProduct ? clickedArray[index].children[inx].product : null
            );

            return childrensPricing;
          });

          const result = setPricingsForMultiAxes(pricing, isProduct);
          setPricings((prevState) => [...prevState, ...result]);
        }
      }
    }
  }, [data, clickedArray, discount, primaryColor]);

  const setPricingsForMultiAxes = (arr, isProduct) => {
    let pricing = [];
    let promotions = isProduct ? [] : arr;
    if (isProduct) {
      for (let i = 0; i < arr.length; i++) {
        const promoByMechanic = arr[i]
          .sort((a, b) => (a.low < b.low ? -1 : 1))
          .reduce((acc, promo) => {
            if (!acc[`${promo.mechanic}_${promo.id}`]) return { ...acc, [`${promo.mechanic}_${promo.id}`]: [promo] };

            const promosByType = acc[`${promo.mechanic}_${promo.id}`];
            const prevPromo = promosByType[promosByType.length - 1];
            const prevEnd = moment(prevPromo.high).add(1, "day").valueOf();

            if (
              prevEnd >= promo.low &&
              prevPromo.id === promo.id &&
              prevPromo.name === promo.name &&
              prevPromo.discount === promo.discount &&
              prevPromo.promotedPrice === promo.promotedPrice
            ) {
              return {
                ...acc,
                [`${promo.mechanic}_${promo.id}`]: [
                  ...acc[`${promo.mechanic}_${promo.id}`].slice(0, -1),
                  {
                    ...prevPromo,
                    high: promo.high,
                  },
                ],
              };
            }
            return {
              ...acc,
              [`${promo.mechanic}_${promo.id}`]: [...acc[`${promo.mechanic}_${promo.id}`], promo],
            };
          }, {});
        console.log("!!promoByMechanic=", Object.values(promoByMechanic)); //!! To combine here...
        promotions.push(...Object.values(promoByMechanic));
      }
    }

    promotions.forEach((el) => {
      const promoGroups = new Set();
      el.forEach((promoGroup) => {
        promoGroups.add(promoGroup.mechanic);
      });
      if (el.length === 1) {
        pricing.push({
          data: [el[0]],
          borderRadius: "4px",

          pointPlacement: 0,
        });
      }
      if (el.length > 1 && promoGroups.size === 1) {
        el.forEach((item) => {
          pricing.push({
            data: [item],
            borderRadius: "4px",

            pointPlacement: 0,
          });
        });
      }
      if (promoGroups.size === 2) {
        el.forEach((item) => {
          pricing.push({
            data: [item],
            borderRadius: "4px",

            pointPlacement: 0.165 - Array.from(promoGroups).indexOf(item.mechanic) * 0.33,
          });
        });
      }
      if (promoGroups.size === 3) {
        el.forEach((item) => {
          pricing.push({
            data: [item],
            borderRadius: "4px",

            pointPlacement: 0.33 - Array.from(promoGroups).indexOf(item.mechanic) * 0.33,
          });
        });
      }
      if (promoGroups.size > 3) {
        const color = "red";
        let discontSum = 0;
        el.forEach((el) => (discontSum += el.discount));
        const discount = (discontSum / el.length).toFixed(2);
        const high = Math.max(...el.map(({ high }) => high));
        const low = Math.min(...el.map(({ low }) => low));
        const id = el[0].id;
        const mechanic = "";
        const name = `Multipromotions (${el.length})`;
        const product = el.product;
        let promotedPriceSum = 0;
        el.forEach((el) => (promotedPriceSum += el.promotedPrice));
        const promotedPrice = (promotedPriceSum / el.length).toFixed(2);
        const valType = el[0].valType;
        const x = el[0].x;
        const isMultipromotions = true;
        pricing.push({
          data: [
            {
              color,
              discount,
              high,
              low,
              id,
              mechanic,
              name,
              product,
              promotedPrice,
              valType,
              x,
              isMultipromotions,
              promotions: el,
            },
          ],

          borderRadius: "4px",

          pointPlacement: 0,
        });
      }
    });
    return pricing;
  };

  useEffect(() => {
    let isActive = true;
    if (maxMinYValue.min > 0 && !isNew) {
      const runWorker = (inputData) => {
        const worker = new window.Worker("./worker.js");
        setIsLoading(true);
        worker.postMessage(inputData);
        worker.onmessage = (e) => {
          const data = e.data;
          if (isActive && data?.length) {
            dispatch(addToData(data));
            setIsLoading(false);
          }
          worker.terminate();
        };
      };

      runWorker({ controlData, maxMinYValue });

      return () => {
        setIsLoading(false);
        isActive = false;
      };
    }
  }, [controlData, maxMinYValue, dispatch, isNew]);

  useEffect(() => {
    if (clickedArray.length && data.rows.length) {
      for (let index = 0; index < clickedArray.length; index++) {
        const element = clickedArray[index];
        if (typeof element.id === "number") {
          const retailer = data.rows.find((el) => el.id === element.id);
          const isBrands = false;

          if (retailer?.id) {
            returnNewClickedArr(retailer.id, retailer.children, isBrands);
          } else {
            setClickedArray((prevState) => {
              const indexOfEl = prevState.findIndex((el) => el.id === element.id);
              return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
            });
          }
        }
        if (typeof element.id === "string") {
          const retailer = data.rows.find((el) => el.label === element.id.split("_")[0]);

          if (retailer?.children.length) {
            const brand = retailer.children.find((el) => el.id === element.id);
            const isBrands = true;

            if (brand?.id) {
              returnNewClickedArr(brand.id, brand.children, isBrands);
            } else {
              setClickedArray((prevState) => {
                const indexOfEl = prevState.findIndex((el) => el.id === element.id);
                return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
              });
            }
          } else {
            setClickedArray((prevState) => {
              const indexOfEl = prevState.findIndex((el) => el.id === element.id);
              return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
            });
          }
        }
      }
    }
  }, [data]);

  const returnNewClickedArr = (id, children, isBrands) => {
    let mapedChildren = [];

    if (!isBrands) {
      mapedChildren = children.map((el) => {
        const promotions = el.promotions.map((item) => {
          return {
            mechanic: item.label,
            period: Object.entries(item.period),
            discount: isDiscountInPercents ? item.discountPercent : item.discountValue,
          };
        });

        return {
          id: el.id,
          name: el.label,
          promotions,
        };
      });
    } else {
      mapedChildren = children.map((el) => {
        const promotions = el.promotions.map((item) => {
          return {
            mechanic: item.mechanic,
            label: item.label,
            period: Object.entries(item.period),
            promotedPrice: item.promotedPrice,
            discount: isDiscountInPercents ? item.discountPercent : item.discountValue,
          };
        });
        const product = el.product.productTitle;

        return {
          id: `${id}_${el.product.id}`,
          name: el.product.promotionDescription,
          promotions,
          product,
        };
      });
    }

    const matchedEl = clickedArray.find((el) => el.id === id);

    setClickedArray((prevState) => {
      const indexOfEl = prevState.findIndex((el) => el.id === id);
      return [
        ...prevState.slice(0, indexOfEl),
        { ...matchedEl, children: mapedChildren },
        ...prevState.slice(indexOfEl + 1, prevState.length),
      ];
    });
  };

  const sliderValueHandler = (values) => {
    setIsRedraw(false);
    setTimeout(() => {
      setIsRedraw(true);
      setMaxMinYValue({
        max: values[1],
        min: values[0],
      });
    }, 500);
  };

  return (
    <div className="calendar">
      <div className="slider-wrapper">
        <Slider
          max={maxMinYValue.max}
          min={maxMinYValue.min}
          range
          value={sliderValue}
          onChange={(values) => {
            onSliderChange(values);
          }}
          onAfterChange={sliderValueHandler}
          tipFormatter={(e) => formatToDate(e)}
        />
      </div>

      {isLoading ? (
        <LoaderBox />
      ) : isEmpty ? (
        <RenderNoData style={{ height: 300, marginTop: 43 }} />
      ) : (
        <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} immutable={true} />
      )}
    </div>
  );
};

export default PromotionsCalendarChart;
