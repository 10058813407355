import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import { Popover } from "antd";

//Components
import RenderNoData from "components/RenderNoData";

import useRetailers from "../../../../../hooks/useRetailers";
import { changeNameRetailer } from "utils/changeNameRetailer";
import { firstCharToUpperCase } from "utils/generalUtils";

import { COLORS } from "assets/colors/colors";
import red from "assets/images/red.svg";
import green from "assets/images/green.svg";

import RowOfBrands from "components/RowOfBrands";

HC_rounded(Highcharts);

const AssortmentChangeChart = (props) => {
  //Props
  const {
    data,
    currentChart,
    setHoverEl,
    hoverEl,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    isCompare,
    compareData,
  } = props;

  //Refs
  const inputRef = useRef(null);
  const moreBrandsRef = useRef(null);
  const ref = useRef(null);
  const { getRemoteLogo } = useRetailers();

  //Constants
  const isProductsInPercent = currentChart[1].value === "percent";
  const MORE = "more";
  const renderLabelsStep = 16;
  const suffix = isProductsInPercent ? "%" : "";
  const isShowBrands = currentChart[0].value;
  const key = isProductsInPercent ? "percent" : "count";
  const LOW = "low";
  const HIGH = "high";

  //States
  const [isMoreBtnClicked, setMoreBtnClick] = useState(false);
  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);

  const [emptyData, setEmptyData] = useState(false);
  const [isCompareDataIsEmpty, setIsCompareDataIsEmpty] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const getEmptyCondition = (arr) => {
    return arr.result.map(({ children: { dropped, newly } }) => [...dropped.list, ...newly.list]).flat().length === 0;
  };

  useEffect(() => {
    const emptyCondition = getEmptyCondition(data);

    if (emptyCondition) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }
  }, [data]);

  useEffect(() => {
    const emptyCondition = getEmptyCondition(compareData);
    if (emptyCondition && isCompare) {
      setIsCompareDataIsEmpty(true);
    } else {
      setIsCompareDataIsEmpty(false);
    }
  }, [compareData, isCompare]);

  const isIncluded = (value) => {
    return arrayOfDesiredBrand.includes(value);
  };

  const isHovered = (value) => {
    return value === hoverEl;
  };

  const getClassName = (isInclude, isHover) => {
    if (isInclude) {
      return "hightlighted";
    } else if (!isInclude && arrayOfDesiredBrand.length) {
      return "not-hightlighted";
    } else if (hoverEl && hoverEl.length && !isHover) {
      return "not-hightlighted";
    } else if (isHover && hoverEl.length) {
      return "hightlighted";
    } else {
      return "hightlighted";
    }
  };

  const getClassNameForAllBrands = (isInclude) => {
    if (isInclude) {
      return "hightlighted";
    } else if (!isInclude && arrayOfDesiredBrand.length) {
      return "not-hightlighted";
    } else {
      return "not-hightlighted";
    }
  };

  const getDesiredArray = (data, nameForClass, type) => {
    return data.map((el, i) => {
      const {
        item: { name },
        children,
        newly: { group, products },
      } = el;

      const highValue = children.newly.list.reduce((acc, current) => acc + current[key], 0);
      const lowValue = children.dropped.list.reduce((acc, current) => acc + current[key], 0);

      const yAxis = type === HIGH ? 0 : 1;
      const y = type === HIGH ? highValue : -lowValue;

      const color = type === HIGH ? COLORS.available : COLORS.unavailable;

      const isInclude = isIncluded(name);
      const isHover = isHovered(name);

      const newly = children.newly.list;
      const dropped = children.dropped.list;

      return {
        x: i,
        name,
        y,
        color,
        group,
        products,
        yAxis,
        className: nameForClass ? nameForClass : getClassName(isInclude, isHover),
        newly,
        dropped,
      };
    });
  };

  const getArrOfProductsObj = (arr1, arr2, arr3, arr4, stack, type) => {
    let arr = [];
    for (let index = 0; index < arr1.length; index++) {
      const el = arr1[index];
      let productObj = {
        name: el.item.name,
        color: el.item.color,
        data: [],
        total: [],
        newly: [],
        dropped: [],
      };
      for (let i = 0; i < arr2.length; i++) {
        const element = arr2[i];

        let matchedEl;
        for (let indx = 0; indx < element.length; indx++) {
          const product = element[indx];
          if (product.item.id === el.item.id) {
            matchedEl = product;
          }
        }
        if (matchedEl) {
          productObj.data.push(matchedEl);
        } else {
          productObj.data.push({ count: 0, percent: 0, item: { name: "" } });
        }
        productObj.newly.push(arr4[i].newly.list);
        productObj.dropped.push(arr4[i].dropped.list);
        productObj.total.push(arr3[i]);
      }
      const result = formDataForAllBrands(productObj, stack, type);

      arr.push(result);
    }
    return arr;
  };

  const getChildrenArr = (arr, type) => {
    if (type) {
      const objKey = type === HIGH ? "newly" : "dropped";
      return arr.result.map(({ children }) => children[objKey].list);
    } else {
      return arr.result.map(({ children }) => children);
    }
  };

  const getTotalArr = (arr) => {
    return arr.result.map(({ newly }) => newly);
  };

  const getNotRepeatedProducts = (arr) => {
    const products = arr.map(({ children: { dropped, newly } }) => [...dropped.list, ...newly.list]).flat();
    const notRepeatedProducts = products.reduce((acc, current) => {
      const isInArray = acc.some((el) => el.item.id === current.item.id);
      if (!isInArray) {
        acc.push(current);
      }
      return acc;
    }, []);
    return notRepeatedProducts;
  };

  const formDataForAllBrands = (product, stack, type) => {
    const data = product.data.map((el, i) => {
      const { name, color } = el.item;
      const isInclude = isIncluded(name);

      const value = el[key];

      const yAxis = type === HIGH ? 0 : 1;
      const y = type === HIGH ? value : -value;

      return {
        x: i,
        y,
        yAxis,
        name,
        color,
        newly: product.newly[i],
        dropped: product.dropped[i],
        group: product.total[i].group,
        products: product.total[i].products,
        className: getClassNameForAllBrands(isInclude),
      };
    });

    return {
      name: product.name,
      color: type === HIGH ? COLORS.available : COLORS.unavailable,
      data,
      stack,
      borderRadiusTopLeft: 0,
      borderRadiusBottomLeft: 0,
      borderRadiusTopRight: 0,
      borderRadiusBottomRight: 0,
    };
  };

  const mergeTwoArrWithData = (arr1, arr2) => {
    return arr1.map((el, i) => ({ ...el, data: [...el.data, ...arr2[i].data] }));
  };

  const prepareNamesArr = (arr) => {
    return arr.map(({ item: { name, color } }) => ({ name, color }));
  };

  //Converting Data
  useEffect(() => {
    if (data.result.length) {
      const currentStack = "current";
      const compareStack = "compare";
      //Find Names
      const notRepeatedProducts = getNotRepeatedProducts(data.result);
      const namesArr = prepareNamesArr(notRepeatedProducts);

      setNames(namesArr);

      const childrenArrHigh = getChildrenArr(data, HIGH);
      const childrenArrLow = getChildrenArr(data, LOW);

      const childrenArr = getChildrenArr(data);

      const totalArr = getTotalArr(data);

      const lowArr = getDesiredArray(data.result, "", LOW);
      const highArr = getDesiredArray(data.result, "", HIGH);
      // const allRetailersData = [...lowArr, ...highArr];

      const pricing = [
        {
          name: "HighData",
          data: highArr,
          stack: currentStack,
          borderRadiusTopLeft: "20%",
          borderRadiusTopRight: "20%",
        },
        {
          name: "LowData",
          data: lowArr,
          stack: currentStack,
          borderRadiusBottomLeft: "20%",
          borderRadiusBottomRight: "20%",
        },
      ];

      if (!isShowBrands && !compareData.result.length) {
        setPricings(pricing);
      }

      if (isShowBrands && !compareData.result.length) {
        const resultHigh = getArrOfProductsObj(
          notRepeatedProducts,
          childrenArrHigh,
          totalArr,
          childrenArr,
          currentStack,
          HIGH
        );
        const resultLow = getArrOfProductsObj(
          notRepeatedProducts,
          childrenArrLow,
          totalArr,
          childrenArr,
          currentStack,
          LOW
        );

        const result = mergeTwoArrWithData(resultHigh, resultLow);

        setPricings(result);
      }

      if (isShowBrands && compareData.result.length) {
        const compareChildrenArrHigh = getChildrenArr(compareData, HIGH);
        const compareChildrenArrLow = getChildrenArr(compareData, LOW);
        const compareChildrenArr = getChildrenArr(compareData);

        const compareNotRepeatedProducts = getNotRepeatedProducts(compareData.result);
        const compareTotalArr = getTotalArr(compareData);

        const newNames = getNotRepeatedProducts([...data.result, ...compareData.result]);
        const newNamesArr = prepareNamesArr(newNames);
        setNames(newNamesArr);

        const resultHigh = getArrOfProductsObj(
          notRepeatedProducts,
          childrenArrHigh,
          totalArr,
          childrenArr,
          currentStack,
          HIGH
        );
        const resultLow = getArrOfProductsObj(
          notRepeatedProducts,
          childrenArrLow,
          totalArr,
          childrenArr,
          currentStack,
          LOW
        );
        const result = mergeTwoArrWithData(resultHigh, resultLow);

        const newDataHigh = getArrOfProductsObj(
          compareNotRepeatedProducts,
          compareChildrenArrHigh,
          compareTotalArr,
          compareChildrenArr,
          compareStack,
          HIGH
        );
        const newDataLow = getArrOfProductsObj(
          compareNotRepeatedProducts,
          compareChildrenArrLow,
          compareTotalArr,
          compareChildrenArr,
          compareStack,
          LOW
        );
        const newData = mergeTwoArrWithData(newDataHigh, newDataLow);

        setPricings([...newData, ...result]);
      }

      if (compareData.result.length && !isShowBrands) {
        const lowArrCompare = getDesiredArray(compareData.result, "not-hightlighted", LOW);
        const highArrCompare = getDesiredArray(compareData.result, "not-hightlighted", HIGH);

        // const allRetailersNew = [...lowArrCompare, ...highArrCompare];

        const newData = [
          {
            name: "HighCompare",
            data: highArrCompare,
            stack: compareStack,
            borderRadiusTopLeft: "20%",
            borderRadiusTopRight: "20%",
          },
          {
            name: "LowCompare",
            data: lowArrCompare,
            stack: compareStack,
            borderRadiusBottomLeft: "20%",
            borderRadiusBottomRight: "20%",
          },
        ];

        setPricings([...newData, ...pricing]);
      }
    }
  }, [data, currentChart, compareData, arrayOfDesiredBrand, hoverEl, isShowBrands, isProductsInPercent]);

  useEffect(() => {
    if (pricings.length) {
      const valuesArr = pricings.map(({ data }) => data.map(({ y }) => y)).flat();
      const min = Math.min(...valuesArr);
      const max = Math.max(...valuesArr);
      setMinValue(min);
      setMaxValue(max);
    }
  }, [pricings]);

  //Settings for chart
  const options = {
    title: {
      text: "",
    },

    chart: {
      animation: false,

      type: "column",
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
        overflow: "initial",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      zIndex: 999,
      formatter: function () {
        return renderTooltip(this);
      },
    },
    xAxis: {
      categories: data.result.map(({ item: { name } }) => name),
      labels: {
        useHTML: true,
        formatter: function () {
          return `<img style="width: 25px;" src=${getRemoteLogo(this.value)}></img>`;
        },
      },
      offset: 16,
    },
    yAxis: [
      {
        labels: {
          format: "{value}" + `${suffix}`,
          style: {
            fontSize: "10px",
          },
        },
        style: {
          fontFamily: "Gilroy-Medium",
        },
        title: {
          offset: 20,
          x: 25,
          text: `New Products`,
          align: minValue >= 0 && maxValue >= 0 ? "middle" : "high",
          y: minValue >= 0 && maxValue >= 0 ? 0 : minValue <= 0 && maxValue <= 0 ? 999 : 20,
        },
        offset: 25,
        plotLines: isShowBrands
          ? [
              {
                from: 0,
                to: -10000,
                zIndex: 5,
                color: "rgba(254, 106, 104, 0.2)",
              },
              {
                from: 0,
                to: 0,
                zIndex: 5,
                color: "rgba(191, 191, 191, 0.99)",
              },
            ]
          : [
              {
                from: 0,
                to: 0,
                zIndex: 5,
                color: "rgba(191, 191, 191, 0.99)",
              },
            ],
      },
      {
        enabled: false,
        labels: {
          format: "{value}" + `${suffix}`,
          style: {
            fontSize: "10px",
          },
        },
        style: {
          fontFamily: "Gilroy-Medium",
        },
        title: {
          offset: 20,
          x: 25,
          text: `${isProductsInPercent ? "%" : "No"} of Dropped`,
          align: minValue <= 0 && maxValue <= 0 ? "middle" : "low",

          y: minValue <= 0 && maxValue <= 0 ? 0 : minValue >= 0 && maxValue >= 0 ? -999 : -20,
        },
        offset: 25,
      },
    ],
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        pointWidth: 24,
        states: {},
        point: {
          events: {
            mouseOut: function () {
              setElement("");
            },
            mouseOver: function () {
              setElement(this.category);
            },
          },
        },
      },
    },
    series: pricings,
  };

  const renderTooltip = (thisObj) => {
    const baseArr = [
      {
        point: {
          options: { newly: [], dropped: [], group: { count: 0, percent: 0 }, products: { count: 0, percent: 0 } },
        },
      },
    ];
    const compareData = thisObj.points.length
      ? thisObj.points.filter((item) => item?.series?.options.stack === "compare")
      : [...baseArr, ...baseArr];
    const initData = thisObj.points.length
      ? thisObj.points.filter((item) => item?.series?.options.stack === "current")
      : [...baseArr, ...baseArr];

    const name = thisObj.x;
    const { group: firstGroup, products: firstProducts } =
      compareData.length && compareData[0].point.options ? compareData[0].point.options : initData[0].point.options;
    const { group: secondGroup, products: secondProducts } = initData[0].point.options;
    const nameForContextFirstLine = `New ${firstCharToUpperCase(currentChart[2].value)}s`;
    const nameForContextSecondLine = "New Products";

    const valueFirstLine = `${firstGroup.count} (${firstGroup.percent}%)`;
    const valueSecondLLine = `${firstProducts.count} (${firstProducts.percent}%)`;

    const valueFirstLineIsCompare = `${secondGroup.count}(${secondGroup.percent}%)`;
    const valueSecondLLineIsCompare = `${secondProducts.count}(${secondProducts.percent}%)`;

    const brandsDataPositive = initData[0].point.options.newly.map(({ item: { name, color }, count, percent }) =>
      renderBrandRow(name, percent, count, color, green)
    );
    const brandsDataNegative = initData[1].point.options.dropped.map(({ item: { name, color }, count, percent }) =>
      renderBrandRow(name, percent, count, color, red)
    );

    const arrIfCompareDataIsEmpty = compareData.length ? compareData : [...baseArr, ...baseArr];

    const compareBrandsDataPositive = arrIfCompareDataIsEmpty[0].point.options.newly.map(
      ({ item: { name, color }, count, percent }) => renderBrandRow(name, percent, count, color, green)
    );
    const compareBrandsDataNegative = arrIfCompareDataIsEmpty[1].point.options.dropped.map(
      ({ item: { name, color }, count, percent }) => renderBrandRow(name, percent, count, color, red)
    );

    const renderBrandsDataPositive = [...brandsDataPositive, ...compareBrandsDataPositive].join(" ");
    const renderBrandsDataNegative = [...brandsDataNegative, ...compareBrandsDataNegative].join(" ");

    return `<div class="share-tooltip-wrapper" style="width: min-content; z-index: 999;">
          <div class="title" style="text-transform: capitalize">${changeNameRetailer(name)}</div>
          <div style="${isCompare ? "display: flex" : ""}">
            <div>
              ${renderContextForTooltip(nameForContextFirstLine, valueFirstLine)}
              ${renderContextForTooltip(nameForContextSecondLine, valueSecondLLine)}
            </div>
            ${isCompare ? `<div class='line-separete'></div>` : ""}
            ${
              isCompare
                ? `<div>
                ${renderContextForTooltip("", valueFirstLineIsCompare)}
                ${renderContextForTooltip("", valueSecondLLineIsCompare)}
                </div>`
                : ""
            }
          </div>
          <div>${renderBrandsDataPositive}</div>
          <div>${renderBrandsDataNegative}</div>
        </div>`;
  };

  const renderContextForTooltip = (name, value) => {
    return `<div class="row-product">
          <span style="margin-right: 8px">${name}</span>
          <span>${value}</span>
        </div>`;
  };

  const renderBrandRow = (name, percent, count, color, image) => {
    return `<div class="row-product">
    <div class="row-brand" style="margin-top: 10px;">
      <div class="color" style="background: ${color};"></div>
      <span style="margin-right: 8px">${name}</span>
    </div>
    <div class="row-brand" style="margin-top: 10px;">
      <span>
        ${count} (${percent}%)
      </span>
      <img src="${image}" alt="" />
    </div>
  </div>`;
  };

  //set hover element
  const setElement = (el) => {
    setHoverEl(el);
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `100%` }} ref={ref}>
      {emptyData || isCompareDataIsEmpty ? (
        <RenderNoData />
      ) : (
        <HighchartsReact
          containerProps={{ className: "initialOverflow" }}
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
          update={[true, true, false]}
        />
      )}

      <div className="brand-labesl-row">
        {isShowBrands ? (
          <RowOfBrands
            names={names}
            arrayOfDesiredBrand={arrayOfDesiredBrand}
            setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AssortmentChangeChart;
