import React from "react";
import { Checkbox, Radio } from "antd";

import SelectCalculation from "components/TabsExport/components/SelectCalculation";
import RadioButtons from "components/TabsExport/components/RadioButtons";

import {
  summaryName,
  priceNemes,
  viewType,
  colourFill,
  viewByRetailerType,
  viewByProductType,
  isAllReviews,
} from "components/TabsExport/exportSections";

const SUB_OPTION_SYTLE = {
  marginTop: "2px",
  marginLeft: "24px",
};

const ReportOptions = ({ current, sectionId, handleSetOptions, options, amountDays, disabled, isFullHistory }) => {
  const renderOptions = () => {
    switch (sectionId) {
      // pricing
      case 2: {
        return (
          <>
            <RadioButtons
              title={"Select Pricing Mechanic"}
              onChange={handleSetOptions}
              value={options}
              section={"pricing"}
              property={"pricingMechanic"}
              items={priceNemes}
              disabled={disabled}
            />
            <Checkbox
              style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
              disabled={disabled}
              checked={options["pricing"]["productGroup"]}
              onChange={(e) => handleSetOptions("productGroup", e.target.checked)}
            >
              Include Custom Groups
            </Checkbox>
            <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
              (Using this view may result in duplicate rows where an individual product exists in multiple custom
              groups)
            </div>

            {amountDays <= 14 ? (
              <div className="export-hint" style={{ marginTop: "24px", fontFamily: "Gilroy-ExtraBold" }}>
                Additional {summaryName[sectionId]} display options (weekly average / snapshot) are available if you
                select a time range greater than 14 days
              </div>
            ) : null}

            {amountDays > 14 ? (
              <SelectCalculation disabled={disabled} options={options["pricing"]} handleSetOptions={handleSetOptions} />
            ) : null}
            <Checkbox
              style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
              disabled={disabled}
              checked={options["pricing"]["promotion"]}
              onChange={(e) => handleSetOptions("promotion", e.target.checked)}
            >
              Include Promotion Summary in Report
            </Checkbox>
          </>
        );
      }
      // promotions
      case 3: {
        return (
          <>
            <RadioButtons
              title={"Select View Type"}
              onChange={handleSetOptions}
              value={options}
              section={"promotions"}
              property={"viewType"}
              items={viewType}
              disabled={disabled}
            />
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["promotions"]["productGroup"]}
                onChange={(e) => handleSetOptions("productGroup", e.target.checked)}
              >
                Include Custom Groups
              </Checkbox>
            </div>
            <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
              (Using this view may result in duplicate rows where an individual product exists in multiple custom
              groups)
            </div>

            {options["promotions"].viewType !== "promoDate" && (
              <>
                {amountDays <= 14 ? (
                  <div className="export-hint" style={{ marginTop: "24px", fontFamily: "Gilroy-ExtraBold" }}>
                    Additional {summaryName[sectionId]} display options (weekly average / snapshot) are available if you
                    select a time range greater than 14 days
                  </div>
                ) : null}

                <Checkbox
                  style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                  disabled={disabled}
                  checked={options["promotions"]["grouped"]}
                  onChange={(e) => handleSetOptions("grouped", e.target.checked)}
                >
                  Group Promotions By Promotion Text
                </Checkbox>

                {amountDays > 14 ? (
                  <SelectCalculation
                    disabled={disabled}
                    options={options["promotions"]}
                    handleSetOptions={handleSetOptions}
                  />
                ) : null}

                {options["promotions"].viewType === "product" ? (
                  <div style={{ marginTop: "24px" }}>
                    <RadioButtons
                      title={"Select Colour Fill"}
                      onChange={handleSetOptions}
                      value={options}
                      section={"promotions"}
                      property={"fillColor"}
                      items={colourFill}
                      disabled={disabled}
                    />
                  </div>
                ) : null}
              </>
            )}
          </>
        );
      }
      // rating
      case 6: {
        return (
          <>
            <div>
              <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
                Select View Type
              </div>
              <Checkbox
                style={{ marginTop: "12px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["hasViewByRetailer"]}
                onChange={(e) => handleSetOptions("hasViewByRetailer", e.target.checked)}
              >
                View By Retailer
              </Checkbox>
              {options["rating"].hasViewByRetailer && (
                <div style={{ marginTop: "12px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"rating"}
                    property={"viewByRetailerType"}
                    items={viewByRetailerType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["hasViewByProduct"]}
                onChange={(e) => handleSetOptions("hasViewByProduct", e.target.checked)}
              >
                View By Product
              </Checkbox>
              {options["rating"].hasViewByProduct && (
                <div style={{ marginTop: "12px" }}>
                  <RadioButtons
                    onChange={handleSetOptions}
                    value={options}
                    section={"rating"}
                    property={"viewByProductType"}
                    items={viewByProductType}
                    disabled={disabled}
                    isVertical={true}
                    styleOverride={SUB_OPTION_SYTLE}
                  />
                </div>
              )}
            </div>
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["hasDetailedView"]}
                onChange={(e) => handleSetOptions("hasDetailedView", e.target.checked)}
              >
                Detailed View
              </Checkbox>
              {options["rating"].hasDetailedView && (
                <div style={{ marginTop: "12px", color: "red" }}>
                  {!isFullHistory ? (
                    <div style={{ marginTop: "12px" }}>
                      <RadioButtons
                        onChange={handleSetOptions}
                        value={options}
                        section={"rating"}
                        property={"isAllReviews"}
                        items={isAllReviews}
                        disabled={disabled}
                        isVertical={true}
                        styleOverride={SUB_OPTION_SYTLE}
                      />
                    </div>
                  ) : (
                    <div style={{ marginLeft: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}>
                      <div style={{ marginTop: "12px", opacity: 0.5 }}>
                        <Radio disabled>New Reviews within Time Period</Radio>
                      </div>
                      <div style={{ marginTop: "12px" }}>
                        <Radio checked>All Reviews</Radio>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div style={{ marginTop: "24px" }}>
              <Checkbox
                style={{ fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                checked={options["rating"]["hasNoReviews"]}
                onChange={(e) => handleSetOptions("hasNoReviews", e.target.checked)}
              >
                View Products with No Reviews
              </Checkbox>
            </div>
            <div>
              <Checkbox
                style={{ marginTop: "24px", fontFamily: "Gilroy-Medium", fontWeight: "bold" }}
                disabled={disabled}
                checked={options["rating"]["productGroup"]}
                onChange={(e) => handleSetOptions("productGroup", e.target.checked)}
              >
                Include Custom Groups
              </Checkbox>
            </div>
            <div className="export-hint" style={{ fontFamily: "Gilroy-Medium" }}>
              (Custom groups is only applicable to the View By Product and Detailed View options)
            </div>
          </>
        );
      }
      // content
      case 5:
      // navigation
      case 8:
      // availability
      case 9:
      // ranking
      case 4:
      // media
      case 7:
      // assortment
      case 10:
      default: {
        return (
          <div style={{ fontSize: "16px", marginTop: "15px", fontFamily: "Gilroy-Bold" }}>
            Report Options are coming soon.
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className="export-item">
        <div className="number">{current}</div>
        <div className="text">Report Options ({summaryName[sectionId]})</div>
      </div>

      {renderOptions()}
    </>
  );
};

export default ReportOptions;
