import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import moment from "moment";
//Components
import RenderNoData from "components/RenderNoData";
import RowOfBrands from "components/RowOfBrands";

HC_rounded(Highcharts);

const AssortmentSummaryChartSingle = (props) => {
  //Props
  const {
    data,
    currentChart,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    isCompare,
    compareData,
    comparativeDate,
    currentDate,
  } = props;

  //Refs
  const inputRef = useRef(null);
  const ref = useRef(null);

  //Constants
  const isProductsInPercent = currentChart[1].value === "percent";

  const renderLabelsStep = 16;
  const suffix = isProductsInPercent ? "%" : "";

  //States
  const [isMoreBtnClicked, setMoreBtnClick] = useState(false);
  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  const [isCompareDataIsEmpty, setIsCompareDataIsEmpty] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    pricings[0]?.data.length * 25 > ref?.current?.offsetWidth
      ? setChartWidth(pricings[0]?.data.length * 25)
      : setChartWidth(ref?.current?.offsetWidth);
  }, [ref, pricings]);

  const isIncluded = (value) => {
    return arrayOfDesiredBrand.includes(value);
  };

  const getDesiredArray = (data) => {
    const key = isProductsInPercent ? "productsPercentRound" : "productsCount";

    return data.map((el) => {
      const {
        item: { name, color },
        productsCount,
        productsPercentRound,
      } = el;

      const isInclude = isIncluded(name);

      const getClassName = () => {
        if (isInclude) {
          return "hightlighted";
        } else if (!isInclude && arrayOfDesiredBrand.length) {
          return "not-hightlighted";
        } else {
          return "not-hightlighted";
        }
      };

      return {
        name,
        y: el[key],
        className: getClassName(),
        color,
        productsCount,
        productsPercentRound,
      };
    });
  };

  const formDataArr = (arrOne, stack, nameForClass) => {
    return [
      {
        name: data.item.name,
        data: arrOne,
        stack,
        className: nameForClass,
      },
    ];
  };

  const findMissingElementsInArr = (filteredArr, currentArr, filterKey) => {
    return filteredArr.filter(({ [filterKey]: id1 }) => !currentArr.some(({ [filterKey]: id2 }) => id2 === id1));
  };

  const findMissingElementsInArrWithElKey = (filteredArr, currentArr, filterKey, elKey) => {
    return filteredArr.filter(
      ({ [filterKey]: id1 }) => !currentArr.some(({ [filterKey]: id2 }) => id2[elKey] === id1[elKey])
    );
  };

  const getArrWithZeroValuesByAnotherArr = (arr) => {
    return arr.map((el) => {
      return {
        ...el,
        productsCount: 0,
        productsPercentRound: 0,
      };
    });
  };

  //Converting Data
  useEffect(() => {
    const dataFilteredByChart = data.children;
    const compareDataFilteredByChart = compareData.children;

    if (dataFilteredByChart.length) {
      setEmptyData(false);

      //Find Names
      const namesArr = dataFilteredByChart.map(({ item: { name, color } }) => {
        return {
          name,
          color,
        };
      });
      //compareDataFilteredByChart may has different names
      const compareNames = compareDataFilteredByChart.length
        ? compareDataFilteredByChart.map(({ item }) => {
            const { name, color } = item;
            return {
              name,
              color,
            };
          })
        : [];
      //so need to find missing elements
      const missingNames = findMissingElementsInArr(compareNames, namesArr, "name");

      //And if find another names add them to existing names
      const arr = missingNames.length ? [...namesArr, ...missingNames] : namesArr;

      setNames(arr);

      const resultData = getDesiredArray(dataFilteredByChart);
      const stack = "current";

      const pricing = formDataArr(resultData, stack, "");

      setPricings(pricing);

      if (compareDataFilteredByChart.length) {
        //For current Data
        //Current data may has different with compare data so we need to find
        //difference in Current data by compare data and reverse
        const missingElements = findMissingElementsInArrWithElKey(
          compareDataFilteredByChart,
          dataFilteredByChart,
          "item",
          "name"
        );

        const emptyMissingEl = getArrWithZeroValuesByAnotherArr(missingElements);
        //If has difference add missing elements to current data
        const arr = missingElements.length ? [...dataFilteredByChart, ...emptyMissingEl] : dataFilteredByChart;

        const resultData = getDesiredArray(arr);
        const stack = "current";
        const pricing = formDataArr(resultData, stack, "");

        //For compare Data
        //Find missing elements in reverse
        const compareMissingElements = findMissingElementsInArrWithElKey(
          dataFilteredByChart,
          compareDataFilteredByChart,
          "item",
          "name"
        );

        const compareEmptyMissingEl = getArrWithZeroValuesByAnotherArr(compareMissingElements);

        const compareArr = compareMissingElements.length
          ? [...compareDataFilteredByChart, ...compareEmptyMissingEl]
          : compareDataFilteredByChart;
        //Sort compare arr by current arr to have same structure with zero values
        const filteredCompareArr = compareArr.sort((a, b) => {
          return (
            arr.findIndex((el) => el.item.name === a.item.name) - arr.findIndex((el) => el.item.name === b.item.name)
          );
        });
        const compareResultData = getDesiredArray(filteredCompareArr);

        const compareStack = "compare";
        const newData = formDataArr(compareResultData, compareStack, "not-hightlighted");

        setPricings([...newData, ...pricing]);
      }
    } else {
      setEmptyData(true);
    }
  }, [data, currentChart, isCompare, compareData, arrayOfDesiredBrand]);

  useEffect(() => {
    if (names.length > renderLabelsStep) {
      if (!arrayOfDesiredBrand.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, arrayOfDesiredBrand]);

  useEffect(() => {
    if (!compareData.children.length && isCompare) {
      setIsCompareDataIsEmpty(true);
    } else {
      setIsCompareDataIsEmpty(false);
    }
  }, [compareData, isCompare]);

  //Settings for chart
  const options = {
    title: {
      text: "",
    },

    chart: {
      animation: false,
      type: "column",
      width: chartWidth,
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },

    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        return renderTooltip(this);
      },
    },
    xAxis: {
      categories: names.map(({ name }) => name),
      labels: {
        useHTML: false,
        formatter: function () {
          return "";
        },
      },
    },
    yAxis: {
      labels: {
        format: "{value}" + `${suffix}`,
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
      title: {
        offset: 20,
        x: 25,
        text: `${isProductsInPercent ? "%" : "No"} of Products`,
      },
      offset: 25,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        pointWidth: 6,
        groupPadding: 0.2,
        borderRadiusTopLeft: "40%",
        borderRadiusTopRight: "40%",
        states: {},
        point: {
          events: {
            click: function () {
              onLabelClick(this.category);
            },
          },
        },
      },
    },
    series: pricings,
  };

  //if array of brands has at least 1 item remove div with opacity
  useEffect(() => {
    if (arrayOfDesiredBrand.length) {
      setIsTooMoreData(false);
    }
  }, [arrayOfDesiredBrand]);
  //put name of brand to array
  const onLabelClick = (key) => {
    let array;

    if (arrayOfDesiredBrand.includes(key)) {
      array = arrayOfDesiredBrand.filter((brand) => brand !== key);
      setArrayOfDesiredBrand(array);
    } else {
      array = [...arrayOfDesiredBrand, key];
      setArrayOfDesiredBrand(array);
    }
  };

  const renderTooltip = (thisObj) => {
    const baseArr = [[{ point: { options: { group: { count: 0, percent: 0 }, products: { count: 0, percent: 0 } } } }]];
    const compareData = thisObj.points.length
      ? thisObj.points.filter((item) => item?.series?.options.stack === "compare")
      : baseArr;
    const initData = thisObj.points.length
      ? thisObj.points.filter((item) => item?.series?.options.stack === "current")
      : baseArr;

    const name = thisObj.x;
    const { productsCount, productsPercentRound } =
      compareData.length && compareData[0].point.options ? compareData[0].point.options : initData[0].point.options;
    const { productsCount: productsCountCompare, productsPercentRound: productsPercentCompare } =
      initData[0].point.options;

    const nameForContextSecondLine = "No. of Products";

    const valueSecondLLine = `${productsCount} (${productsPercentRound}%)`;

    const valueSecondLLineIsCompare = `${productsCountCompare} (${productsPercentCompare}%)`;

    const compareDateArr = comparativeDate.split("|");
    const currentDateArr = currentDate.split("|");

    return `<div class="share-tooltip-wrapper">
        <div class="title" style="text-transform: capitalize">${data.item.name} > ${name}</div>
        <div style="${isCompare ? "display: flex" : ""}">
          <div>
          ${isCompare ? renderDateRow(compareDateArr) : ""}
            ${renderContextForTooltip(nameForContextSecondLine, valueSecondLLine)}
          </div>
          ${isCompare ? `<div class='line-separete' style="margin-top: 20px"></div>` : ""}
          ${
            isCompare
              ? `<div>
              ${renderDateRow(currentDateArr)}
              ${renderContextForTooltip("", valueSecondLLineIsCompare)}
              </div>`
              : ""
          }
        </div>
      </div>`;
  };

  const renderContextForTooltip = (name, value) => {
    return `<div class="row">
        <span style="margin-right: 8px">${name}</span>
        <span>${value}</span>
      </div>`;
  };

  const renderDateRow = (dateArr) => {
    return `<div class="row" style="margin-bottom: 10px">${moment(dateArr[2]).format("DD MMM YYYY")}</div>`;
  };

  const renderChartPlug = () => {
    if (!isCompareDataIsEmpty && isTooMoreData && !emptyData) {
      return (
        <div className={"too-more-data"} style={{ top: -5 }}>
          <p>Looks like we have a lot of data.</p>
          <p>Select the buttons below to see the graph.</p>
        </div>
      );
    }
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `100%` }} ref={ref}>
      {renderChartPlug()}
      {emptyData || isCompareDataIsEmpty ? (
        <RenderNoData />
      ) : (
        <HighchartsReact
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: "enableOverflow" }}
        />
      )}

      <div className="brand-labesl-row">
        {!isCompareDataIsEmpty ? (
          <RowOfBrands
            names={names}
            arrayOfDesiredBrand={arrayOfDesiredBrand}
            setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AssortmentSummaryChartSingle;
