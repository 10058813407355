import React, { useState, useEffect, useMemo } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useRetailers from "../../../../hooks/useRetailers";
import moment from "moment";

const LocationAnalysisAverageShareChart = (props) => {
  const {
    data,
    timePeriod,
    currentRetailer,
    outside,
  } = props;

  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);

  const { getRetailerColorHex, getRetailerBackgroundColor } = useRetailers();

  const getMainColor = (retailer) => getRetailerColorHex(retailer);
  const getBackground = (retailer) => getRetailerBackgroundColor(retailer);

  useEffect(() => {
    if (data.length) {
      let dataChart;
      let start;
      let end;

      dataChart = data.map((item) => ({
        y: Number(item.value),
        max: item.max,
        min: item.min,
        marker: {
          enabled: item.max || item.min,
          lineColor: item.max ? "#1cc08a" : "#fe6a68",
          lineWidth: 2,
          fillColor: "#fff",
          radius: 4,
        },
        date: item.date,
      }));
      start = dataChart[0]?.y;
      end = dataChart[dataChart.length - 1]?.y;

      let pricings = [
        {
          name: "Average Share",
          color: getMainColor(currentRetailer),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: getBackground(currentRetailer),
          },
          dataLabels: {
            enabled: false,
          },
          data: dataChart,
        },
      ];

      const datesMoment = data.map((item) => moment(item.date).format("DD MMM YYYY")).slice(timePeriod[0], timePeriod[1] + 1);

      setPricings(pricings);
      setDates(datesMoment);
    }
  }, [data, timePeriod]);

  console.log("Data in Average Share Chart:", data)

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 100,
      animation: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.x == 0) return this.point.options.label;
          },
        },
        events: {
          click: function (e) {
            // Disable the clicking until all ranking fetching logic is aligned
            // getProductPopup(e.point);
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 2,
        threshold: null,
      },
    },
    xAxis: {
      visible: false,
      crosshair: {
        width: 2,
        color: "#384493",
      },
      type: "line",
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      visible: false,
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      hideDelay: 0,
      shadow: false,
      outside: outside,
      formatter: function (params) {
        let format = '<div class="wrapper-category">'
        format += `<div class='title'F>${(this.points.length > 0 && !!this.points[0]) && this.points[0]?.series?.name}:</div>`
        format += `<div class='total-data'>${moment(dates[this.points[0].x]).format("DD MMM YY")}</div>`
        format += `<div class='wrapper'>`
        format += `<div class='box'>`
        format += `<div style="padding-right: 5px"><b>${Math.abs(this.points[0].y)}${"%"}</b></div>`
        format += `</div>`
        format += `</div>`
        format += `</div>`

        return format
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LocationAnalysisAverageShareChart;
