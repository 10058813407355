import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import moment from "moment";
//Components
import RenderNoData from "components/RenderNoData";
import RowOfBrands from "components/RowOfBrands";

import useRetailers from "../../../../../../hooks/useRetailers";
import { firstCharToUpperCase } from "utils/generalUtils";
import { changeNameRetailer } from "utils/changeNameRetailer";

HC_rounded(Highcharts);

const AssortmentSummaryChartAll = (props) => {
  //Props
  const {
    data,
    currentChart,
    setHoverEl,
    hoverEl,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    isCompare,
    compareData,
    setSelectedEntity,
    setCompareSelectedEntity,
    comparativeDate,
    currentDate,
  } = props;

  //Refs
  const inputRef = useRef(null);
  const ref = useRef(null);
  const { getRemoteLogo } = useRetailers();

  //Constants
  const isProductsInPercent = currentChart[1].value === "percent";

  const suffix = isProductsInPercent ? "%" : "";
  const isShowBrands = currentChart[0].value;
  const key = isProductsInPercent ? "productsPercent" : "productsCount";

  //States

  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);

  const [emptyData, setEmptyData] = useState(false);
  const [isCompareDataIsEmpty, setIsCompareDataIsEmpty] = useState(false);

  const getEmptyCondition = (arr) => {
    return arr.result.map(({ children }) => children).flat().length === 0;
  };

  useEffect(() => {
    const emptyCondition = getEmptyCondition(data);

    if (emptyCondition) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }
  }, [data]);

  useEffect(() => {
    const emptyCondition = getEmptyCondition(compareData);
    if (emptyCondition && isCompare) {
      setIsCompareDataIsEmpty(true);
    } else {
      setIsCompareDataIsEmpty(false);
    }
  }, [compareData, isCompare]);

  const isIncluded = (value) => {
    return arrayOfDesiredBrand.includes(value);
  };

  const isHovered = (value) => {
    return value === hoverEl;
  };

  const getClassName = (isInclude, isHover) => {
    if (isInclude) {
      return "hightlighted";
    } else if (!isInclude && arrayOfDesiredBrand.length) {
      return "not-hightlighted";
    } else if (hoverEl && hoverEl.length && !isHover) {
      return "not-hightlighted";
    } else if (isHover && hoverEl.length) {
      return "hightlighted";
    } else {
      return "hightlighted";
    }
  };

  const getClassNameForAllBrands = (isInclude) => {
    if (isInclude) {
      return "hightlighted";
    } else if (!isInclude && arrayOfDesiredBrand.length) {
      return "not-hightlighted";
    } else {
      return "not-hightlighted";
    }
  };

  const getDesiredArray = (data, nameForClass) => {
    return data.map((el) => {
      const {
        item: { name, color },
        children,
        total: { group, products },
      } = el;

      const y = children.reduce((acc, current) => acc + +current[key], 0);

      const isInclude = isIncluded(name);

      const isHover = isHovered(name);

      return {
        name,
        y,
        color: color,
        group,
        products,
        className: nameForClass ? nameForClass : getClassName(isInclude, isHover),
      };
    });
  };

  const getArrOfProductsObj = (arr1, arr2, arr3, stack) => {
    let arr = [];
    for (let index = 0; index < arr1.length; index++) {
      const el = arr1[index];
      let productObj = {
        name: el.item.name,
        color: el.item.color,
        data: [],
        total: [],
      };
      for (let i = 0; i < arr2.length; i++) {
        const element = arr2[i];
        let matchedEl;
        for (let indx = 0; indx < element.length; indx++) {
          const product = element[indx];
          if (product.item.id === el.item.id) {
            matchedEl = product;
          }
        }
        if (matchedEl) {
          productObj.data.push(matchedEl);
        } else {
          productObj.data.push({ productsCount: 0, productsPercent: 0, item: { name: "" } });
        }
        productObj.total.push(arr3[i]);
      }
      const result = formDataForAllBrands(productObj, stack);
      arr.push(result);
    }
    return arr;
  };

  const getChildrenArr = (arr) => {
    return arr.result.map(({ children }) => children);
  };

  const getTotalArr = (arr) => {
    return arr.result.map(({ total }) => total);
  };

  const getNotRepeatedProducts = (arr) => {
    const products = arr.map(({ children }) => children.map((el) => el)).flat();
    const notRepeatedProducts = products.reduce((acc, current) => {
      const isInArray = acc.some((el) => el.item.id === current.item.id);
      if (!isInArray) {
        acc.push(current);
      }
      return acc;
    }, []);
    return notRepeatedProducts;
  };

  const formDataForAllBrands = (product, stack) => {
    const data = product.data.map((el, i) => {
      const name = el.item.name;
      const isInclude = isIncluded(name);

      return {
        y: +el[key],
        name,
        group: product.total[i].group,
        products: product.total[i].products,
        className: getClassNameForAllBrands(isInclude),
      };
    });

    return {
      name: product.name,
      color: product.color,
      data,
      stack,
    };
  };

  const prepareNamesArr = (arr) => {
    return arr.map(({ item: { name, color } }) => ({ name, color }));
  };

  //Converting Data
  useEffect(() => {
    if (data.result.length) {
      const currentStack = "current";
      const compareStack = "compare";

      const childrenArr = getChildrenArr(data);
      const totalArr = getTotalArr(data);
      const notRepeatedProducts = getNotRepeatedProducts(data.result);

      const namesArr = prepareNamesArr(notRepeatedProducts);

      setNames(namesArr);

      const allRetailersData = getDesiredArray(data.result);

      const pricing = [
        {
          name: "AllRetailers",
          data: allRetailersData,
          borderRadiusTopLeft: "10%",
          borderRadiusTopRight: "10%",
          stack: currentStack,
        },
      ];

      setPricings(pricing);

      if (isShowBrands && !compareData.result.length) {
        const result = getArrOfProductsObj(notRepeatedProducts, childrenArr, totalArr, currentStack);
        setPricings(result);
      }

      if (isShowBrands && compareData.result.length) {
        const compareChildrenArr = getChildrenArr(compareData);
        const compareTotalArr = getTotalArr(compareData);
        const compareNotRepeatedProducts = getNotRepeatedProducts(compareData.result);

        const newNames = getNotRepeatedProducts([...data.result, ...compareData.result]);
        const newNamesArr = prepareNamesArr(newNames);
        setNames(newNamesArr);

        const result = getArrOfProductsObj(notRepeatedProducts, childrenArr, totalArr, currentStack);
        const newData = getArrOfProductsObj(
          compareNotRepeatedProducts,
          compareChildrenArr,
          compareTotalArr,
          compareStack
        );

        setPricings([...newData, ...result]);
      }

      if (compareData.result.length && !isShowBrands) {
        const allRetailersNew = getDesiredArray(compareData.result, "not-hightlighted");

        const newData = [
          {
            name: "AllRetailers",
            data: allRetailersNew,
            borderRadiusTopLeft: "10%",
            borderRadiusTopRight: "10%",
            stack: compareStack,
          },
        ];

        setPricings([...newData, ...pricing]);
      }
    }
  }, [data, currentChart, compareData, arrayOfDesiredBrand, hoverEl, isShowBrands, isProductsInPercent]);

  //Settings for chart
  const options = {
    title: {
      text: "",
    },

    chart: {
      animation: false,
      type: "column",
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        return renderTooltip(this);
      },
    },
    xAxis: {
      categories: data.result.map(({ item: { name } }) => name),
      labels: {
        useHTML: true,
        formatter: function () {
          return `<img style="width: 25px;" src=${getRemoteLogo(this.value)}></img>`;
        },
      },
    },
    yAxis: {
      labels: {
        format: "{value}" + `${suffix}`,
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
      title: {
        offset: 20,
        x: 25,
        text: `${isProductsInPercent ? "%" : "No"} of Products`,
      },
      offset: 25,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: "normal",
      },
      series: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        pointWidth: 24,
        states: {},
        point: {
          events: {
            click: function () {
              onColumnClick(this.category);
            },
            mouseOut: function () {
              setElement("");
            },
            mouseOver: function () {
              setElement(this.category);
            },
          },
        },
      },
    },
    series: pricings,
  };

  const onColumnClick = (name) => {
    const matchedEl = data.result.length ? data.result.find(({ item }) => item.name === name) || {} : {};
    const matchedCompareEl = compareData.result.length
      ? compareData.result.find(({ item }) => item.name === name) || { children: [] }
      : { children: [] };
    setSelectedEntity(matchedEl);
    setCompareSelectedEntity(matchedCompareEl);
  };

  const renderTooltip = (thisObj) => {
    const baseArr = [[{ point: { options: { group: { count: 0, percent: 0 }, products: { count: 0, percent: 0 } } } }]];
    const compareData = thisObj.points.length
      ? thisObj.points.filter((item) => item?.series?.options.stack === "compare")
      : baseArr;
    const initData = thisObj.points.length
      ? thisObj.points.filter((item) => item?.series?.options.stack === "current")
      : baseArr;

    const name = thisObj.x;
    const { group: firstGroup, products: firstProducts } =
      compareData.length && compareData[0].point.options ? compareData[0].point.options : initData[0].point.options;
    const { group: secondGroup, products: secondProducts } = initData[0].point.options;
    const entity = currentChart[2].value === "manufacture" ? "manufacturer" : currentChart[2].value;
    const nameForContextFirstLine = `No. of ${firstCharToUpperCase(entity)}s`;
    const nameForContextSecondLine = "No. of Products";

    const valueFirstLine = `${firstGroup.count} (${firstGroup.percent}%)`;
    const valueSecondLLine = `${firstProducts.count} (${firstProducts.percent}%)`;

    const valueFirstLineIsCompare = `${secondGroup.count}(${secondGroup.percent}%)`;
    const valueSecondLLineIsCompare = `${secondProducts.count}(${secondProducts.percent}%)`;

    const compareDateArr = comparativeDate.split("|");
    const currentDateArr = currentDate.split("|");

    return `<div class="share-tooltip-wrapper">
        <div class="title" style="text-transform: capitalize">${changeNameRetailer(name)}</div>
        <div style="${isCompare ? "display: flex" : ""}">
          <div>
            ${isCompare ? renderDateRow(compareDateArr) : ""}
            ${renderContextForTooltip(nameForContextFirstLine, valueFirstLine)}
            ${renderContextForTooltip(nameForContextSecondLine, valueSecondLLine)}
          </div>
          ${isCompare ? `<div class='line-separete' style="margin-top: 20px"></div>` : ""}
          ${
            isCompare
              ? `<div>
              ${renderDateRow(currentDateArr)}
              ${renderContextForTooltip("", valueFirstLineIsCompare)}
              ${renderContextForTooltip("", valueSecondLLineIsCompare)}
              </div>`
              : ""
          }
        </div>
      </div>`;
  };

  const renderContextForTooltip = (name, value) => {
    return `<div class="row">
        <span style="margin-right: 8px">${name}</span>
        <span>${value}</span>
      </div>`;
  };

  const renderDateRow = (dateArr) => {
    return `<div class="row" style="margin-bottom: 10px">${moment(dateArr[2]).format("DD MMM YYYY")}</div>`;
  };

  //set hover element
  const setElement = (el) => {
    setHoverEl(el);
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `100%` }} ref={ref}>
      {emptyData || isCompareDataIsEmpty ? (
        <RenderNoData />
      ) : (
        <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />
      )}

      <div className="brand-labesl-row">
        {isShowBrands ? (
          <RowOfBrands
            names={names}
            arrayOfDesiredBrand={arrayOfDesiredBrand}
            setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AssortmentSummaryChartAll;
