import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNavigationLocationAnalysisSingleDay } from "store/navigation/navigationLocationAnalysis/actions";
import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import useFilters from "../../../../hooks/useFilters";

import TrendAllDay from "./TrendAllDay";
// import SummaryTerm from "tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/СurrentlyTrend/SummaryTerm";
// import AllTerms from "tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/СurrentlyTrend/AllTerms";

// const TrendSingleDay = (props) => {
//   const {
//     currentManufacturerId,
//     currentManufacturer,
//     selectProduct,
//     type,
//     actionSingleProduct,
//     storeSingleProduct,
//     actionSingleProductSummary,
//     actionSingleProductSetPeriod,
//     actionRemoveProduct,
//     actionProductCompare,
//     storeProductCompare,
//     actionSingleProductCompareSummary,
//     actionSingleProductSetCompare,
//     statusProduct,
//   } = props;

//   const dispatch = useDispatch();

//   const { data } = useSelector((state) => state.navigationLocationAnalysis.navigationLocationAnalysisSingleDay);
//   const { date } = useSelector((state) => state.navigationLocationAnalysis.navigationLocationAnalysisSingleDay);
//   const { lastFilter } = useFilters();

//   const { productsStatus } = useSelector((state) => state.navigationLocationAnalysis);
//   const { currentShareLocationSingleDay } = useSelector((state) => state.navigationLocationAnalysis);
//   const [isFeaturedProducts, setIsFeaturedProducts] = useState(true);

//   useEffect(() => {
//     let activeTab;
//     if (selectProduct.length) {
//       activeTab = selectProduct;
//     } else {
//       activeTab = "Summary";
//     }
//     dispatch(
//       fetchNavigationLocationAnalysisSingleDay({
//         type: "location",
//         searchTerm: activeTab,
//         manufacturer: currentManufacturerId.toString(),
//         sourceType: currentShareLocationSingleDay.sourceType,
//         timePeriod: getTimePeriodRanking(lastFilter.date),
//         product: lastFilter.product,
//         featured: isFeaturedProducts,
//       })
//     );
//   }, [
//     fetchNavigationLocationAnalysisSingleDay,
//     lastFilter,
//     currentManufacturerId,
//     selectProduct,
//     currentShareLocationSingleDay,
//     isFeaturedProducts,
//   ]);

//   return (
//     <div className="wrapper-box-relative">
//       {productsStatus === STATE_STATUSES.READY ? (
//         <>
//           {selectProduct.length && data?.length === 1 && Object.entries(data[0]).length <= 3 ? (
//             <AllTerms
//               data={data}
//               date={date}
//               activeTab={selectProduct}
//               isOpen={true}
//               storeShareSingleDay={"currentShareLocationSingleDay"}
//               type={type}
//               actionSingleProduct={actionSingleProduct}
//               storeSingleProduct={storeSingleProduct}
//               actionRemoveProduct={actionRemoveProduct}
//               actionProductCompare={actionProductCompare}
//               storeProductCompare={storeProductCompare}
//               statusProduct={statusProduct}
//               isFeaturedProducts={isFeaturedProducts}
//               setIsFeaturedProducts={setIsFeaturedProducts}
//             />
//           ) : (
//             <SummaryTerm
//               data={data}
//               date={date}
//               currentManufacturer={currentManufacturer}
//               termType={"Location Term"}
//               type={type}
//               actionSingleProduct={actionSingleProduct}
//               storeSingleProduct={storeSingleProduct}
//               actionSingleProductSummary={actionSingleProductSummary}
//               actionSingleProductSetPeriod={actionSingleProductSetPeriod}
//               actionRemoveProduct={actionRemoveProduct}
//               actionProductCompare={actionProductCompare}
//               storeProductCompare={storeProductCompare}
//               actionSingleProductCompareSummary={actionSingleProductCompareSummary}
//               actionSingleProductSetCompare={actionSingleProductSetCompare}
//               statusProduct={statusProduct}
//               isFeaturedProducts={isFeaturedProducts}
//               setIsFeaturedProducts={setIsFeaturedProducts}
//             />
//           )}
//         </>
//       ) : null}
//       {productsStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
//     </div>
//   );
// };

const CurrentlyViewingTrend = (props) => {
  const {
    data,
    // currentManufacturerId,
    // currentManufacturer,
    // selectProduct,
    // type,
    // actionSingleProduct,
    // storeSingleProduct,
    // actionSingleProductSummary,
    // actionSingleProductSetPeriod,
    // actionRemoveProduct,
    // actionProductCompare,
    // storeProductCompare,
    // actionSingleProductCompareSummary,
    // actionSingleProductSetCompare,
    // statusProduct,
  } = props;

  const { date } = useSelector((state) => state.authorization.lastFilter);

  const [isSingleDate, setIsSingleDate] = useState(false);

  useEffect(() => {
    if (date.length) {
      if (Number(date)) {
        if (Number(date) === 1 || Number(date) === 2) {
          setIsSingleDate(true);
        } else {
          setIsSingleDate(false);
        }
      } else {
        const startDate = date?.split("|")[1]?.split("-")[2];
        const endDate = date?.split("|")[2]?.split("-")[2];

        const numberDate = Number(endDate) - Number(startDate);

        if (numberDate === 0) {
          setIsSingleDate(true);
        } else {
          setIsSingleDate(false);
        }
      }
    }
  }, [date]);

  return (
    <>
      {/* {isSingleDate ? (
        <TrendSingleDay
          currentManufacturerId={currentManufacturerId}
          currentManufacturer={currentManufacturer}
          selectProduct={selectProduct}
          type={type}
          actionSingleProduct={actionSingleProduct}
          storeSingleProduct={storeSingleProduct}
          actionSingleProductSummary={actionSingleProductSummary}
          actionSingleProductSetPeriod={actionSingleProductSetPeriod}
          actionRemoveProduct={actionRemoveProduct}
          actionProductCompare={actionProductCompare}
          storeProductCompare={storeProductCompare}
          actionSingleProductCompareSummary={actionSingleProductCompareSummary}
          actionSingleProductSetCompare={actionSingleProductSetCompare}
          statusProduct={statusProduct}
        />
      ) : ( */}
        <TrendAllDay data={data} activeTab={"Summary"} />
      {/* )} */}
    </>
  );
};

export default CurrentlyViewingTrend;
