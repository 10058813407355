import React, { useState, useEffect, useCallback } from 'react'
import { connect } from "react-redux";
import { useDispatch } from "react-redux"
import MainFilters from 'components/Filters/MainFilters'
import NavBar from 'components/NavBar'
import RankingTopTabs from 'tabs/RankingTabs/RankingTopTabs/RankingTopTabs'
import RankingBottomTabs from 'tabs/RankingTabs/RankingBottomTabs/RankingBottomTabs'
import { STATE_STATUSES } from "utils/statuses"
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

const Ranking = (props) => {
    const { filtersStatus, prepareLogoutStatus, statusAuth } = props
    const dispatch = useDispatch()
    const [isFeaturedProducts, setIsFeaturedProducts] = useState(true);
    const [ activeTabTop, setActiveTabTop ] = useState(1)
    const [ activeTabBottom, setActiveTabBottom ] = useState(1)
    const {
      mainLastFilter,
      statusMainFilters,
      universalFiltersStatus,
      statusFilters,
      titleDateStatus,
    } = useFilters();

    const getActionTop = useCallback(() => [ 'SET_PRISTINE_OVERVIEW_SEARCH', 'SET_PRISTINE_RETAILER_SEARCH'][activeTabTop-1],
    [activeTabTop]);
    const getPristineTop = () => activeTabTop > 1 ? false : true

    const getActionBottom = useCallback(() => [ 'SET_PRISTINE_ANALYSIS_SEARCH', 'SET_PRISTINE_ANALYSIS_LOCATION'][activeTabBottom-1],
    [activeTabBottom]);

    const getPristineBottom = () => activeTabBottom > 1 ? false : true

    useEffect(() => {
        return () => {
            dispatch({ type: getActionTop(), payload: getPristineTop() })
            dispatch({ type: getActionBottom(), payload: getPristineBottom() })
        }
    }, [])

    useEffect(() => {
        if(filtersStatus === STATE_STATUSES.PENDING) {
            dispatch({ type: getActionTop(), payload: true })
            dispatch({ type: getActionBottom(), payload: true })
        }
    }, [filtersStatus, dispatch, getActionBottom, getActionTop])

    return (
        <div className='main-page-wrapper'>
            <NavBar />
            <div className='main-page-box'>
                <MainFilters />
                <div className='main-title-page'>Ranking</div>
                <div className='main-tabs-box'>
                    <RankingTopTabs
                        setActiveTab={setActiveTabTop}
                        isFeaturedProducts={isFeaturedProducts}
                        setIsFeaturedProducts={setIsFeaturedProducts}
                    />                
                </div>
                <div className='main-tabs-box'>
                   <RankingBottomTabs
                      setActiveTab={setActiveTabBottom}
                      isFeaturedProducts={isFeaturedProducts}
                    />
                </div>
            </div>
            {
                statusAuth !== STATE_STATUSES.PENDING &&
                mainLastFilter &&
                statusMainFilters === STATE_STATUSES.READY &&
                universalFiltersStatus === STATE_STATUSES.READY &&
                statusFilters === STATE_STATUSES.READY &&
                titleDateStatus === STATE_STATUSES.READY &&
                prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
            } 
            </div>
    )
}

export default connect(
    (state) => ({
        isPristineOverviewSearch: state.rankingSearchOverview.isPristine,
        isPristineSearchRetailer: state.rankingSearchRetailer.isPristine,

        isPristineAnalysisSearch: state.rankingSearchAnalysis.isPristine,

        filtersStatus: state.filters.status,
        prepareLogoutStatus: state.authorization.prepareLogoutStatus,
        statusAuth: state.authorization.status,
    }), null
)(Ranking)
