import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../hooks/useColorTheme";
import styled from "styled-components";
import { Empty } from "antd";

import { fetchCurrentPricePopup } from "store/pricing/priceTrend/actions";

import useRetailers from "../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../hooks/useCountry";
import useFilters from "hooks/useFilters";

import { getTimePeriod } from "utils/getTimePeriod";
import { roundingNumbers } from "utils/roundingNumbers";
import { changeNameRetailer } from "utils/changeNameRetailer";

import LoaderBox from "components/LoaderBox";
import PopupPrice from "tabs/PricingTabs/PricingBottomTabs/CurrentPricesTab/PopupPrice";

import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import gray from "assets/images/gray.svg";
import star from "assets/images/star.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";

import category from "assets/images/category.svg";
import productGroup from "assets/images/productGroup.svg";
import brand from "assets/images/brand.svg";
import manufacturer from "assets/images/manufacturer.svg";

const Styles = styled.div.attrs((props) => props)`
  margin-left: 25px;
  position: relative;
  .topMovers {
    width: 400px;
    height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .main-title {
    display: flex;
    align-items: center;
    font-family: "Gilroy-Bold";
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #abaaba;
    margin-bottom: 10px;
  }
  .main-title-right {
    font-family: "Gilroy-Bold";
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #abaaba;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    .sort-box {
      display: flex;
      align-items: center;
    }
  }
  .mover {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .image {
    width: 40px;
    height: 40px;
  }
  .title {
    font-family: "Gilroy-Medium";
    width: 210px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    margin: 0 15px;
    text-transform: capitalize;
  }
  .star {
    display: flex;
    align-items: center;
    width: 24px;
  }
  .percent {
    font-family: "Gilroy-Medium";
    width: 54px;
    text-align: right;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
  }
  .direction {
    width: 24px;
    margin-left: 10px;
  }
  .direction.rotate {
    transform: rotate(90deg);
  }
  .any-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    img {
      width: 28px;
      height: 29px;
    }
  }
  .wrapper-prod {
    position: relative;
    .retailer-marcker {
      width: 18px;
      height: 18px;
      position: absolute;
      border-radius: 50%;
      right: 0;
      bottom: 0;
    }
  }
  .no-data-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f2f4;
  }

  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .popup-box {
      max-width: 840px;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;
      .title-box {
        display: flex;
        margin-bottom: 30px;
        .title-product {
          font-size: 22px;
          font-family: "Gilroy-ExtraBold";
          color: #000000;
        }
        .date-product {
          font-family: "Gilroy-Medium";
          font-size: 14px;
          color: #374456;
        }
        .product-img {
          width: 120px;
          height: 120px;
          margin-right: 25px;
        }
      }
      .cancel {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 12px;
        top: 12px;
        cursor: pointer;
      }
    }
    .price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      .line {
        width: 220px;
        height: 4px;
        background-color: #25c891;
      }
      .price-type {
        width: 220px;
        height: 176px;
        padding: 15px;
        .title-price {
          font-size: 14px;
          color: #000000;
          font-family: "Gilroy-ExtraBold";
        }
        .no-promotions {
          margin-top: 40px;
        }
        .pricing {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;
          .price-product {
            font-size: 14px;
            color: #000000;
            font-family: "Gilroy-ExtraBold";
          }
          .date-prod {
            font-size: 12px;
            color: #3a3d40;
            font-family: "Gilroy-Medium";
          }
        }
        .change {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .not-listed {
    color: #abaaba;
    font-size: 12px;
    font-family: "Gilroy-ExtraBold";
  }
  .hint-price {
    font-size: 10px;
    font-family: Gilroy-ExtraBold;
  }
  .hint-price.hight {
    color: #ff6866;
  }
  .hint-price.best {
    color: #1ec68d;
  }
  .product-link {
    display: flex;
    align-items: center;
    margin-top: 25px;
    a {
      font-size: 16px;
      color: ${(props) => props.color};
      font-family: "Gilroy-ExtraBold";
      display: block;
    }
    img {
      margin-right: 10px;
      width: 25px;
    }
  }
  .promoted-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .product-link {
      margin: 0;
    }
  }
`;

const TopMovers = (props) => {
  const { isSwitch, currentChart, priceParam, topMovers } = props;
  const { formatCurrencyNumber } = useCountry();

  const dispatch = useDispatch();
  const { lastFilter } = useFilters();
  const { date } = lastFilter;
  const { primaryColor } = useColorTheme();
  const { getRemoteLogo } = useRetailers();

  const [moversParam, setMoversParam] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataPopup, setDataPopup] = useState({});

  const getIcon = (name) => {
    const icons = {
      // byBrand: brand,
      byCategory: category,
      byManufacturer: manufacturer,
      byProductGroup: productGroup,
    };

    return icons[name];
  };

  const renderMoversPriceTrend = () => {
    let movers = [];

    if (Object.entries(topMovers).length !== 0) {
      switch (priceParam) {
        case "shelfPrices": {
          movers = topMovers.topMoversShelf;
          break;
        }
        case "basePrices": {
          movers = topMovers.topMoversBase;
          break;
        }
        case "promotedPrices": {
          movers = topMovers.topMoversPromoted;
          break;
        }
      }
    }

    return movers;
  };

  const renderMoversRanking = () => {
    let movers = [];

    switch (moversParam) {
      case true: {
        movers = topMovers.cheapestPrice;
        break;
      }
      case false: {
        movers = topMovers.expensivePrice;
        break;
      }
    }

    return movers;
  };

  const getPopupProduct = (item) => {
    if (currentChart === "byTotal") {
      setLoading(true);
      dispatch(fetchCurrentPricePopup(item.id, item.retailerId, getTimePeriod(date))).then((response) => {
        setDataPopup(response.data);
        setShowPopup(true);
        setLoading(false);
      });
    }
  };

  return (
    <Styles color={primaryColor}>
      {isSwitch ? (
        <>
          {renderMoversRanking().length ? (
            <div className="main-title-right">
              <div className="sort-box" onClick={() => setMoversParam(!moversParam)}>
                <span>{`${moversParam ? "Cheapest" : "Most Expensive"}`}</span>
                <img src={moversParam ? sortIconDown : sortIconUp} alt="sortIcon" className="sortIcon" />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div className="main-title">Top Movers</div>
      )}
      <div className="topMovers">
        {isSwitch ? (
          <>
            {renderMoversRanking().length ? (
              renderMoversRanking().map((item, index) => (
                <div className="mover" key={index}>
                  <div className="wrapper-prod">
                    <img className="image" src={item.productImage} alt="banner" />
                    {item.retailer ? (
                      <img className="retailer-marcker" src={getRemoteLogo(item.retailer)} alt="retailer" />
                    ) : null}
                  </div>
                  <div className="title">{changeNameRetailer(item.title)}</div>
                  <div className="star">{item.star ? <img src={star} alt="star" /> : null}</div>
                  <div className="percent">
                    {formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data-box">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </>
        ) : (
          <>
            {renderMoversPriceTrend().length ? (
              renderMoversPriceTrend().map((item, index) => (
                <div
                  className="mover"
                  key={index}
                  style={{
                    cursor: currentChart === "byTotal" ? "pointer" : "default",
                  }}
                  onClick={() => getPopupProduct(item)}
                >
                  {currentChart === "byTotal" ? (
                    <div className="wrapper-prod">
                      <img className="image" src={item.image} alt="banner" />
                      {item.retailer ? (
                        <img className="retailer-marcker" src={getRemoteLogo(item.retailer)} alt="retailer" />
                      ) : null}
                    </div>
                  ) : currentChart === "byRetailer" ? (
                    <img className="image" src={getRemoteLogo(item.title)} alt="banner" />
                  ) : (
                    <div className="any-item" style={{ background: item.color }}>
                      {currentChart !== "byBrand" ? <img src={getIcon(currentChart)} alt="product" /> : null}
                    </div>
                  )}
                  <div className="title">{changeNameRetailer(item.title)}</div>
                  <div className="star">{item.star ? <img src={star} alt="star" /> : null}</div>
                  <div className="percent">{Number.parseFloat(item.percent).toFixed(1)}%</div>
                  {Number(item.percent) === 0 ? (
                    <img className="direction rotate" src={gray} alt="direction" />
                  ) : (
                    <img className="direction" src={item.direction ? green : red} alt="direction" />
                  )}
                </div>
              ))
            ) : (
              <div className="no-data-box">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </>
        )}
      </div>
      {loading ? <LoaderBox /> : null}
      {showPopup ? <PopupPrice data={dataPopup} closePopup={() => setShowPopup(false)} /> : null}
    </Styles>
  );
};

export default TopMovers;
