import { success, error } from "redux-saga-requests";
import {
  FETCH_NAVIGATION_LOCATION_ANALYSIS,
  SET_CURRENT_SHARE_LOCATION,
  SET_CURRENT_ITEM_TERM_LOCATION,
  SET_TERM_PRODUCT,
  FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY,
  SET_CURRENT_SHARE_LOCATION_SINGLE_DAY,
  FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS,
  FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP,
  SET_SHOW_PRODUCT_LOCATION_POPUP,
  SET_PRISTINE_ANALYSIS_LOCATION,
  FETCH_ANALYSIS_SINGLE_PRODUCT,
  FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY,
  FETCH_ANALYSIS_SINGLE_PRODUCT_SET_PERIOD,
  REMOVE_ANALYSIS_PRODUCT,
  FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE,
  FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY,
  SET_ANALYSIS_SINGLE_PRODUCT_COMPARE,
} from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  navigationLocationAnalysis: [],
  currentItemTermLocation: {},
  currentShareLocation: {},
  termProdcut: "",
  navigationLocationAnalysisSingleDay: {
    data: [],
  },
  currentShareLocationSingleDay: {},
  navigationLocationAnalysisProducts: {},
  navigationLocationAnalysisProductsPopup: [],
  showProductPopup: false,
  productsStatus: STATE_STATUSES.INIT,
  analisisSingleProduct: [],
  analisisSingleProductCompare: [],
  popupStatus: STATE_STATUSES.INIT,
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NAVIGATION_LOCATION_ANALYSIS: {
      return processReducer(state);
    }
    case success(FETCH_NAVIGATION_LOCATION_ANALYSIS): {
      return {
        ...state,
        navigationLocationAnalysis: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_NAVIGATION_LOCATION_ANALYSIS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_CURRENT_ITEM_TERM_LOCATION: {
      return {
        ...state,
        currentItemTermLocation: action.payload,
      };
    }

    case SET_CURRENT_SHARE_LOCATION: {
      return {
        ...state,
        currentShareLocation: action.payload,
      };
    }

    case SET_TERM_PRODUCT: {
      return {
        ...state,
        termProdcut: action.payload,
      };
    }

    case FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY: {
      return {
        ...state,
        productsStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY): {
      return {
        ...state,
        navigationLocationAnalysisSingleDay: action.data,
        productsStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY): {
      const error = action.error.response;
      return {
        ...state,
        productsStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case SET_CURRENT_SHARE_LOCATION_SINGLE_DAY: {
      return {
        ...state,
        currentShareLocationSingleDay: action.payload,
      };
    }

    case FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS: {
      return {
        ...state,
        productsStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS): {
      return {
        ...state,
        navigationLocationAnalysisProducts: action.data,
        productsStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS): {
      const error = action.error.response;
      return {
        ...state,
        productsStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP: {
      return {
        ...state,
        productsStatus: STATE_STATUSES.PENDING,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP): {
      return {
        ...state,
        navigationLocationAnalysisProductsPopup: action.data,
        showProductPopup: true,
        productsStatus: STATE_STATUSES.READY,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP): {
      const error = action.error.response;
      return {
        ...state,
        productsStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case SET_SHOW_PRODUCT_LOCATION_POPUP: {
      return {
        ...state,
        showProductPopup: action.payload,
        analisisSingleProduct: [],
      };
    }

    case SET_PRISTINE_ANALYSIS_LOCATION: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case FETCH_ANALYSIS_SINGLE_PRODUCT: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_ANALYSIS_SINGLE_PRODUCT): {
      let products = action.meta.isSingle
        ? [...state.analisisSingleProduct, ...action.data.products]
        : action.data.products;

      return {
        ...state,
        analisisSingleProduct: products,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_ANALYSIS_SINGLE_PRODUCT): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY): {
      return {
        ...state,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_ANALYSIS_SINGLE_PRODUCT_SET_PERIOD: {
      return {
        ...state,
        analisisSingleProduct: action.payload,
        error: null,
      };
    }

    case REMOVE_ANALYSIS_PRODUCT: {
      let products = action.payload ? state.analisisSingleProduct.filter((item) => item.id == action.payload) : [];

      return {
        ...state,
        analisisSingleProduct: products,
      };
    }

    case FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE): {
      return {
        ...state,
        analisisSingleProductCompare: action.data.products,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY): {
      return {
        ...state,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case SET_ANALYSIS_SINGLE_PRODUCT_COMPARE: {
      return {
        ...state,
        analisisSingleProductCompare: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
