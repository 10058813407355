import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import useCountry from "../../hooks/useCountry";
import { tz } from "moment-timezone";
import { getAlert, editAlert, fetchAlertFilters } from "store/alerts/actions";
import { STATE_STATUSES } from "utils/statuses";

import AlertCruds from "components/Alerts/AlertCruds";
import LoaderBox from "components/LoaderBox";
import { flattenHistoricAlertBrands } from "utils/flattenHistoricAlertBrands";
import AlertMessage from "./components/AlertMessage";
import { checkEmail } from "utils/checkEmail";

const EditAlert = ({ match: { params }, history }) => {
  const dispatch = useDispatch();
  const { countryStatus } = useCountry();
  const { alert, filters, alerts } = useSelector((state) => state.alerts);
  const { status } = useSelector((state) => state.alerts);
  const {
    settingsYourProfile: { retailers },
  } = useSelector((state) => state.settingsYourProfile);
  const [isEmptyName, setIsEmptyName] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [historicBrands, setHistoricBrands] = useState([]);
  const { email } = useSelector((state) => state.settingsYourProfile.settingsYourProfile);
  const userEmail = email;
  const [emails, setEmails] = useState([""]);
  let fetchedAlertBrands = [];

  useEffect(() => {
    if (countryStatus !== STATE_STATUSES.READY) {
      history.push("/alerts");
    }
  }, [countryStatus, history]);

  useEffect(() => {
    dispatch(getAlert(params.id)).then((response) => {
      const { emails } = response.data;
      dispatch({ type: "SET_PRISTINE_ALERTS", payload: true });
      setEmails(emails.length ? emails : [""]);
      dispatch(
        fetchAlertFilters({
          sourceType: response.data.filters.retailer.join("|"),
          productBrand: response.data.filters.productBrand.join("|"),
          category: response.data.filters.category.join("|"),
          manufacture: response.data.filters.manufacturer.join("|"),
          product: response.data.filters.product.join("|"),
          save: false,
        })
      ).then(() => dispatch({ type: "SET_PRISTINE_ALERTS", payload: false }));
    });
  }, []);

  useEffect(() => {
    if (Object.entries(alert).length !== 0) {
      if (alert.filters.productBrand.includes("All")) {
        // Could have 100+ brands come back in an array of arrays (of unknown length)
        // so we need a way of flattening this structure
        // Using a reduce to spread the elements from our alert's brandObj into the accumulator into a single,
        // concatenated array. We filter the alerts array for the alert we want beforehand so we know we're grabbing the correct alert
        // - Results in a single array containing all the brands from the alert's brandObj.
        const flattenedObjects = alerts.reduce(
          (acc, item) => (item.id === alert.id ? [...acc, ...(item.brandObj || [])] : acc),
          []
        );

        flattenedObjects.forEach((brand) => {
          fetchedAlertBrands.push(brand.brandId);
        });
      }
      const historicAlertBrands = flattenHistoricAlertBrands(alerts, alert);
      setHistoricBrands(historicAlertBrands);

      setQueryParams({
        name: alert.name,
        sourceType: alert.filters.retailer,
        category: alert.filters.category,
        manufacture: alert.filters.manufacturer,
        productBrand: alert.filters.productBrand.includes("All") ? fetchedAlertBrands : alert.filters.productBrand,
        product: alert.filters.product,
        pricing: alert.pricing,
        promotion: alert.promotion,
        availability: alert.availability,
        listing: alert.listing,
        message: alert.message,
        email: true,
        isAllowEmpty: false,
        // sms: alert.sms,
        // whatsApp: alert.whatsApp,
        schedule: {
          type: alert.schedule.type,
          days: alert.schedule.days,
          time: alert.schedule.time,
          timezone: tz.guess(),
        },
        share: {
          emails: emails && emails[0].length ? emails : [],
        },
      });
    }
  }, [alert]);

  const setTextParams = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setFiltersParams = (name, selectItems) => {
    const params = {
      sourceType: name === "sourceType" && selectItems.includes("All") ? ["All"] : queryParams.sourceType.join("|"),
      manufacture: name === "manufacture" && selectItems.includes("All") ? ["All"] : queryParams.manufacture.join("|"),
      productBrand:
        name === "productBrand" && selectItems.includes("All") ? ["All"] : queryParams.productBrand.join("|"),
      category: name === "category" && selectItems.includes("All") ? ["All"] : queryParams.category.join("|"),
      product: name === "product" && selectItems.includes("All") ? ["All"] : queryParams.product.join("|"),
      save: false,
    };

    const sendParams = { ...params, ...{ [name]: selectItems.join("|") } };

    const parsingData = (data) =>
      data
        .split("|")
        .map((item) => Number(item))
        .filter((item) => Number(item));

    const filterSections = {
      sourceType: parsingData(sendParams.sourceType),
      productBrand: parsingData(sendParams.productBrand),
      category: parsingData(sendParams.category),
      product: parsingData(sendParams.product),
      manufacture: parsingData(sendParams.manufacture),
    };

    dispatch(fetchAlertFilters(sendParams)).then((res) => {
      Object.keys(filterSections).forEach((name) => {
        const selectItems = filterSections[name].filter((item) => res.data.filters[name].find(({ id }) => id === item));

        setQueryParams((prevState) => ({
          ...prevState,
          [name]: selectItems,
        }));
      });
    });
  };

  const setMetricsParamsSlider = (name, subName, values) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [subName]: values },
    }));
  };

  const setMetricsParamsCheckbox = (name, subName, nominal, value) => {
    if (nominal) {
      setQueryParams((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], [subName]: { ...prevState[name][subName], [nominal]: value } },
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], [subName]: value },
      }));
    }
  };

  const setIsCheckMetric = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], ["selected"]: value },
    }));
  };

  const toggleMetricsParams = (name, values) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: values,
    }));
  };

  const setDeliveryParams = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setScheduleType = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ["schedule"]: { ...prevState["schedule"], ["type"]: value },
    }));
  };

  const setScheduleDays = (value) => {
    if (queryParams.schedule.days.includes(value)) {
      setQueryParams((prevState) => ({
        ...prevState,
        ["schedule"]: {
          ...prevState["schedule"],
          ["days"]: prevState["schedule"]["days"].filter((item) => item !== value),
        },
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        ["schedule"]: { ...prevState["schedule"], ["days"]: [...prevState["schedule"]["days"], value] },
      }));
    }
  };

  const setScheduleTime = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ["schedule"]: { ...prevState["schedule"], ["time"]: value },
    }));
  };

  const handleEditAlert = () => {
    if (queryParams.name.length) {
      const emailsValid = checkEmail(emails, userEmail);
      if (emailsValid) {
        const updateQueryParams = {
          ...queryParams,
          share: {
            ...queryParams.share,
            emails: emails.length ? emails : [],
          },
        };
        dispatch(editAlert(updateQueryParams, params.id)).then(() => history.push("/alerts"));
      }
    } else {
      setIsEmptyName(true);
    }
  };

  const setIsAllowEmpty = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeInput = (value, indexVal) => {
    const values = emails.map((item, index) => {
      if (index === indexVal) {
        return (item = value);
      }
      return item;
    });
    setEmails(values);
  };

  const handleAddInput = () => {
    setEmails((prevState) => [...prevState, ""]);
  };

  const handleDeleteInput = (indexDel) => {
    const inputs = emails.filter((item, index) => index !== indexDel);
    setEmails(inputs);
  };

  return (
    <>
      <div className="main-title-page">Edit Nudge</div>
      <div className="main-tabs-box">
        {historicBrands.length > 0 && (
          <AlertMessage
            direction="vertical"
            message="Tracking Historic Brands"
            description={`It looks like this alert is tracking ${historicBrands.length} brands, which may no longer be selling within your selected filters. You can re-select your filter options at any time to make sure you stay up to date with the most recent developments.`}
            type="warning"
            showIcon
            closable
            padding="10px"
          />
        )}
        <div className="main-box">
          {Object.entries(filters).length && Object.entries(queryParams).length ? (
            <div className="wrapper-box-relative">
              <AlertCruds
                queryParams={queryParams}
                setTextParams={setTextParams}
                setFiltersParams={setFiltersParams}
                setMetricsParamsSlider={setMetricsParamsSlider}
                setMetricsParamsCheckbox={setMetricsParamsCheckbox}
                setIsCheckMetric={setIsCheckMetric}
                toggleMetricsParams={toggleMetricsParams}
                setDeliveryParams={setDeliveryParams}
                setScheduleType={setScheduleType}
                setScheduleDays={setScheduleDays}
                setScheduleTime={setScheduleTime}
                setIsAllowEmpty={setIsAllowEmpty}
                retailers={retailers}
                filters={{
                  sourceType: filters.filters.sourceType,
                  category: filters.filters.category,
                  manufacture: filters.filters.manufacture,
                  productBrand: filters.filters.productBrand,
                  product: filters.filters.product,
                }}
                errorField={{ isEmptyName }}
                disabled={false}
                emails={emails}
                handleAddInput={handleAddInput}
                handleChangeInput={handleChangeInput}
                handleDeleteInput={handleDeleteInput}
              />
              <div className="alert-btn-wrapper">
                <div className="nudge-btn" onClick={handleEditAlert}>
                  Edit a Nudge
                </div>
              </div>
            </div>
          ) : null}

          {Object.entries(filters).length === 0 ||
          countryStatus === STATE_STATUSES.PENDING ||
          status === STATE_STATUSES.PENDING ? (
            <LoaderBox isOpacity={true} />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default withRouter(EditAlert);
