import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";

//Components
import { Styles } from "./styles";
import PopupMediaSummary from "components/Popups/PopupMediaSummary";
import MediaSummaryRetailers from "components/CalendarItems/ MediaCalendar/MediaSummaryRetailers";
import MediaSummaryCalendar from "./components/MediaSummaryCalendar";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import PopupMultiContent from "components/Popups/PopupMultiContent";
import useFilters from "../../../../../hooks/useFilters";

//Actions
import {
  deleteMediaSummaryBanner,
  fetchMediaSummaryBanner,
  fetchMediaSummaryBottom,
} from "store/media/mediaSummaryBottom/actions";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import { formatDateToTimeStamp } from "utils/calendarPromotionsHelper";
import { firstCharToUpperCase } from "utils/generalUtils";

const MediaSummaryBottom = ({
  setSelectValue,
  isRedraw,
  setIsRedraw,
  clickedArray,
  setClickedArray,
  retailer,
  arrayOfDesiredBrand,
  setArrayOfDesiredBrand,
}) => {
  //Selectors
  const { status, filter, mediaSummaryBottom, controlData, mediaBanner } = useSelector(
    (state) => state.mediaSummaryBottom
  );
  const { filter: mediaSummaryTopFilter } = useSelector((state) => state.mediaSummaryTop);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { sourceType } = useSelector((state) => state.filters.filters);

  //States
  const [queryParams, setQueryParams] = useState({});
  const [emptyData, setEmptyData] = useState(false);
  const [maxMinYValue, setMaxMinYValue] = useState({ min: 0, max: 100 });
  const [maxMinSliderValue, setMaxMinSliderValue] = useState({
    min: 0,
    max: 100,
  });
  const [sliderValue, onSliderChange] = useState([0, 100]);
  const [search, setSearch] = useState("");
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [sortId, setSortId] = useState("title");
  const [indexOfPopup, setIndexOfPopup] = useState(0);

  //Const
  const dispatch = useDispatch();
  const isBrand = mediaSummaryTopFilter[0].value === "byBrand";
  const desiredArr = isBrand ? arrayOfDesiredBrand : retailer;

  const filteredMediaSummaryBottom = useMemo(() => {
    if (isBrand || !mediaSummaryBottom?.result || mediaSummaryBottom.result.length === 0 || !Array.isArray(sourceType))
      return mediaSummaryBottom;

    const retailerIds = new Set(sourceType.map((s) => s.id));
    return {
      ...mediaSummaryBottom,
      result: mediaSummaryBottom.result.filter((r) => retailerIds.has(r.id)),
    };
  }, [mediaSummaryBottom, sourceType, isBrand]);

  const groupBy = {
    byRetailer: "retailer",
    byBrand: "brand",
  };

  //set queryParams
  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      search,
      groupBy: groupBy[mediaSummaryTopFilter[0].value],
      showAll: filter[0].value,
      sortDirection: "asc",
    }));
    setClickedArray([]);
    setArrayOfDesiredBrand([]);
  }, [lastFilter, search, mediaSummaryTopFilter[0].value, filter[0].value, statusFilters]);

  // dispatch based on query
  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchMediaSummaryBottom(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);
  //if data empty show no data banner
  useEffect(() => {
    if (!filteredMediaSummaryBottom.result.length) {
      setEmptyData(true);
      setIsRedraw(true);
    } else {
      setEmptyData(false);
    }
  }, [filteredMediaSummaryBottom]);
  //get banner popup
  const fetchBanner = async (idsArr) => {
    const promises = idsArr.map((id) => {
      return dispatch(fetchMediaSummaryBanner(id));
    });

    Promise.all(promises)
      .then((el) => {
        setIsShowPopup(true);
      })
      .catch((e) => {
        setIsShowPopup(false);
      });
  };
  //on click + btn
  const onOpen = (id, children, type, color, element, brand) => {
    const retailer = { id: element.id, label: element.label };

    setIsRedraw(false);
    const mapedChildren = children.map(({ label, id: childId, banners, days, color: childColor }) => {
      return {
        id: childId,
        name: label,
        days,
        banner: banners,
        color: childColor ? childColor : color,
      };
    });
    let arr = [];
    if (isIncluded(id)) {
      const matchedEl = clickedArray.find((item) => item.id === id);

      const filteredElements = matchedEl.children.map((el) => clickedArray.filter((item) => item.id === el.id)).flat();

      if (filteredElements.length) {
        const clickedArrIncludesChildrens = filteredElements
          .map(({ children }) => clickedArray.filter((el) => children.some(({ id }) => id === el.id)))
          .flat();
        const resultArr = clickedArray.filter(
          (el) => !filteredElements.some((item) => item.id === el.id) && el.id !== id
        );
        if (clickedArrIncludesChildrens.length) {
          arr = resultArr.filter(
            (el) => !clickedArrIncludesChildrens.some((item) => item.id === el.id) && el.id !== id
          );
        } else {
          arr = resultArr;
        }
      } else {
        arr = clickedArray.filter((item) => item.id !== id);
      }
    } else {
      arr = [...clickedArray, { id, children: mapedChildren, type, retailer, brand }];
    }

    setClickedArray(arr);
  };

  const isIncluded = (id) => {
    if (clickedArray.some((el) => el.id === id)) {
      return true;
    }
  };

  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    setSortId(id);

    const sort = !sortDirection ? "asc" : "desc";
    setQueryParams((prevState) => ({
      ...prevState,
      sortDirection: sort,
    }));
  };
  //If top filter byRetailer/byBrand changed reset slider
  useEffect(() => {
    const { max, min } = maxMinSliderValue;
    onSliderChange([min, max]);
    setMaxMinYValue({ min, max });
  }, [mediaSummaryTopFilter]);

  const sliderValueHandler = (values) => {
    setIsRedraw(false);
    setTimeout(
      () =>
        setMaxMinYValue({
          max: values[1],
          min: values[0],
        }),
      500
    );
  };
  //Set min max values when loading is done
  useEffect(() => {
    if (status === STATE_STATUSES.READY && filteredMediaSummaryBottom.days.length) {
      const periods = filteredMediaSummaryBottom.days;

      if (periods.length) {
        const min = formatDateToTimeStamp(filteredMediaSummaryBottom.days[0]);
        const max = formatDateToTimeStamp(filteredMediaSummaryBottom.days[periods.length - 1]);
        setMaxMinYValue({ min, max });
        setMaxMinSliderValue({ min, max });
        onSliderChange([min, max]);
      }
    }
  }, [status]);

  const renderRetailers = () => {
    return (
      <MediaSummaryRetailers
        data={filteredMediaSummaryBottom.result}
        isBrand={isBrand}
        clickedArray={clickedArray}
        onOpen={onOpen}
        handleSearch={setSearch}
        search={search}
        handleSort={handleSort}
        sortDirection={sortDirection}
        sortId={sortId}
        filter={filter}
        desiredArr={desiredArr}
        isEmpty={emptyData}
        setEmptyData={setEmptyData}
      />
    );
  };

  const renderChart = () => {
    return (
      <MediaSummaryCalendar
        data={filteredMediaSummaryBottom}
        controlData={controlData}
        clickedArray={clickedArray}
        setClickedArray={setClickedArray}
        currentChart={filter}
        maxMinYValue={maxMinYValue}
        maxMinSliderValue={maxMinSliderValue}
        sliderValue={sliderValue}
        onSliderChange={onSliderChange}
        setIsRedraw={setIsRedraw}
        desiredArr={desiredArr}
        filter={filter}
        isEmpty={emptyData}
        sliderValueHandler={sliderValueHandler}
        fetchBanner={fetchBanner}
      />
    );
  };

  const nextPopup = (i) => {
    setIndexOfPopup(i + 1);
  };

  const prevPopup = (i) => {
    setIndexOfPopup(i - 1);
  };

  return (
    <Styles>
      <>
        {filteredMediaSummaryBottom.success ? (
          <div className="main-box-header">
            <div className="calendar-title-desc">
              <span>
                You are currently viewing{" "}
                <span className="bold">
                  {isBrand && !arrayOfDesiredBrand.length
                    ? "All Brands"
                    : isBrand && arrayOfDesiredBrand.length === 1
                    ? arrayOfDesiredBrand[0]
                    : isBrand && arrayOfDesiredBrand.length > 1
                    ? `${arrayOfDesiredBrand.length} Brands`
                    : !isBrand && !retailer.length
                    ? "All Retailers"
                    : !isBrand && retailer.length
                    ? firstCharToUpperCase(retailer[0])
                    : null}
                </span>{" "}
                location.
              </span>
            </div>

            <div className="main-box-filters">
              <div className="wrapper-dscount">
                <Checkbox
                  checked={filter[0].value}
                  onChange={() => setSelectValue({ showAll: !filter[0].value })}
                  disabled={status !== STATE_STATUSES.READY}
                >
                  Show All Places
                </Checkbox>
              </div>
              <SelectBox
                filter={filter[1].value}
                data={filter[1]}
                setSelectValue={setSelectValue}
                disabled={status !== STATE_STATUSES.READY}
              />
            </div>
          </div>
        ) : null}
        {filteredMediaSummaryBottom.success ? (
          <div className={"calendar-wrapper"}>
            {renderRetailers()}
            {renderChart()}
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING || !isRedraw ? <LoaderBox /> : null}
        {isShowPopup && status === STATE_STATUSES.READY ? (
          <PopupMultiContent
            moreData={mediaBanner.length > 1}
            isLastEl={mediaBanner.length - 1 === indexOfPopup}
            isFirstEl={indexOfPopup === 0}
            nextPopup={() => nextPopup(indexOfPopup)}
            prevPopup={() => prevPopup(indexOfPopup)}
          >
            <PopupMediaSummary
              banner={mediaBanner[indexOfPopup]}
              closePopup={() => {
                setIndexOfPopup(0);
                dispatch(deleteMediaSummaryBanner());
                setIsShowPopup(false);
              }}
            />
          </PopupMultiContent>
        ) : null}
      </>
    </Styles>
  );
};

export default MediaSummaryBottom;
