import React, { useState } from "react";
import SelectBox from "components/Selects/SelectBox";
import CurrentlyTrendChart from "./CurrentlyTrendChart";

const selects = [
  {
    name: "chartType",
    default: "byTotal",
    options: [
      { lable: "Total", value: "byTotal" },
      { lable: "By Retailer", value: "byRetailer" },
    ],
  },
];

const TrendAllDay = ({ data, activeTab }) => {
  const [chartType, setChartType] = useState("byTotal");

  const handleSetShareSize = (values) => {
    const value = Object.keys(values);
    setChartType(values[value]);
  };

  return (
    <div className="viewing-trend-box">
      <div className="trend-header">
        <div className="title">
          You are currently viewing trend for <span>{activeTab}</span>.
        </div>
        {selects.map((item, index) => (
          <SelectBox key={index} data={item} setSelectValue={handleSetShareSize} />
        ))}
      </div>
      <CurrentlyTrendChart data={data} type={chartType} />
    </div>
  );
};

export default TrendAllDay;
