import React, { useState, useEffect, useRef } from "react";
import useColorTheme from "../../../../hooks/useColorTheme";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Modal, Checkbox } from "antd";
import moment from "moment";
import { sortData } from "utils/sortRankingTable";
import { getTimePeriodSingleDay } from "utils/getTimePeriodSingleDay";
import { STATE_STATUSES } from "utils/statuses";
import { timePeriod } from "utils/timePeriod";

import CompareProductsChart from "./CompareProductsChart";
import LocationAnalysisProductPopUpRankingChart from "./LocationAnalysisProductPopUpRankingChart";
import LoaderBox from "components/LoaderBox";

import sort from "assets/images/sort.svg";
import sortIconUp from "assets/images/sort_up.svg";
import sortIconDown from "assets/images/sort_down.svg";

import printscreen from "assets/images/printscreen.svg";
import show_chart from "assets/images/show_chart.svg";
import show_chart_white from "assets/images/show_chart_white.svg";
import back from "assets/images/back.svg";
import featured from "assets/images/featured.svg";
import options from "assets/images/options.svg";

const ProductPopup = (props) => {
  const dispatch = useDispatch();
  const { primaryColor, primaryColorRgb, getColorPromotions } = useColorTheme();

  const {
    storeProductPopup,
    actionShowPopup,
    showPopup,
    type,
    searchTerm,
    sourceType,
    actionSingleProduct,
    storeSingleProduct,
    actionRemoveProduct,
    actionProductCompare,
    storeProductCompare,
    actionProductPopup,
    manufacturer,
    status,
  } = props;

  const wrapperRef = useRef(null);

  const [sortParam, setSortParam] = useState("rank");
  const [directionSort, setDirectionSort] = useState(true);

  const [showScreenshot, setShowScreenshot] = useState(false);
  const [isCompare, setIsCompare] = useState(false);

  const [currentDate, setCurrentDate] = useState(null);
  const [datePeriod, setDatePeriod] = useState(14);
  const [isOpenDatePeriod, setIsOpenDatePeriod] = useState(false);

  const [isCompareChart, setIsCompareChart] = useState(false);

  const [selectItems, setSelectItems] = useState([]);
  const [compareItems, setCompareItems] = useState([]);

  const [isFeaturedProducts, setIsFeaturedProducts] = useState(true);
  const { formatCurrencyNumber } = useCountry();

  useEffect(() => {
    if (Object.entries(props[storeProductPopup]).length !== 0) {
      setCurrentDate(props[storeProductPopup].date);
    }
  }, [props[storeProductPopup]]);

  const handleSort = (name) => {
    if (sortParam !== name) {
      setDirectionSort(true);
    } else {
      setDirectionSort(!directionSort);
    }
    setSortParam(name);
  };

  const handleSetShowScreenshot = () => {
    if (props[storeProductPopup].products[0].screenshot.length) {
      setShowScreenshot(!showScreenshot);
    }
  };

  const handleback = () => {
    setIsCompareChart(false);
    setCompareItems([]);
    setIsCompare(false);
  };

  const handleCompareProducts = () => {
    if (compareItems.length >= 2) {
      actionProductCompare({
        type,
        raw: true,
        searchTerm,
        sourceType,
        timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
        product: compareItems.join("|"),
        page: "1",
        limit: "10",
      }).then(() => setIsCompareChart(true));
    }
  };

  const handleSetDatePeriod = (period) => {
    setDatePeriod(period);
    setIsOpenDatePeriod(false);

    actionSingleProduct(
      {
        type,
        raw: true,
        searchTerm,
        sourceType,
        timePeriod: getTimePeriodSingleDay(currentDate, period),
        product: selectItems.join("|"),
        page: "1",
        limit: "10",
      },
      false
    );
  };

  const handleCheckbox = (productId) => {
    if (compareItems.includes(productId)) {
      setCompareItems((prevState) => prevState.filter((item) => item !== productId));
    } else {
      setCompareItems((prevState) => [...prevState, productId]);
    }
  };

  const handleChart = ({ coreProductId, uid }) => {
    if (selectItems.includes(coreProductId)) {
      setSelectItems((prevState) => prevState.filter((item) => item !== coreProductId));

      dispatch({ type: actionRemoveProduct, payload: uid });
    } else {
      setSelectItems((prevState) => [...prevState, coreProductId]);

      actionSingleProduct(
        {
          type,
          raw: true,
          searchTerm,
          sourceType,
          timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
          product: coreProductId.toString(),
          page: "1",
          limit: "10",
        },
        true
      );
    }
  };

  const handleSetIsFeaturedProducts = () => {
    actionProductPopup(props[storeProductPopup].productId, {
      type,
      searchTerm,
      sourceType,
      manufacturer,
      timePeriod: `custom|${props[storeProductPopup].date}|${props[storeProductPopup].date}`,
      featured: !isFeaturedProducts,
    });

    setIsFeaturedProducts(!isFeaturedProducts);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpenDatePeriod) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenDatePeriod(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpenDatePeriod]);
  }
  useOutsideAlerter(wrapperRef);

  const handleHiddePopup = () => dispatch({ type: actionShowPopup, payload: false });

  return (
    <Modal className="ranking-page-modal bottom" visible={props[showPopup]} onCancel={handleHiddePopup}>
      <div className="wrapper-title-popup">
        <div className="product-title-popup">
          {showScreenshot ? (
            <div className="title">{props[storeProductPopup].screenshotTitle}</div>
          ) : (
            <div className="title">{props[storeProductPopup].title}</div>
          )}

          {showScreenshot ? (
            <div className="subtitle"></div>
          ) : isCompareChart ? (
            <div className="subtitle">
              {moment(currentDate).subtract(datePeriod, "d").format("DD MMM YY")} -{" "}
              {moment(currentDate).format("DD MMM YY")}
            </div>
          ) : (
            <div className="subtitle">{moment(currentDate).format("DD MMM YYYY")}</div>
          )}
        </div>
        <div className="popup-setting-box">
          {!isCompare && selectItems.length ? (
            <div className="trend-perion-wrapper" ref={wrapperRef}>
              <div className="trend-perion" onClick={() => setIsOpenDatePeriod(!isOpenDatePeriod)}>
                <span className="label">Trend Period</span>
              </div>
              {isOpenDatePeriod ? (
                <div className="dropdown">
                  <div className="description">Relative</div>
                  <div className="period-box">
                    {timePeriod.map((item, index) => (
                      <div
                        key={index}
                        className="item-period"
                        style={{
                          border: `1px solid ${datePeriod === item.value ? primaryColor : "#dfdfea"}`,
                          fontFamily: datePeriod === item.value ? "Gilroy-ExtraBold" : "Gilroy-Medium",
                          color: datePeriod === item.value ? primaryColor : "#000",
                        }}
                        onClick={() => handleSetDatePeriod(item.value)}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          {!isCompareChart ? (
            <>
              <div className="onlyProducts right-wrapper" style={{ marginRight: "15px" }}>
                <Checkbox checked={isFeaturedProducts} onChange={handleSetIsFeaturedProducts}>
                  Sponsored Products
                </Checkbox>
              </div>
              <div
                className="compare-data-popup"
                style={{
                  background: isCompare ? primaryColor : "#eef2f7",
                  color: isCompare ? "#fff" : "#000",
                }}
                onClick={() => setIsCompare(!isCompare)}
              >
                Compare Products
              </div>
              <div className="icon-btn" onClick={handleSetShowScreenshot}>
                {showScreenshot ? (
                  <img src={back} alt="back" />
                ) : (
                  <img
                    style={{
                      cursor: props[storeProductPopup].products[0].screenshot.length ? "pointer" : "no-drop",
                    }}
                    src={printscreen}
                    alt="printscreen"
                  />
                )}
              </div>
            </>
          ) : null}

          {isCompareChart ? (
            <div className="icon-btn" onClick={handleback}>
              <img src={back} alt="back" />
            </div>
          ) : null}
        </div>
      </div>
      {showScreenshot ? (
        <div className="screenshot-wrapper">
          <img src={props[storeProductPopup].products[0].screenshot} alt="screenshot" />
        </div>
      ) : isCompareChart ? (
        <CompareProductsChart data={props[storeProductCompare]} />
      ) : (
        <>
          <div className="table-header">
            <div className="rank-sort" onClick={() => handleSort("rank")}>
              <div>Rank</div>
              <img src={sortParam === "rank" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
            </div>
            <div className="title-sort" onClick={() => handleSort("title")}>
              <div>Product Title (Retailer)</div>
              <img src={sortParam === "title" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
            </div>
            <div className="discription-sort">
              <div>Promotion Description</div>
            </div>
            <div className="price-sort" onClick={() => handleSort("shelfPrice")}>
              <div>Shelf Price</div>
              <img src={sortParam === "shelfPrice" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
            </div>
            <div style={{ width: "84px" }}></div>
          </div>
          <div style={{ position: "relative" }}>
            <div className="table-body">
              {sortData(props[storeProductPopup].products, sortParam, directionSort).map((item, index) => (
                <div key={index}>
                  <div
                    className={`table-item ${item.selectedProduct ? "selectedProduct" : ""}`}
                    style={{
                      borderBottom: selectItems.includes(item.coreProductId) ? "none" : "2px solid #e0e0e4",
                    }}
                  >
                    <div className="rank">
                      <span>{item.rank}</span>
                      {item.featured ? <img src={featured} alt="featured" /> : null}
                      {item.productOptions ? <img src={options} alt="options" /> : null}
                    </div>
                    <div className={`title-wrapper ${item.fromSelectedManufacture ? "selectedManufacture" : ""}`}>
                      <img src={item.image} alt="product" />
                      <div className="titles-box">
                        <div className="title">{item.title}</div>
                        <div className="subtitle">{item.detailTitle}</div>
                      </div>
                    </div>
                    <div className="description-wrapper">
                      <div
                        className="description-box"
                        style={{
                          background: item.promotions ? getColorPromotions(item.promotions.mechanic.name) : null,
                        }}
                      >
                        {item.promotions ? (
                          item.promotions.description
                        ) : (
                          <span className="no-promotion">No promotion</span>
                        )}
                      </div>
                    </div>
                    <div className="price">{formatCurrencyNumber(item.shelfPrice, NUMBER_TYPE.CURRENCY)}</div>
                    <div className="right-wrapper">
                      {isCompare ? (
                        <Checkbox
                          checked={compareItems.includes(item.coreProductId)}
                          onChange={() => handleCheckbox(item.coreProductId)}
                        ></Checkbox>
                      ) : (
                        <div
                          className="icon-btn"
                          style={{
                            background: selectItems.includes(item.coreProductId) ? primaryColor : "#eef2f7",
                          }}
                          onClick={() => handleChart(item)}
                        >
                          <img
                            style={{ cursor: "pointer" }}
                            src={selectItems.includes(item.coreProductId) ? show_chart_white : show_chart}
                            alt="back"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {props[storeSingleProduct].length
                    ? props[storeSingleProduct].map((chart, index) => {
                      if (chart.id == item.coreProductId && selectItems.includes(item.coreProductId)) {
                        return (
                          <div
                            key={index}
                            className="wrapper-bottom-chart"
                            style={{ borderBottom: "2px solid #e0e0e4" }}
                          >
                            <LocationAnalysisProductPopUpRankingChart
                              data={chart.ranks}
                              timePeriod={[0, chart.ranks.length]}
                              isMarker={false}
                              outside={false}
                            />
                          </div>
                        );
                      }
                    })
                    : null}
                </div>
              ))}
            </div>
            {props[status] === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
          </div>
          {isCompare ? (
            <div className="table-footer">
              <div className="compare-hint">
                <div className="title">Compare Products</div>
                <div className="hint">Select at least 2 products to compare.</div>
              </div>
              <div
                className="compare-btn"
                style={{
                  background: compareItems.length === 1 ? primaryColorRgb(0.5) : primaryColor,
                }}
                onClick={handleCompareProducts}
              >
                {compareItems.length > 0 ? (
                  <span>{`Compare ${compareItems.length} Product${compareItems.length >= 2 ? "s" : ""}`}</span>
                ) : (
                  "Select Products"
                )}
              </div>
              <div className="shadow-box"></div>
            </div>
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default connect(
  (state) => ({
    navigationLocationAnalysisProductsPopup: state.navigationLocationAnalysis.navigationLocationAnalysisProductsPopup,
    analisisSingleProduct: state.navigationLocationAnalysis.analisisSingleProduct,
    analisisSingleProductCompare: state.navigationLocationAnalysis.analisisSingleProductCompare,
    showProductLocationPopup: state.navigationLocationAnalysis.showProductPopup,
    statusLocation: state.navigationLocationAnalysis.popupStatus,
  }),
  null
)(ProductPopup);
