import { Popover } from "antd";
import React, { useEffect, useRef, useState } from "react";

import BrandLabelCard from "components/BrandLabelCard";
import { sortAlphabetically } from "utils/generalUtils";

const RowOfBrands = ({ names, arrayOfDesiredBrand, setArrayOfDesiredBrand, selectSingle, withId, labelsStep }) => {
  const moreBrandsRef = useRef(null);

  const [isMoreBtnClicked, setMoreBtnClick] = useState(false);

  const MORE = "more";
  const renderLabelsStep = labelsStep ? labelsStep : 16;
  const arr = sortAlphabetically(names, "name");

  const renderBrandNames = () => {
    return arr.slice(0, renderLabelsStep + 1).map(({ name, color, id }, index) => {
      if (index === renderLabelsStep && arr.length > renderLabelsStep) {
        return (
          <Popover
            content={renderMoreBrands}
            trigger="click"
            placement="bottomLeft"
            arrowPointAtCenter={false}
            align={{ offset: [0, 0] }}
            overlayClassName="more-popover"
            key={name}
          >
            <BrandLabelCard name={MORE} key={index} more onClick={() => setMoreBtnClick(!isMoreBtnClicked)} />
          </Popover>
        );
      } else {
        const setFunc = withId ? onLabelClickWithId : onLabelClick;
        const isSelected = withId
          ? arrayOfDesiredBrand.map(({ id }) => id).includes(id)
          : arrayOfDesiredBrand.includes(name);
        return (
          <BrandLabelCard
            name={name}
            key={index}
            onClick={() => setFunc(name, id)}
            isSelected={isSelected}
            color={color}
          />
        );
      }
    });
  };

  //render more brands modal
  const renderMoreBrands = () => {
    return (
      <div className="more-brands-modal">
        <div ref={moreBrandsRef}>
          {arr.slice(renderLabelsStep).map(({ name, color, id }, index) => {
            const setFunc = withId ? onLabelClickWithId : onLabelClick;
            const isSelected = withId
              ? arrayOfDesiredBrand.map(({ id }) => id).includes(id)
              : arrayOfDesiredBrand.includes(name);
            return (
              <BrandLabelCard
                name={name}
                key={index}
                onClick={() => setFunc(name, id)}
                isSelected={isSelected}
                color={color}
              />
            );
          })}
        </div>
      </div>
    );
  };

  // click outside of more brands modal
  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.innerText === "More") {
        return;
      }
      if (moreBrandsRef.current && !moreBrandsRef.current.contains(event.target)) {
        setMoreBtnClick(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [moreBrandsRef]);

  //put name of brand to array
  const onLabelClick = (key, id) => {
    let array;

    if (arrayOfDesiredBrand.includes(key)) {
      array = arrayOfDesiredBrand.filter((brand) => brand !== key);
      setArrayOfDesiredBrand(array);
    } else {
      array = [...arrayOfDesiredBrand, key];
      if (selectSingle) {
        setArrayOfDesiredBrand([key]);
      } else {
        setArrayOfDesiredBrand(array);
      }
    }
  };

  const onLabelClickWithId = (key, id) => {
    let array;

    const isIncluded = arrayOfDesiredBrand.map(({ id }) => id).includes(id);

    if (isIncluded) {
      array = arrayOfDesiredBrand.filter((el) => el.id !== id);
      setArrayOfDesiredBrand(array);
    } else {
      array = [...arrayOfDesiredBrand, { name: key, id }];
      if (selectSingle) {
        setArrayOfDesiredBrand([{ name: key, id }]);
      } else {
        setArrayOfDesiredBrand(array);
      }
    }
  };

  return <div>{renderBrandNames()}</div>;
};

export default RowOfBrands;
