import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import moment from "moment";
import { Popover } from "antd";

//Components
import RenderNoData from "components/RenderNoData";
import RowOfBrands from "components/RowOfBrands";
import useRetailers from "../../../../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";
import { changeNameRetailer } from "utils/changeNameRetailer";

HC_rounded(Highcharts);

const AvailabilitySummaryChart = (props) => {
  //Props
  const {
    data,
    currentChart,
    setHoverEl,
    hoverEl,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    isCompare,
    compareData,
    status,
    comparativeValue,
    currentDate,
  } = props;

  //Refs
  const inputRef = useRef(null);
  const moreBrandsRef = useRef(null);
  const ref = useRef(null);
  const { getRemoteLogo } = useRetailers();

  //Constants
  const statusIsReady = status === STATE_STATUSES.READY;

  const isBrand = currentChart[2].value === "brand" || currentChart[2].value === "manufacture";

  const isDiscountInPercents = currentChart[0].value === "availability";
  const MORE = "more";
  const renderLabelsStep = 16;
  const AVAILABLE = "available";
  const UNAVAILABLE = "unavailable";
  const availableColor = "#1ec68d";
  const availableColorOpacity = "rgba(30, 198, 141, 0.5)";
  const unAvailableColor = "#fe6a68";
  const unAvailableColorOpacity = "rgba(254, 106, 104, 0.5)";
  const suffix = isDiscountInPercents ? "%" : "";

  //States
  const [isMoreBtnClicked, setMoreBtnClick] = useState(false);
  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  const [isCompareDataIsEmpty, setIsCompareDataIsEmpty] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [clickedLabel, setClickedLabel] = useState("");
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    pricings[0]?.data.length * 25 > ref?.current?.offsetWidth
      ? setChartWidth(pricings[0]?.data.length * 25)
      : setChartWidth(ref?.current?.offsetWidth);
  }, [ref, pricings]);

  const getDesiredArray = (type, data) => {
    const key = isDiscountInPercents ? type + "Percent" : type + "Count";

    return data.map((el) => {
      const {
        productCount,
        productPercent,
        availableCount,
        availablePercent,
        unavailableCount,
        unavailablePercent,
        item,
      } = el;
      const { name } = item;
      const isIncluded = arrayOfDesiredBrand.includes(name);
      const isHovered = name === hoverEl;
      const isAvailable = type === AVAILABLE;

      const color = isAvailable ? availableColor : unAvailableColor;

      const opacityColor = isAvailable ? availableColorOpacity : unAvailableColorOpacity;

      const getColor = () => {
        if (isBrand) {
          if (isIncluded) {
            return color;
          } else {
            return color;
          }
        } else {
          if (hoverEl && hoverEl.length && !isHovered) {
            return opacityColor;
          } else if (isHovered && hoverEl.length) {
            return color;
          } else {
            return color;
          }
        }
      };

      return {
        name,
        y: el[key],
        color: getColor(),
        productCount,
        productPercent,
        availableCount,
        availablePercent,
        unavailableCount,
        unavailablePercent,
      };
    });
  };

  const formDataArr = (typeOne, arrOne, typeTwo, arrTwo, stack, nameForClass) => {
    return [
      {
        name: typeOne,
        data: arrOne,
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        stack,
        className: nameForClass,
      },
      {
        name: typeTwo,
        data: arrTwo,
        stack,
        className: nameForClass,
      },
    ];
  };

  const findMissingElementsInArr = (filteredArr, currentArr, filterKey) => {
    return filteredArr.filter(({ [filterKey]: id1 }) => !currentArr.some(({ [filterKey]: id2 }) => id2 === id1));
  };

  const findMissingElementsInArrWithElKey = (filteredArr, currentArr, filterKey, elKey) => {
    return filteredArr.filter(
      ({ [filterKey]: id1 }) => !currentArr.some(({ [filterKey]: id2 }) => id2[elKey] === id1[elKey])
    );
  };

  const getArrWithZeroValuesByAnotherArr = (arr) => {
    return arr.map((el) => {
      return {
        ...el,
        productCount: 0,
        productPercent: 0,
        availableCount: 0,
        availablePercent: 0,
        unavailableCount: 0,
        unavailablePercent: 0,
      };
    });
  };

  //Converting Data
  useEffect(() => {
    const dataFilteredByChart = data;
    const compareDataFilteredByChart = compareData;

    if (dataFilteredByChart.length) {
      setEmptyData(false);

      //Find Names
      const namesArr = dataFilteredByChart.map(({ item }) => {
        const { name, color } = item;
        return {
          name,
          color,
        };
      });
      //compareDataFilteredByChart may has different names
      const compareNames = compareDataFilteredByChart.map(({ item }) => {
        const { name, color } = item;
        return {
          name,
          color,
        };
      });
      //so need to find missing elements
      const missingNames = findMissingElementsInArr(compareNames, namesArr, "name");

      //And if find another names add them to existing names
      const arr = missingNames.length ? [...namesArr, ...missingNames] : namesArr;

      setNames(arr);

      const availableData = getDesiredArray(AVAILABLE, dataFilteredByChart);
      const unAvailableData = getDesiredArray(UNAVAILABLE, dataFilteredByChart);
      const stack = "current";

      const pricing = formDataArr(AVAILABLE, availableData, UNAVAILABLE, unAvailableData, stack, "");

      setPricings(pricing);

      if (compareDataFilteredByChart.length) {
        //For current Data
        //Current data may has different with compare data so we need to find
        //difference in Current data by compare data and reverse
        const missingElements = findMissingElementsInArrWithElKey(
          compareDataFilteredByChart,
          dataFilteredByChart,
          "item",
          "name"
        );

        const emptyMissingEl = getArrWithZeroValuesByAnotherArr(missingElements);
        //If has difference add missing elements to current data
        const arr = missingElements.length ? [...dataFilteredByChart, ...emptyMissingEl] : dataFilteredByChart;

        const availableData = getDesiredArray(AVAILABLE, arr);
        const unAvailableData = getDesiredArray(UNAVAILABLE, arr);
        const stack = "current";
        const pricing = formDataArr(AVAILABLE, availableData, UNAVAILABLE, unAvailableData, stack, "");

        //For compare Data
        //Find missing elements in reverse
        const compareMissingElements = findMissingElementsInArrWithElKey(
          dataFilteredByChart,
          compareDataFilteredByChart,
          "item",
          "name"
        );

        const compareEmptyMissingEl = getArrWithZeroValuesByAnotherArr(compareMissingElements);

        const compareArr = compareMissingElements.length
          ? [...compareDataFilteredByChart, ...compareEmptyMissingEl]
          : compareDataFilteredByChart;
        //Sort compare arr by current arr to have same structure with zero values
        const filteredCompareArr = compareArr.sort((a, b) => {
          return (
            arr.findIndex((el) => el.item.name === a.item.name) - arr.findIndex((el) => el.item.name === b.item.name)
          );
        });
        const compareAvailableData = getDesiredArray(AVAILABLE, filteredCompareArr);
        const compareUnavailableData = getDesiredArray(UNAVAILABLE, filteredCompareArr);
        const compareStack = "compare";
        const newData = formDataArr(
          AVAILABLE,
          compareAvailableData,
          UNAVAILABLE,
          compareUnavailableData,
          compareStack,
          "not-hightlighted"
        );

        setPricings([...newData, ...pricing]);
      }
    } else {
      setEmptyData(true);
    }
  }, [data, currentChart, isCompare, compareData, arrayOfDesiredBrand, hoverEl]);

  useEffect(() => {
    if (names.length > renderLabelsStep && isBrand) {
      if (!arrayOfDesiredBrand.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, isBrand, arrayOfDesiredBrand]);

  useEffect(() => {
    if (!compareData.length && isCompare && statusIsReady) {
      setIsCompareDataIsEmpty(true);
    } else {
      setIsCompareDataIsEmpty(false);
    }
  }, [compareData, isCompare, statusIsReady]);

  //Settings for chart
  const options = {
    title: {
      text: "",
    },

    chart: {
      animation: false,
      type: "column",
      width: chartWidth,
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },

    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        const compareData = this.points.length
          ? this.points.filter((item) => item?.series?.options.stack === "compare")
          : [];
        const initData = this.points.length
          ? this.points.filter((item) => item?.series?.options.stack === "current")
          : [];

        let initialData = this.points.length
          ? this.points.map((item) => {
              if (item?.series?.options?.stack === "current") {
                return `<div class="wrapper">
               ${
                 !isCompare
                   ? `<div class="box">
                  <div
                    class="color"
                    style="background: ${item?.point?.color};"
                  >
                </div>
                <div class="name">
                  ${item?.series?.name === AVAILABLE ? "Available" : "Unavailable"}
                </div>
              </div>`
                   : `<div class="box"></div>`
               }
              <div class="price">${
                item?.series?.name === AVAILABLE
                  ? item?.point?.options?.availablePercent
                  : item?.point?.options?.unavailablePercent
              }%</div>
            </div>`;
              }
            })
          : [];
        initialData = initialData.join("");

        let dataCompare = this.points.length
          ? this.points.map((item) => {
              if (item?.series?.options?.stack === "compare") {
                return `<div class="wrapper">
                ${
                  isCompare
                    ? `<div class="box">
                <div
                  class="color"
                  style="background: ${item?.point?.color};"
                ></div>
                <div class="name">
                  ${item?.series?.name === AVAILABLE ? "Available" : "Unavailable"}
                </div>
              </div>`
                    : `<div class="box"></div>`
                }
              <div class="price">${
                item?.series?.name === AVAILABLE
                  ? item?.point?.options?.availablePercent
                  : item?.point?.options?.unavailablePercent
              }%</div>
            </div>`;
              }
            })
          : [];
        dataCompare = dataCompare.join("");

        const compareDateArr = comparativeValue.split("|");
        const currentDateArr = currentDate.split("|");

        return `<div class="wrapper-category">
            <div class="title">${changeNameRetailer(initData[0].point?.name)}</div>
            <div class="wrapper-box">
              <div>
                ${
                  isCompare
                    ? `<div class="name" style="margin-top: 10px">${moment(currentDateArr[1]).add(1, 'd').format("DD MMM YYYY") + " to " + moment(currentDateArr[2]).format("DD MMM YYYY")}</div>`
                    : ""
                }
                <div style="margin-top: 10px">
                  <div class="wrapper">
                    <div class="box">
                      <div class="name" style="margin: 0">
                        No. of Products
                      </div>
                    </div>
                    <div class="price">${
                      !isCompare ? initData[0].point?.productCount : compareData[0]?.point?.productCount || 0
                    }</div>
                  </div>
                </div>
                <div style="margin-bottom: 10px">
                  <div class="wrapper">
                    <div class="box">
                      <div class="name" style="margin: 0">
                        % Products
                      </div>
                    </div>
                    <div class="price">
                    ${!isCompare ? initData[0].point?.productPercent : compareData[0]?.point?.productPercent}%
                    </div>
                  </div>
                </div>
                <div>${!isCompare ? initialData : dataCompare}</div>
              </div>
              ${
                isCompare
                  ? ` <div class='line-separete' style="margin-top: 30px"></div>
                  <div>
                    <div class="name" style="margin-top: 10px">${moment(compareDateArr[1]).format("DD MMM YYYY") + " to " + moment(compareDateArr[2]).subtract(1, 'd').format("DD MMM YYYY")}</div>
                   
                    <div style="margin-top: 10px">
                   
                  <div class="wrapper">
                 
                    <div class="box">
                      <div class="name" style="margin: 0">
                       
                      </div>
                    </div>
                    <div class="price">${initData[0].point?.productCount}</div>
                  </div>
                </div>
                <div style="margin-bottom: 10px">
                  <div class="wrapper">
                    <div class="box">
                      <div class="name" style="margin: 0">
                       
                      </div>
                    </div>
                    <div class="price">
                    ${isCompare ? initData[0].point?.productPercent : compareData[0]?.point?.productPercent}%
                    </div>
                  </div>
                </div>
                <div>${isCompare ? initialData : dataCompare}</div>
              </div>
                    </div>`
                  : ""
              }
            </div>
          </div>`;
      },
    },
    xAxis: {
      categories: names.map(({ name }) => name),
      labels: {
        useHTML: !isBrand && statusIsReady,
        formatter: function () {
          return `<img style="width: 25px;" src=${getRemoteLogo(this.value)}></img>`;
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        format: "{value}" + `${suffix}`,
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        pointWidth: 24,
        groupPadding: 0.2,
        states: {},
        point: {
          events: {
            click: function () {
              setClickedLabel(this.category);
            },
            mouseOut: function () {
              setElement("");
            },
            mouseOver: function () {
              setElement(this.category);
            },
          },
        },
      },
    },
    series: pricings,
  };

  useEffect(() => {
    if (clickedLabel) {
      onLabelClick(clickedLabel);
    }
  }, [clickedLabel]);

  //set hover element
  const setElement = (el) => {
    setHoverEl(el);
  };
  //if array of brands has at least 1 item remove div with opacity
  useEffect(() => {
    if (arrayOfDesiredBrand.length) {
      setIsTooMoreData(false);
    }
  }, [arrayOfDesiredBrand]);
  //put name of brand to array
  const onLabelClick = (key) => {
    let array;

    if (arrayOfDesiredBrand.includes(key)) {
      array = arrayOfDesiredBrand.filter((brand) => brand !== key);
      setArrayOfDesiredBrand(array);
    } else {
      array = [...arrayOfDesiredBrand, key];
      setArrayOfDesiredBrand(array);
    }
  };

  const renderChartPlug = () => {
    if (!isCompareDataIsEmpty && isTooMoreData && !emptyData) {
      return (
        <div className={"too-more-data"} style={{ top: 30 }}>
          <p>Looks like we have a lot of data.</p>
          <p>Select the buttons below to see the graph.</p>
        </div>
      );
    }
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `100%` }} ref={ref}>
      {renderChartPlug()}
      <div className="chart-title-desc">Average availability over selected time.</div>

      {emptyData || isCompareDataIsEmpty ? (
        <RenderNoData />
      ) : (
        <HighchartsReact
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: "enableOverflow" }}
        />
      )}

      <div className="brand-labesl-row">
        {isBrand && statusIsReady && !isCompareDataIsEmpty ? (
          <RowOfBrands
            names={names}
            arrayOfDesiredBrand={arrayOfDesiredBrand}
            setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AvailabilitySummaryChart;
