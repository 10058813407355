import React, { useState, useEffect, useMemo } from "react";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";
import useFilters from "hooks/useFilters";
import { getTimePeriodRanking } from "utils/getTimePeriodRanking";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { greaterColor, lessColor, equallyColor } from "utils/colorsCharts";
import useRetailers from "../../../../hooks/useRetailers";
import moment from "moment";

import cup from "assets/images/cup.svg";
import star from "assets/images/star.svg";
import { DELETE_ALERT } from "store/alerts/actions";

const LocationAnalysisRankingChart = (props) => {
  const {
    timePeriod,
    data,
    promotions,
    currentRetailer,
    searchTerm,
    sourceType,
    manufacturer,
    productId,
    actionType,
    actionProductPopup,
    outside,
  } = props;

  console.log("PopUp Graph, data:", data)

  const ORGANIC_LINE_CHART = "Organic Rank", FEATURED_LINE_CHART = "Featured Rank", AVERAGE_LINE_CHART = "Average Organic Rank";


  const { lastFilter } = useFilters();
  const filterDates = getTimePeriodRanking(lastFilter.date).split("|")
  const minFilterDate = filterDates?.length == 3 ? filterDates[1] : null;
  const maxFilterDate = filterDates?.length == 3 ? filterDates[2] : null;
  console.log("data:", data)

  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [topRankings, setTopRankings] = useState([]);
  const [stars, setStars] = useState([]);

  const [promotionsDate, setPromotionsDate] = useState([]);
  const { formatCurrencyNumber } = useCountry();
  const { getRetailerColorHex, getRetailerBackgroundColor } = useRetailers();

  const [normalRanks, setNormalRanks] = useState(JSON.parse(JSON.stringify(data)));
  const [featuredRanks, setFeaturedRanks] = useState([]);

  useMemo(() => {
    if (!!data && !!data.length) {
      let _normalRanks = data.filter((element) => element.isFeaturedOnThisDay == false)
      let _featuredRanks = []
      for (const rank of normalRanks) {
        if (rank.isFeaturedOnThisDay) {
          _featuredRanks.push(rank)
        }
      }

      console.log("During preparation, _normalRanks:", _normalRanks)
      console.log("During preparation, _featuredRanks:", _featuredRanks)

      let dateArray = []
      for (let dateCounter = moment(maxFilterDate); dateCounter.valueOf() >= moment(minFilterDate); dateCounter.subtract(1, 'day')) {
        let dateCounterStr = dateCounter.format("YYYY-MM-DD");
        dateArray.push(dateCounterStr)
        let _normal = _normalRanks.filter((rank) => rank.date == dateCounterStr)
        let _featured = _featuredRanks.filter((rank) => rank.date == dateCounterStr)
        // Only _normal has it, _featured doesn't
        if (_normal.length > 0 && _featured.length == 0) {
          _featuredRanks.push(JSON.parse(JSON.stringify(_normal[0])))
        }
        // Only _featured has it, _normal doesn't
        if (_normal.length == 0 && _featured.length > 0) {
          _normalRanks.push(JSON.parse(JSON.stringify(_featured[0])))
        }
        // if both have it, just let it flows
        // if both don't, just let it flows
        // if (_normal.length == 0 && _featured.length == 0) {
        //   _normalRanks.push({ topRanking: false, date: dateCounterStr, isFeaturedOnThisDay: false, max: false, min: false, star: false, value: NaN })
        //   _featuredRanks.push({ topRanking: false, date: dateCounterStr, isFeaturedOnThisDay: false, max: false, min: false, star: false, topRanking: false, value: NaN })
        // }
      }

      console.log("After preparation, _normalRanks:", _normalRanks)
      console.log("After preparation, _featuredRanks:", _featuredRanks)

      setNormalRanks(_normalRanks.sort((a, b) => a.date > b.date ? 1 : -1))
      setFeaturedRanks(_featuredRanks.sort((a, b) => a.date > b.date ? 1 : -1))
    }
  }, [data])

  useEffect(() => {
    if (promotions?.length) {
      const dates = [];

      promotions.forEach((promotion) =>
        promotion.promotions[0].date.forEach((item) => dates.push({ date: moment(item).format("DD MMM YYYY"), id: promotion.id }))
      );

      setPromotionsDate(dates);
    }
  }, [promotions]);

  const getMainColor = (retailer) => getRetailerColorHex(retailer);

  const getBackground = (retailer) => getRetailerBackgroundColor(retailer);

  useEffect(() => {
    if (data.length) {
      let dataChart;
      let dataChart_featured;
      let start;
      let end;

      dataChart = normalRanks.map((item) => ({
        y: !!item.value ? Number(item.value) : NaN,
        max: item.max,
        min: item.min,
        marker: {
          enabled: item.max || item.min,
          lineColor: item.max ? "#1cc08a" : "#fe6a68",
          lineWidth: 2,
          fillColor: "#fff",
          radius: 4,
        },
        isFeaturedOnThisDay: item.isFeaturedOnThisDay,
        date: item.date,
        isAverageRank: false
      }));

      dataChart_featured = featuredRanks.map((item) => ({
        y: !!item.value ? Number(item.value) : NaN,
        max: item.max,
        min: item.min,
        marker: {
          enabled: item.max || item.min,
          lineColor: item.max ? "#1cc08a" : "#fe6a68",
          lineWidth: 2,
          fillColor: "#fff",
          radius: 4,
        },
        isFeaturedOnThisDay: item.isFeaturedOnThisDay,
        date: item.date,
        isAverageRank: false
      }));

      // Used to decide the color if the chart
      // If the start > end, its a decrease, so color = red
      // otherwise color = green | if same value, color = grey 
      start = dataChart[0]?.y;
      end = dataChart[dataChart.length - 1]?.y;

      const topRankings = data.map((item) => item.topRanking).slice(timePeriod[0], timePeriod[1] + 1);
      const stars = data.map((item) => item.star).slice(timePeriod[0], timePeriod[1] + 1);

      let pricings;

      pricings = [
        {
          name: ORGANIC_LINE_CHART,
          color: start === end ? "#a0a2a6" : start < end ? "#1ec68d" : "#fe6a68",
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: start === end ? equallyColor : start < end ? greaterColor : lessColor,
          },
          dataLabels: {
            enabled: false,
          },
          data: dataChart,
        },
      ];

      if (!!featuredRanks && featuredRanks?.length > 0) {
        pricings.push({
          name: FEATURED_LINE_CHART,
          color: 'rgba(192, 84, 255, .2)',
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: start === end ? equallyColor : start < end ? greaterColor : lessColor,
          },
          dataLabels: {
            enabled: false,
          },
          data: dataChart_featured,
        })
      }

      const points = dataChart.map((item) => { return { y: item.y, date: item.date } });
      const points_y = points.map((item) => item.y);

      const maxValue = Math.max(...points_y);
      const minValue = Math.min(...points_y);

      const averageValue = (maxValue + minValue) / 2;

      pricings.push({
        name: AVERAGE_LINE_CHART,
        dataLabels: {
          enabled: true,
          align: "left",
          style: {
            fontSize: "8px",
            color: "#5d6a7d",
            fontFamily: "Gilroy-Medium",
          },
        },
        color: "#dcdce7",
        dashStyle: "dash",
        fillColor: 0,
        data: points.map((item, index) => {
          return {
            x: index, y: averageValue, label: `Average Organic Rank: ${Math.abs(averageValue)}`,
            isAverageRank: true,
            date: item.date
          }
        }),
      });

      const datesMoment = data.map((item) => moment(item.date).format("DD MMM YYYY")).slice(timePeriod[0], timePeriod[1] + 1);

      setPricings(pricings);
      setTopRankings(topRankings);
      setStars(stars);
      setDates(datesMoment);
    }
  }, [data, timePeriod]);

  const getTimePeriod = (time) => {
    return `custom|${moment(time).format("YYYY-MM-DD")}|${moment(time).format("YYYY-MM-DD")}`;
  };

  const getProductPopup = (point) => {
    actionProductPopup(productId, {
      type: actionType,
      searchTerm,
      sourceType,
      manufacturer,
      featured: true,
      timePeriod: getTimePeriod(dates[point.x]),
    });
  };

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 100,
      animation: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.x == 0) return this.point.options.label;
          },
        },
        events: {
          click: function (e) {
            // Do not allow clicking in PopUp
            // getProductPopup(e.point);
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 2,
        threshold: null,
      },
    },
    xAxis: {
      visible: false,
      crosshair: {
        width: 2,
        color: "#384493",
      },
      type: "line",
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      visible: false,
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      hideDelay: 0,
      shadow: false,
      outside: outside,
      formatter: function () {
        console.log("this.points:", this.points)
        let _normal, _featured, _average;
        for (const point of this.points) {
          if (point.series.name == ORGANIC_LINE_CHART) { _normal = point }
          if (point.series.name == FEATURED_LINE_CHART) { _featured = point }
          if (point.series.name == AVERAGE_LINE_CHART) { _average = point }
        }

        const findPromotion = (index) => {
          const promoId = promotionsDate.find((item) => item.date === moment(dates[index]).format("DD MMM YYYY")).id;
          const currentPromo = promotions.find((item) => item.id === promoId);
          return currentPromo;
        };

        let format = '<div class="wrapper-category">'
        format += `<div class='total-data'>${moment(dates[this.points[0].x]).format("DD MMM YY")}</div>`

        for (const point of this.points) {

          console.log("point:", point)

          if (point?.series?.name == ORGANIC_LINE_CHART) {
            if (
              // This day has Featured Rank, and ranking value is different, Let it through
              (!!_featured && !!_featured.isFeaturedOnThisDay && _normal.y !== _featured.y) ||
              // Not Featured, Pass
              (!!_featured && !_featured.isFeaturedOnThisDay)
            ) {
              format += `<div class='title' style="font-size:13px;color:#5169f5">Organic:</div>`
              format += `<div class='wrapper'>`
              format += `<div class='box'>`
              format += `<div style="padding-right: 5px"><b>${Math.abs(point.y)}</b></div>`
              format += `${topRankings[point.point.index] ? `<img src=${cup} />` : ""}`
              format += `${stars[point.point.index] ? `<img src=${star} />` : ""}`
              format += `</div>`
              format += `</div>`
              format += `${point.point.min ? `<div class='lowest'>Lowest Position</div><br/>` : ""}`
              format += `${point.point.max ? `<div class='best'>Best Position</div><br/>` : ""}`
              promotions?.length && promotionsDate.find((item) => item.date === moment(dates[point.x]).format("DD MMM YYYY"))
                ?
                format += `
                  <div class='ranking-separate-line'></div>
                  <div class='title' style='margin-bottom: 5px;'>${findPromotion(point.x).promotions[0].name}</div>
                  <div class='wrapper' style='margin-bottom: 5px;'>
                    <div>Promoted Price</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].promotedPrice, NUMBER_TYPE.CURRENCY)}</div>
                  </div>
                  <div class='wrapper'>
                    <div>Discount</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].discount, NUMBER_TYPE.DECIMAL)}%</div>
                  </div>
                `
                :
                format += ""
            }
          } else if (point?.series?.name == FEATURED_LINE_CHART) {
            if (!!_featured && !!_featured.isFeaturedOnThisDay) {
              format += `<div class='title' style="font-size:13px;color:#a74dd1;">Featured:</div>`
              format += `<div class='wrapper'>`
              format += `<div class='box'>`
              format += `<div style="padding-right: 5px"><b>${Math.abs(point.y)}</b></div>`
              format += `${topRankings[point.point.index] ? `<img src=${cup} />` : ""}`
              format += `${stars[point.point.index] ? `<img src=${star} />` : ""}`
              format += `</div>`
              format += `</div>`
              format += `${point.point.min ? `<div class='lowest'>Lowest Position</div><br/>` : ""}`
              format += `${point.point.max ? `<div class='best'>Best Position</div><br/>` : ""}`
              promotions?.length && promotionsDate.find((item) => item.date === moment(dates[point.x]).format("DD MMM YYYY"))
                ?
                format += `
                  <div class='ranking-separate-line'></div>
                  <div class='title' style='margin-bottom: 5px;'>${findPromotion(point.x).promotions[0].name}</div>
                  <div class='wrapper' style='margin-bottom: 5px;'>
                    <div>Promoted Price</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].promotedPrice, NUMBER_TYPE.CURRENCY)}</div>
                  </div>
                  <div class='wrapper'>
                    <div>Discount</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].discount, NUMBER_TYPE.DECIMAL)}%</div>
                  </div>
                `
                :
                format += ""
            }
          } else if (point?.series?.name == AVERAGE_LINE_CHART) {
            format += `<div class='title' style="font-size:13px;color:#c4bd5c">Average:</div>`
            format += `<div class='wrapper'>`
            format += `<div class='box'>`
            format += `<div style="padding-right: 5px"><b>${Math.abs(point.y)}</b></div>`
            format += `</div>`
            format += `</div>`
          }
        }

        format += `</div>`

        console.log("format:", format)

        return format
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LocationAnalysisRankingChart;
