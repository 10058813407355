import React, { useState, useEffect, useMemo } from "react";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { greaterColor, lessColor, equallyColor } from "utils/colorsCharts";
import useRetailers from "../../../../../hooks/useRetailers";
import moment from "moment";

import cup from "assets/images/cup.svg";
import star from "assets/images/star.svg";

const СurrentlyItemChart = (props) => {
  const {
    type,
    timePeriod,
    data,
    promotions,
    currentRetailer,
    searchTerm,
    sourceType,
    manufacturer,
    productId,
    actionType,
    actionProductPopup,
    isPopup,
    outside,
  } = props;

  const ORGANIC = "ORGANIC", FEATURED = "FEATURED", AVERAGE = "AVERAGE";

  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [topRankings, setTopRankings] = useState([]);
  const [stars, setStars] = useState([]);

  const [promotionsDate, setPromotionsDate] = useState([]);
  const { formatCurrencyNumber } = useCountry();
  const { getRetailerColorHex, getRetailerBackgroundColor } = useRetailers();

  const [normalRanks, setNormalRanks] = useState(JSON.parse(JSON.stringify(data)));
  const [featuredRanks, setFeaturedRanks] = useState([]);

  useMemo(() => {
    if (!!data && !!data.length) {
      let _normalRanks = data.filter((element) => element?.isFeaturedOnThisDay == false)
      let _featuredRanks = []
      for (const rank of normalRanks) {
        if (rank?.isFeaturedOnThisDay) {
          _featuredRanks.push(rank)
        }
      }

      let dateArray = []
      let dateCounter = moment()
      for (let i = 0; i <= timePeriod[1]; i++) {
        dateCounter.subtract(1, 'day');
        let dateCounterStr = dateCounter.format("YYYY-MM-DD");
        dateArray.push(dateCounterStr)
        let _normal = _normalRanks.filter((rank) => rank.date == dateCounterStr)
        let _featured = _featuredRanks.filter((rank) => rank.date == dateCounterStr)
        // Only _normal has it, _featured doesn't
        if (_normal.length > 0 && _featured.length == 0) {
          _featuredRanks.push(JSON.parse(JSON.stringify(_normal[0])))
        }
        // Only _featured has it, _normal doesn't
        if (_normal.length == 0 && _featured.length > 0) {
          _normalRanks.push(JSON.parse(JSON.stringify(_featured[0])))
        }
        // if both have it, just let it flows
        // if both don't, just let it flows
      }

      setNormalRanks(_normalRanks.sort((a, b) => a.date > b.date ? 1 : -1))
      setFeaturedRanks(_featuredRanks.sort((a, b) => a.date > b.date ? 1 : -1))
    }
  }, [data])

  useEffect(() => {
    if (promotions?.length) {
      const dates = [];

      promotions.forEach((promotion) =>
        promotion.promotions[0].date.forEach((item) => dates.push({ date: moment(item).format("DD MMM YYYY"), id: promotion.id }))
      );

      setPromotionsDate(dates);
    }
  }, [promotions]);

  const getMainColor = (retailer) => getRetailerColorHex(retailer);

  const getBackground = (retailer) => getRetailerBackgroundColor(retailer);

  useEffect(() => {
    if (data.length) {
      let dataChart;
      let dataChart_featured;
      let start;
      let end;

      if (isPopup) {
        dataChart = normalRanks.map((item) => ({
          y: Number(item.value),
          max: item.max,
          min: item.min,
          marker: {
            enabled: item.max || item.min,
            lineColor: item.max ? "#1cc08a" : "#fe6a68",
            lineWidth: 2,
            fillColor: "#fff",
            radius: 4,
          },
          isFeaturedOnThisDay: item?.isFeaturedOnThisDay,
          date: item.date,
          isAverageRank: false
        }));
        dataChart_featured = featuredRanks.map((item) => ({
          y: Number(item.value),
          max: item.max,
          min: item.min,
          marker: {
            enabled: item.max || item.min,
            lineColor: item.max ? "#1cc08a" : "#fe6a68",
            lineWidth: 2,
            fillColor: "#fff",
            radius: 4,
          },
          isFeaturedOnThisDay: item?.isFeaturedOnThisDay,
          date: item.date,
          isAverageRank: false
        }));

        start = dataChart[0].y;
        end = dataChart[dataChart.length - 1].y;
      } else {
        dataChart = data.map((item) => Number(item.value)).slice(timePeriod[0], timePeriod[1] + 1);
        dataChart_featured = data.map((item) => Number(item.value)).slice(timePeriod[0], timePeriod[1] + 1);

        start = dataChart[0];
        end = dataChart[dataChart.length - 1];
      }

      const topRankings = data.map((item) => item.topRanking).slice(timePeriod[0], timePeriod[1] + 1);
      const stars = data.map((item) => item.star).slice(timePeriod[0], timePeriod[1] + 1);

      let pricings;

      if (type === "average") {
        pricings = [
          {
            name: "Average Share",
            color: getMainColor(currentRetailer),
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: getBackground(currentRetailer),
            },
            dataLabels: {
              enabled: false,
            },
            data: dataChart,
          },
        ];
      }

      if (type === "rank") {
        pricings = [
          {
            name: ORGANIC,
            color: start === end ? "#a0a2a6" : start < end ? "#1ec68d" : "#fe6a68",
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: start === end ? equallyColor : start < end ? greaterColor : lessColor,
            },
            dataLabels: {
              enabled: false,
            },
            data: dataChart,
          },
        ];

        if (!!featuredRanks && featuredRanks?.length > 0) {
          pricings.push({
            name: FEATURED,
            color: 'rgba(192, 84, 255, .2)',
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: start === end ? equallyColor : start < end ? greaterColor : lessColor,
            },
            dataLabels: {
              enabled: false,
            },
            data: dataChart_featured,
          })
        }

        if (isPopup) {
          const points = dataChart.map((item) => { return { y: item.y, date: item.date } });
          const points_y = points.map((item) => item.y);

          const maxValue = Math.max(...points_y);
          const minValue = Math.min(...points_y);

          const averageValue = (maxValue + minValue) / 2;

          const averageRank = {
            name: AVERAGE,
            dataLabels: {
              enabled: true,
              align: "left",
              style: {
                fontSize: "8px",
                color: "#5d6a7d",
                fontFamily: "Gilroy-Medium",
              },
            },
            color: "#dcdce7",
            dashStyle: "dash",
            fillColor: 0,
            data: points.map((item, index) => {
              return {
                x: index, y: averageValue, label: `Average Rank: ${Math.abs(averageValue)}`,
                isAverageRank: true,
                date: item.date
              }
            }),
          };

          pricings = [...pricings, averageRank];
        }
      }

      const datesMoment = data.map((item) => moment(item.date).format("DD MMM YYYY")).slice(timePeriod[0], timePeriod[1] + 1);

      setPricings(pricings);
      setTopRankings(topRankings);
      setStars(stars);
      setDates(datesMoment);
    }
  }, [data, timePeriod]);

  const getTimePeriod = (time) => {
    return `custom|${moment(time).format("YYYY-MM-DD")}|${moment(time).format("YYYY-MM-DD")}`;
  };

  const getProductPopup = (point) => {
    actionProductPopup(productId, {
      type: actionType,
      searchTerm,
      sourceType,
      manufacturer,
      featured: true,
      timePeriod: getTimePeriod(dates[point.x]),
    });
  };

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 100,
      animation: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.x == 0) return this.point.options.label;
          },
        },
        events: {
          click: function (e) {
            getProductPopup(e.point);
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 2,
        threshold: null,
      },
    },
    xAxis: {
      visible: false,
      crosshair: {
        width: 2,
        color: "#384493",
      },
      type: "line",
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      visible: false,
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      hideDelay: 0,
      shadow: false,
      outside: outside,
      formatter: function () {
        let _normal, _featured, _average;

        // Preparation
        if (this.points.length == 3) {
          _normal = this.points[0].series.userOptions.data.find((element) => element.date == moment(dates[this.points[0].x]).format("YYYY-MM-DD"))
          _featured = this.points[1].series.userOptions.data.find((element) => element.date == moment(dates[this.points[0].x]).format("YYYY-MM-DD"))
          _average = this.points[2].series.userOptions.data.find((element) => element.date == moment(dates[this.points[0].x]).format("YYYY-MM-DD"))
        } else if (this.points.length == 2) {
          _normal = this.points[0].series.userOptions.data.find((element) => element.date == moment(dates[this.points[0].x]).format("YYYY-MM-DD"))
          _average = this.points[1].series.userOptions.data.find((element) => element.date == moment(dates[this.points[0].x]).format("YYYY-MM-DD"))
        }

        const findPromotion = (index) => {
          const promoId = promotionsDate.find((item) => item.date === moment(dates[index]).format("DD MMM YYYY")).id;
          const currentPromo = promotions.find((item) => item.id === promoId);
          return currentPromo;
        };

        let format = '<div class="wrapper-category">'
        format += `<div class='total-data'>${moment(dates[this.points[0].x]).format("DD MMM YY")}</div>`
        for (const point of this.points) {
          if (point?.series?.name == ORGANIC) {
            if (
              // Featured, but different Rank, Pass
              (!!_featured?.isFeaturedOnThisDay && _normal?.y !== _featured?.y) ||
              // Not Featured, Pass
              (!_featured?.isFeaturedOnThisDay)
            ) {
              format += `<div class='title' style="font-size:13px;color:#5169f5">Organic:</div>`
              format += `<div class='wrapper'>`
              format += `<div class='box'>`
              format += `<div style="padding-right: 5px"><b>${Math.abs(point.y)}${type === "average" ? "%" : ""}</b></div>`
              format += `${topRankings[point.point.index] ? `<img src=${cup} />` : ""}`
              format += `${stars[point.point.index] ? `<img src=${star} />` : ""}`
              format += `</div>`
              format += `</div>`
              format += `${point.point.min ? `<div class='lowest'>Lowest Position</div><br/>` : ""}`
              format += `${point.point.max ? `<div class='best'>Best Position</div><br/>` : ""}`
              promotions?.length && promotionsDate.find((item) => item.date === moment(dates[point.x]).format("DD MMM YYYY"))
                ?
                format += `
                  <div class='ranking-separate-line'></div>
                  <div class='title' style='margin-bottom: 5px;'>${findPromotion(point.x).promotions[0].name}</div>
                  <div class='wrapper' style='margin-bottom: 5px;'>
                    <div>Promoted Price</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].promotedPrice, NUMBER_TYPE.CURRENCY)}</div>
                  </div>
                  <div class='wrapper'>
                    <div>Discount</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].discount, NUMBER_TYPE.DECIMAL)}%</div>
                  </div>
                `
                :
                format += ""
            }
          } else if (point?.series?.name == FEATURED) {
            if (_featured?.isFeaturedOnThisDay) {
              format += `<div class='title' style="font-size:13px;color:#a74dd1;">Featured:</div>`
              format += `<div class='wrapper'>`
              format += `<div class='box'>`
              format += `<div style="padding-right: 5px"><b>${Math.abs(point.y)}${type === "average" ? "%" : ""}</b></div>`
              format += `${topRankings[point.point.index] ? `<img src=${cup} />` : ""}`
              format += `${stars[point.point.index] ? `<img src=${star} />` : ""}`
              format += `</div>`
              format += `</div>`
              format += `${point.point.min ? `<div class='lowest'>Lowest Position</div><br/>` : ""}`
              format += `${point.point.max ? `<div class='best'>Best Position</div><br/>` : ""}`
              promotions?.length && promotionsDate.find((item) => item.date === moment(dates[point.x]).format("DD MMM YYYY"))
                ?
                format += `
                  <div class='ranking-separate-line'></div>
                  <div class='title' style='margin-bottom: 5px;'>${findPromotion(point.x).promotions[0].name}</div>
                  <div class='wrapper' style='margin-bottom: 5px;'>
                    <div>Promoted Price</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].promotedPrice, NUMBER_TYPE.CURRENCY)}</div>
                  </div>
                  <div class='wrapper'>
                    <div>Discount</div>
                    <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].discount, NUMBER_TYPE.DECIMAL)}%</div>
                  </div>
                `
                :
                format += ""
            }
          } else if (point?.series?.name == AVERAGE) {
            format += `<div class='title' style="font-size:13px;color:#c4bd5c">Average:</div>`
            format += `<div class='wrapper'>`
            format += `<div class='box'>`
            format += `<div style="padding-right: 5px"><b>${Math.abs(point.y)}${type === "average" ? "%" : ""}</b></div>`
            format += `${topRankings[point.point.index] ? `<img src=${cup} />` : ""}`
            format += `${stars[point.point.index] ? `<img src=${star} />` : ""}`
            format += `</div>`
            format += `</div>`
            format += `${point.point.min ? `<div class='lowest'>Lowest Position</div><br/>` : ""}`
            format += `${point.point.max ? `<div class='best'>Best Position</div><br/>` : ""}`
            promotions?.length && promotionsDate.find((item) => item.date === moment(dates[point.x]).format("DD MMM YYYY"))
              ?
              format += `
                <div class='ranking-separate-line'></div>
                <div class='title' style='margin-bottom: 5px;'>${findPromotion(point.x).promotions[0].name}</div>
                <div class='wrapper' style='margin-bottom: 5px;'>
                  <div>Promoted Price</div>
                  <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].promotedPrice, NUMBER_TYPE.CURRENCY)}</div>
                </div>
                <div class='wrapper'>
                  <div>Discount</div>
                  <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(point.x).promotions[0].discount, NUMBER_TYPE.DECIMAL)}%</div>
                </div>
              `
              :
              format += ""
          }
        }

        format += `</div>`

        return format
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default СurrentlyItemChart;
