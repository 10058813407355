import React, { useState, useEffect, useRef, useCallback } from "react";
import Highcharts from "highcharts";
import HC_more from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

// Components
import RowOfBrands from "components/RowOfBrands";
import { Checkbox } from "antd";

import RenderTooMoreData from "components/RenderTooMoreData";
import RenderNoData from "components/RenderNoData";

// Constants
import { converToThousands } from "utils/ratingHelper";
import { changeNameRetailer } from "utils/changeNameRetailer";

HC_more(Highcharts);

(function (H) {
  H.wrap(H.seriesTypes.bubble.prototype, "applyJitter", function (proceed) {
    var series = this,
      jitter = this.options.jitter,
      len = this.points.length,
      sLen = this.chart.series.length,
      seriesOffset = series.index / (sLen + 1);

    function unrandom(seed) {
      var rand = Math.sin(seed) * 10000;
      return rand - Math.floor(rand);
    }

    if (jitter) {
      this.points.forEach(function (point, i) {
        ["x", "y"].forEach(function (dim, j) {
          var axis,
            plotProp = "plot" + dim.toUpperCase(),
            min,
            max,
            translatedJitter;
          if (jitter[dim] && !point.isNull) {
            axis = series[dim + "Axis"];
            translatedJitter = jitter[dim] * axis.transA;
            if (axis && !axis.isLog) {
              min = Math.max(0, point[plotProp] - translatedJitter);
              max = Math.min(axis.len, point[plotProp] + translatedJitter);
              point[plotProp] = min + (max - min) * unrandom(i + j * len + seriesOffset);
              if (dim === "x") {
                point.clientX = point.plotX;
              }
            }
          }
        });
      });
    }
  });
})(Highcharts);

const RatingSummaryTopChart = (props) => {
  //Props
  const {
    data,
    currentChart,
    discountValues,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    hoverEl,
    setHoverEl,
    retailer,
    setRetailer,
    setClickedArray,
  } = props;

  // Constants
  const labelsStep = 10;

  // States
  const [pricings, setPricings] = useState([]);
  const [names, setNames] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [minZvalue, setMinZValue] = useState(0);
  const [minMaxXValue, setMinMaxXValue] = useState({ min: 0, max: 0 });
  const [allValuesAreEqual, setAllValuesAreEqual] = useState(false);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  const [isShrinked, setIsShrinked] = useState(false);

  // Refs
  const inputRef = useRef(null);
  const chartRef = useRef(null);

  // Initialize arrayOfDesireBrand with all items selected
  useEffect(() => {
    const allBrands = data[currentChart[0].value].map(({ item }) => item.name);
    setArrayOfDesiredBrand(allBrands);
  }, [data, currentChart, setArrayOfDesiredBrand]);

  // Initialize arrayOfDesiredBrand with all items selected
  useEffect(() => {
    const allBrands = data[currentChart[0].value].map(({ item }) => item.name);
    setArrayOfDesiredBrand(allBrands);
  }, [data, currentChart, setArrayOfDesiredBrand]);

  // Format data
  useEffect(() => {
    const dataToFormat = data[currentChart[0].value];
    const filteredData = arrayOfDesiredBrand.length
      ? dataToFormat.filter(({ item }) => arrayOfDesiredBrand.includes(item.name))
      : dataToFormat;

    const pricing = filteredData.map((el) => {
      const isIncluded = arrayOfDesiredBrand.includes(el.item.name);
      const isHovered = hoverEl === el.item.name;

      return {
        x: el.reviewCount,
        y: el.avgScore,
        z: el.productCount,
        name: el.item.name,
        color: el.item.color,
        labelrank: el.productCount,
        isIncluded: isIncluded,
        className:
          !isHovered && !isIncluded
            ? "not-hightlighted"
            : isIncluded || isHovered
            ? "hightlighted"
            : arrayOfDesiredBrand.length || hoverEl.length
            ? "not-hightlighted"
            : null,
        dataLabels: {
          allowOverlap:
            !arrayOfDesiredBrand.length && isHovered
              ? true
              : !arrayOfDesiredBrand.length
              ? false
              : arrayOfDesiredBrand.length && isIncluded
              ? false
              : true,
          className:
            !isHovered && !isIncluded
              ? "not-hightlighted-label"
              : isIncluded || isHovered
              ? "hightlighted-label"
              : arrayOfDesiredBrand.length || hoverEl.length
              ? "not-hightlighted-label"
              : null,
        },
      };
    });

    setPricings(pricing);

    const namesArr = data[currentChart[0].value].map(({ item }) => {
      return { name: item.name, color: item.color };
    });
    setNames(namesArr);
  }, [data, currentChart, discountValues, arrayOfDesiredBrand, hoverEl]);

  // Set minimal Zvalue
  useEffect(() => {
    if (pricings.length) {
      const valuesForZAxis = pricings.map(({ z }) => z);
      const minZValue = Math.min(...valuesForZAxis);
      const minXValue = Math.min(...pricings.map(({ x }) => x));
      const maxXValue = Math.max(...pricings.map(({ x }) => x));

      setMinZValue(minZValue);
      setMinMaxXValue({ min: minXValue, max: maxXValue });

      const allValuesAreEqual = allEqual(pricings.map(({ z }) => z));
      setAllValuesAreEqual(allValuesAreEqual);
    }
  }, [pricings]);

  const allEqual = (arr) => arr.every((val) => val === arr[0]);

  // Check for empty array
  useEffect(() => {
    if (pricings.length) {
      setEmptyData(false);
    } else {
      setEmptyData(true);
    }
  }, [pricings]);

  useEffect(() => {
    if (names.length > labelsStep) {
      if (!arrayOfDesiredBrand.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, arrayOfDesiredBrand]);

  const setMinValue = (minValue) => {
    return minValue - minValue / 2;
  };

  const setMaxValue = (maxValue) => {
    return maxValue + maxValue / 2;
  };

  const toggleBubbleSize = useCallback(() => {
    if (chartRef.current && chartRef.current.chart) {
      const newSize = isShrinked ? "125" : "25";
      chartRef.current.chart.series[0].update({ maxSize: newSize }, true);
      setIsShrinked(!isShrinked);
    }
  }, [isShrinked]);



  // Options
  const options = {
    chart: {
      type: "bubble",
      polar: false,
      animation: false,
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
      borderWidth: "",
      borderRadius: "",
      borderColor: "#000000",
    },
    legend: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      min: pricings.length === 1 || allEqual(pricings.map(({ x }) => x)) ? setMinValue(+minMaxXValue.min) : null,
      max: pricings.length === 1 || allEqual(pricings.map(({ x }) => x)) ? setMaxValue(+minMaxXValue.max) : null,
      lineColor: "#90909",
      tickColor: "transparent",
      labels: {
        formatter: function (e) {
          return `${converToThousands(this.value)}`;
        },
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
      title: {
        offset: 15,
        y: -30,
        text: "No. of Reviews",
      },
      offset: 30,
    },
    yAxis: {
      min: 0,
      softMin: 0,
      softMax: 5,
      startOnTick: false,
      endOnTick: false,
      allowDecimals: false,
      title: {
        offset: 15,
        x: 25,
        text: "Avg. Rating",
      },
      offset: 30,
      labels: {
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    tooltip: {
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function (e) {
        if (this) {
          return tooltipWithOptions(this);
        } else {
          return false;
        }
      },
    },

    plotOptions: {
      series: {
        clip: false,
        dataLabels: {
          useHTML: true,
          allowOverlap: false,
          style: {
            textOverflow: "clip",
            fontWeight: 800,
            color: "#fff",
            fontSize: "12px",
          },
          enabled: true,
          formatter: function (e) {
            const products = `Product${this.point.z > 1 ? "s" : ""}`;
            const productLengthInSpan = 55;
            let bubbleWidth = this.point.dlBox.width;
            if (bubbleWidth < productLengthInSpan) {
              return this.point.z;
            } else {
              return `<div class="column">
                                <span>${this.point.z}</span>
                                <span>${products}</span>
                            </div>`;
            }
          },
        },
        animation: {
          duration: 200,
        },
      },
      bubble: {
        minSize: 25,
        maxSize: 125,
        marker: {
          fillOpacity: 0.8,
          states: {
            hover: {
              fillOpacity: 1,
              zIndex: 999,
            },
          },
        },
      },
    },
    series: [
      {
        data: pricings,
        jitter: {
          x: 0.125,
          y: 0.125,
        },
        point: {
          events: {
            mouseOver: function () {
              const radius = this.graphic.radius * 1.5;
              this.graphic.attr({
                fillOpacity: 1,
                zIndex: 999,
              });
            },
            mouseOut: function () {
              this.graphic.attr({
                fillOpacity: 0.5,
                zIndex: 1,
              });
            },
            click: function () {
              this.graphic.attr({ zIndex: -1 });
            },
          },
        },
      },
    ],
  };
  const tooltipWithOptions = (thisObj) => {
    let key;
    if (thisObj.points) {
      key = thisObj.points[0].point;
    }
    if (thisObj.point) {
      key = thisObj.point;
    }
    const title = key.name;

    const tooltipContent = `<div class="wrapper-category-discount-cut">
    <div class='title'>${changeNameRetailer(title)}</div>
    <div class='wrapper-box-promotion'>
    
    <div class='wrapper'>
    <div class='box'>
      <div class='name'>Avg. Rating</div>
    </div>
    <div class='price'>${thisObj.y}</div>
    </div>
    
    <div class='wrapper'>
    <div class='box'>
    <div class='name'>No. of Reviews</div>
    </div>
    <div class='price'>${thisObj.x}</div>
    </div>
    
    <div class='wrapper'>
    <div class='box'>
    <div class='name'>No. of Products</div>
    </div>
    <div class='price'>${key.z}</div>
    </div>
    
    </div>
    </div>`;

    return tooltipContent;
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `calc(100% - 402px)` }}>
      {isTooMoreData ? <RenderTooMoreData style={{ top: 58 }} /> : null}
      <div className="chart-title-desc-rating">
        <p className="chart-desc">
          Based on the selected filters we have found {data.reviewCount} review{data.listingCount > 1 ? "s" : ""} with
          an average rating of
          {` ${(
            data[currentChart[0].value].map((retailer) => retailer.avgScore).reduce((acc, score) => acc + score, 0) /
            data[currentChart[0].value].length
          ).toFixed(2)} `}
          stars.
        </p>
        <div className="shrink-btn">
          <Checkbox checked={isShrinked} onChange={toggleBubbleSize}>
            Shrink Bubbles
          </Checkbox>
        </div>
      </div>
      {emptyData ? <RenderNoData /> : <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />}
      <div className="brand-labesl-row">
        <RowOfBrands
          names={names}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          labelsStep={labelsStep}
        />
      </div>
    </div>
  );
};

export default RatingSummaryTopChart;
