import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { tz } from "moment-timezone";
import { createAlert, testAlert, fetchAlertFilters } from "store/alerts/actions";
import { STATE_STATUSES } from "utils/statuses";
import useFilters from "hooks/useFilters";
import {
  setSelectAllProducts,
  setSelectAllRetailers,
  setSelectAllCategories,
  setSelectAllManufacturers,
  setSelectAllBrands,
} from "store/alerts/actions";

import AlertCruds from "components/Alerts/AlertCruds";
import LoaderBox from "components/LoaderBox";
import { checkEmail } from "utils/checkEmail";

const CreateAlert = ({ history }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectAllProducts(true));
    dispatch(setSelectAllRetailers(false));
    dispatch(setSelectAllCategories(false));
    dispatch(setSelectAllManufacturers(false));
    dispatch(setSelectAllBrands(false));
  }, []);

  const { status } = useSelector((state) => state.alerts);
  const { selectAllProducts, selectAllRetailers, selectAllCategories, selectAllManufacturers, selectAllBrands } =
    useSelector((state) => state.alerts);
  const { lastFilter } = useFilters();
  const {
    filters: { filters },
  } = useSelector((state) => state);
  const {
    filters: { filters: alertFilters },
  } = useSelector((state) => state.alerts);

  const retailers = useMemo(() => {
    const currentRetailers =
      !!filters.filters && !!filters.filters.sourceType && filters.filters.sourceType.map((el) => el.id);
    return currentRetailers;
  }, [filters]);

  const getFilterArray = (strFilter) =>
    typeof strFilter === "string"
      ? strFilter.split("|").reduce((acc, item) => (!!Number(item) ? [...acc, Number(item)] : acc), [])
      : [];

  const [isEmptyName, setIsEmptyName] = useState(false);
  const [isEmptyOften, setIsEmptyOften] = useState(false);
  const [isEmptyDay, setIsEmptyDay] = useState(false);
  const [isEmptTime, setIsEmptTime] = useState(false);
  const { email } = useSelector((state) => state.settingsYourProfile.settingsYourProfile);
  const userEmail = email;
  const [emails, setEmails] = useState([""]);

  const [queryParams, setQueryParams] = useState({
    name: "",
    sourceType: lastFilter && getFilterArray(lastFilter.sourceType),
    category: lastFilter && getFilterArray(lastFilter.category),
    manufacture: lastFilter && getFilterArray(lastFilter.manufacture),
    productBrand: lastFilter && getFilterArray(lastFilter.productBrand),
    productGroup: lastFilter && getFilterArray(lastFilter.productGroup),
    product: lastFilter && getFilterArray(lastFilter.product),
    pricing: {
      selected: false,
      changeRange: [0, 100],
      changeRangePercent: [0, 100],
    },
    promotion: {
      selected: false,
      mechanic: {
        priceCut: true,
        multiBuy: true,
        other: true,
      },
      discountRangePercent: [0, 100],
    },
    availability: {
      selected: false,
      available: false,
      unavailable: true,
    },
    listing: {
      selected: false,
      newly: true,
      reListed: true,
      deListed: true,
      range: [1, 104],
    },
    message: "",
    email: true,
    // sms: false,
    // whatsApp: false,
    isAllowEmpty: false,
    schedule: {
      type: "",
      days: [],
      time: "",
      timezone: tz.guess(),
    },
    share: {
      emails: emails[0].length ? emails : [],
    },
  });

  useEffect(() => {
    dispatch(
      fetchAlertFilters({
        sourceType: queryParams.sourceType.join("|"),
        productBrand: queryParams.productBrand.join("|"),
        category: queryParams.category.join("|"),
        manufacture: queryParams.manufacture.join("|"),
        product: queryParams.product.join("|"),
        save: false,
        isAlert: true,
      })
    );
  }, []);

  const setTextParams = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const params = {
    sourceType: queryParams.sourceType.join("|"),
    category: queryParams.category.join("|"),
    manufacture: queryParams.manufacture.join("|"),
    productBrand: queryParams.productBrand.join("|"),
    productGroup: queryParams.productGroup.join("|"),
    product: queryParams.product.join("|"),
    save: false,
    isAlert: true,
  };

  const setFiltersParams = (name, selectItems) => {
    const sendParams = { ...params, ...{ [name]: selectItems.join("|") } };
    if (name === "product") {
      dispatch(setSelectAllProducts(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        setQueryParams((prevState) => ({ ...prevState, product: ["All"] }));
        return;
      }
    } else if (name === "retailer") {
      dispatch(setSelectAllRetailers(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        setQueryParams((prevState) => ({ ...prevState, sourceType: ["All"] }));
        return;
      }
    } else if (name === "category") {
      dispatch(setSelectAllCategories(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        setQueryParams((prevState) => ({ ...prevState, category: ["All"] }));
        return;
      }
    } else if (name === "manufacture") {
      dispatch(setSelectAllManufacturers(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        setQueryParams((prevState) => ({ ...prevState, manufacture: ["All"] }));
        return;
      }
    } else if (name === "productBrand") {
      dispatch(setSelectAllBrands(selectItems.includes("All")));
      if (selectItems.includes("All")) {
        setQueryParams((prevState) => ({ ...prevState, productBrand: ["All"] }));
        return;
      }
    }
    dispatch(fetchAlertFilters(sendParams)).then((resolve) => {
      const filterNames = ["sourceType", "category", "manufacture", "productBrand", "product"];
      const { filters } = resolve.data;
      Object.keys(filters).forEach((item) => {
        if (filterNames.includes(item)) {
          const currentFilters = filters[item].map((item) => item.id);
          const selectItems = getFilterArray(sendParams[item]).filter((item) => currentFilters.includes(item));
          setQueryParams((prevState) => ({
            ...prevState,
            [item]: selectItems,
          }));
        }
      });
    });
  };

  const setMetricsParamsSlider = (name, subName, values) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], [subName]: values },
    }));
  };

  const setMetricsParamsCheckbox = (name, subName, nominal, value) => {
    if (nominal) {
      setQueryParams((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], [subName]: { ...prevState[name][subName], [nominal]: value } },
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        [name]: { ...prevState[name], [subName]: value },
      }));
    }
  };

  const setIsCheckMetric = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], ["selected"]: value },
    }));
  };

  const toggleMetricsParams = (name, values) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: values,
    }));
  };

  const setDeliveryParams = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setScheduleType = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ["schedule"]: { ...prevState["schedule"], ["type"]: value },
    }));
  };

  const setIsAllowEmpty = (name, value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setScheduleDays = (value) => {
    if (queryParams.schedule.days.includes(value)) {
      setQueryParams((prevState) => ({
        ...prevState,
        ["schedule"]: {
          ...prevState["schedule"],
          ["days"]: prevState["schedule"]["days"].filter((item) => item !== value),
        },
      }));
    } else {
      setQueryParams((prevState) => ({
        ...prevState,
        ["schedule"]: { ...prevState["schedule"], ["days"]: [...prevState["schedule"]["days"], value] },
      }));
    }
  };

  const setScheduleTime = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      ["schedule"]: { ...prevState["schedule"], ["time"]: value },
    }));
  };

  const validateFields = () => {
    const {
      name,
      schedule: { type, days, time },
    } = queryParams;

    const fields = [name, type, days, time];
    const actions = [setIsEmptyName, setIsEmptyOften, setIsEmptyDay, setIsEmptTime];

    fields.forEach((item, index) => {
      if (!item.length) {
        actions[index](true);
      } else {
        actions[index](false);
      }
    });

    if (type === "daily") {
      return [Boolean(name.length), Boolean(type.length), Boolean(time.length)].every((val) => val === true);
    } else {
      return [Boolean(name.length), Boolean(type.length), Boolean(days.length), Boolean(time.length)].every(
        (val) => val === true
      );
    }
  };

  const handleCreateAlert = () => {
    if (validateFields()) {
      const emailsValid = checkEmail(emails, userEmail);
      if (emailsValid) {
        const updateQueryParams = {
          ...queryParams,
          share: {
            ...queryParams.share,
            emails: emails.length ? emails : [],
          },
          product: selectAllProducts ? ["All"] : queryParams.product,
          sourceType: selectAllRetailers ? ["All"] : queryParams.sourceType,
          category: selectAllCategories ? ["All"] : queryParams.category,
          manufacture: selectAllManufacturers ? ["All"] : queryParams.manufacture,
          productBrand: selectAllBrands ? ["All"] : queryParams.productBrand,
        };
        dispatch(createAlert(updateQueryParams)).then(() => history.push("/alerts"));
      }
    }
  };

  const handleTestAlert = () => {
    if (queryParams.name.length) {
      const emailsValid = checkEmail(emails, userEmail);
      if (emailsValid) {
        const updateQueryParams = {
          ...queryParams,
          share: {
            ...queryParams.share,
            emails: emails.length ? emails : [],
          },
        };
        dispatch(testAlert(updateQueryParams));
      }
    } else {
      setIsEmptyName(true);
    }
  };

  const handleChangeInput = (value, indexVal) => {
    const values = emails.map((item, index) => {
      if (index === indexVal) {
        return (item = value);
      }
      return item;
    });
    setEmails(values);
  };

  const handleAddInput = () => {
    setEmails((prevState) => [...prevState, ""]);
  };

  const handleDeleteInput = (indexDel) => {
    const inputs = emails.filter((item, index) => index !== indexDel);
    setEmails(inputs);
  };

  return (
    <>
      <div className="main-title-page">Create NEW Nudge</div>
      <div className="main-tabs-box">
        <div className="main-box">
          {alertFilters && Object.entries(alertFilters).length ? (
            <div className="wrapper-box-relative">
              <AlertCruds
                queryParams={queryParams}
                setTextParams={setTextParams}
                setFiltersParams={setFiltersParams}
                setMetricsParamsSlider={setMetricsParamsSlider}
                setMetricsParamsCheckbox={setMetricsParamsCheckbox}
                setIsCheckMetric={setIsCheckMetric}
                toggleMetricsParams={toggleMetricsParams}
                setDeliveryParams={setDeliveryParams}
                setScheduleType={setScheduleType}
                setScheduleDays={setScheduleDays}
                setScheduleTime={setScheduleTime}
                setIsAllowEmpty={setIsAllowEmpty}
                retailers={retailers}
                filters={{
                  sourceType: alertFilters.sourceType,
                  category: alertFilters.category,
                  manufacture: alertFilters.manufacture,
                  productBrand: alertFilters.productBrand,
                  product: alertFilters.product,
                }}
                errorField={{ isEmptyName, isEmptyOften, isEmptyDay, isEmptTime }}
                disabled={false}
                emails={emails}
                handleAddInput={handleAddInput}
                handleChangeInput={handleChangeInput}
                handleDeleteInput={handleDeleteInput}
              />
              <div className="alert-btn-wrapper">
                <div className="nudge-btn" onClick={handleCreateAlert}>
                  Create a Nudge
                </div>
                <div className="test-btn" onClick={handleTestAlert}>
                  Send Test
                </div>
              </div>
            </div>
          ) : null}

          {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
        </div>
      </div>
    </>
  );
};

export default withRouter(CreateAlert);
