import { combineReducers } from "redux";

import authorization from "store/authorization/reducers";
import filters from "store/filters/reducers";
import tempFilters from "store/tempFilters/reducers";
import universalFilters from "store/universalFilters/reducers";
//pricing
import priceTrend from "store/pricing/priceTrend/reducers";
import currentPrices from "store/pricing/currentPrices/reducers";
import priceChange from "store/pricing/priceChange/reducers";
import priceComparison from "store/pricing/priceComparison/reducers";
import priceRanking from "store/pricing/priceRanking/reducers";
import priceRange from "store/pricing/priceRange/reducers";
//promotions
import promotionBreakdown from "store/promotions/pormotionBreakdown/reducers";
import summaryPromotions from "store/promotions/summaryPromotions/reducers";
import discountCut from "store/promotions/promotionsDiscountCut/reducers";
import promotionsCalendar from "store/promotions/promotionsCalendar/reducers";
//ranking
import rankingSearchOverview from "store/ranking/rankingSearchOverview/reducers";
import rankingSearchRetailer from "store/ranking/rankingSearchRetailer/reducers";
import rankingSearchAnalysis from "store/ranking/rankingSearchAnalysis/reducers";
//content
import currentContent from "store/content/currentContent/reducers";
import currentContentTable from "store/content/currentContentTable/reducers";
import keyContent from "store/content/keyContent/reducers";
import benchmarkContent from "store/content/benchmarkContent/reducers";
import contentTrend from "store/content/contentTrend/reducers";
//rating
import ratingSummary from "store/rating/ratingSummary/reducers";
import ratingSummaryBottom from "store/rating/ratingSummaryBottom/reducers";
import ratingSummaryProduct from "store/rating/ratingSummaryProduct/reducers";
import productInsight from "store/rating/productInsight/reducers";
import productInsightAll from "store/rating/productInsight/reducers";
import trendAnalysis from "store/rating/trendAnalysis/reducers";
import trendAnalysisTable from "store/rating/trendAnalysisTable/reducers";
import trendAnalysisMovers from "store/rating/trendAnalysis/reducers";
import trendAnalysisMoversAll from "store/rating/trendAnalysis/reducers";
import trendAnalysisAll from "store/rating/trendAnalysis/reducers";
import trendAnalysisTableAll from "store/rating/trendAnalysisTable/reducers";

//media
import mediaSummaryTop from "store/media/mediaSummaryTop/reducers";
import mediaSummaryBottom from "store/media/mediaSummaryBottom/reducers";
import shareOfMediaTop from "store/media/shareOfMediaTop/reducers";
import shareOfMediaBottom from "store/media/shareOfMediaBottom/reducers";
import mediaSummaryDetails from "store/media/mediaDetails/reducers";
//availability
import availabilitySummary from "store/availability/availabiltySummary/reducers";
import retailerAvailability from "store/availability/retailerAvailability/reducers";
//navigation
import navigationShareProducts from "store/navigation/navigationShareProducts/reducers";
import navigationLocationOverview from "store/navigation/navigationLocationOverview/reducers";
import navigationShareTrend from "store/navigation/navigationShareTrend/reducers";
import navigationLocationAnalysis from "store/navigation/navigationLocationAnalysis/reducers";
//assortment
import assortmentSummaryTop from "store/assortment/assortmentSummaryTop/reducers";
import assortmentSummaryBottom from "store/assortment/assortmentSummaryBottom/reducers";
import assortmentSummaryCalendar from "store/assortment/assortmentSummaryCalendar/reducers";
import assortmentChange from "store/assortment/assortmentChange/reducers";
import assortmentDetails from "store/assortment/assortmentDetails/reducers";
//Alerts
import alerts from "store/alerts/reducers";
//setting
import settings from "store/settings/reducers";
import settingsProductGroups from "store/settings/settingsProductGroups/reducers";
import settingsYourProfile from "store/settings/settingsYourProfile/reducers";
import settingsChangePassword from "store/settings/settingsChangePassword/reducer";
import settingsSubscriptions from "store/settings/settingsSubscriptions/reducer";
import settingsWatchlist from "store/settings/SettingsWatchlist/reducers";

//export
import exports from "store/exports/reducers";

//Error
import errorReducer from "./error/reducers";

export default combineReducers({
  authorization,
  filters,
  tempFilters,
  universalFilters,
  priceTrend,
  currentPrices,
  priceChange,
  priceComparison,
  priceRanking,
  priceRange,
  promotionBreakdown,
  summaryPromotions,
  discountCut,
  promotionsCalendar,
  rankingSearchOverview,
  rankingSearchRetailer,
  currentContent,
  currentContentTable,
  rankingSearchAnalysis,
  keyContent,
  benchmarkContent,
  contentTrend,
  ratingSummary,
  ratingSummaryBottom,
  trendAnalysis,
  trendAnalysisMovers,
  trendAnalysisMoversAll,
  mediaSummaryTop,
  ratingSummaryProduct,
  productInsight,
  productInsightAll,
  trendAnalysisAll,
  trendAnalysisTable,
  trendAnalysisTableAll,
  mediaSummaryBottom,
  shareOfMediaTop,
  shareOfMediaBottom,
  mediaSummaryDetails,
  navigationShareProducts,
  navigationLocationOverview,
  navigationShareTrend,
  navigationLocationAnalysis,
  settingsProductGroups,
  settingsYourProfile,
  settingsChangePassword,
  settingsSubscriptions,
  settingsWatchlist,
  availabilitySummary,
  retailerAvailability,
  assortmentSummaryTop,
  assortmentSummaryBottom,
  assortmentSummaryCalendar,
  assortmentChange,
  assortmentDetails,
  settings,
  alerts,
  exports,
  errorReducer,
});
