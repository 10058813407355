import React, { useState } from 'react'
import { Modal } from 'antd'
import useRetailers from "../../../hooks/useRetailers";

import sort from 'assets/images/sort.svg'
import sortIconUp from 'assets/images/sort_up.svg'
import sortIconDown from 'assets/images/sort_down.svg'

const PopupSerchTerm = (props) => {

    const { type, name, data, retailers, titlePopup, showPopup, setShowPopup } = props

    const [sortParam, setSortParam] = useState('manufacturer')
    const [retailerIndex, setRetailerIndex] = useState(null)
    const [directionSort, setDirectionSort] = useState(true)
    const { getRemoteLogo } = useRetailers();

    const sortData = (data) => {
        switch(sortParam) {
            case 'manufacturer': {
                return data.sort((first, second) => {
                    if(directionSort) {
                        if (second.title > first.title) {
                            return -1;
                        }
                    } else {
                        if (first.title > second.title) {
                            return -1;
                        }
                    }
                })
            }
            case 'total':
            case 'retailer': {
                return data.sort((first, second) => {
                    if(directionSort) {
                        return Number(second.share[retailerIndex].percent) - Number(first.share[retailerIndex].percent)
                    } else {
                        return Number(first.share[retailerIndex].percent) - Number(second.share[retailerIndex].percent)
                    }
                })
            }
            default: {
                break
            }
        }
    }

    const sortByManufacturer = () => {
        if(sortParam !== 'manufacturer') {
            setDirectionSort(true)
        } else {
            setDirectionSort(!directionSort)
        }
        setSortParam('manufacturer')
        setRetailerIndex(null)
    }

    const sortByTotal = () => {
        if(sortParam !== 'total') {
            setDirectionSort(true)
        } else {
            setDirectionSort(!directionSort)
        }
        setSortParam('total')
        setRetailerIndex(0)
    }

    const sortByRetailer = (index) => {
        if(retailerIndex !== index+1) {
            setDirectionSort(true)
        } else {
            setDirectionSort(!directionSort)
        }
        setSortParam('retailer')
        setRetailerIndex(index+1)
    }

    return (
        <Modal
            className='ranking-page-modal'
            visible={showPopup}
            onCancel={()=> setShowPopup(false)}
        >
            <div className='table-popup-wrapper'>
                <div className='title-popup-ranking'>{titlePopup} <span>{name}</span></div>
                <div className='table-row-header'>
                    <div 
                        className='manufacturer'
                        onClick={sortByManufacturer}
                    >
                        <div>Manufacturer</div>
                        <img src={ sortParam === 'manufacturer' ? directionSort ? sortIconUp : sortIconDown : sort} alt='sort' />
                    </div>
                    <div 
                        className='total'
                        onClick={sortByTotal}
                    >
                        <div>Total</div>
                        <img src={ sortParam === 'total' ? directionSort ? sortIconUp : sortIconDown : sort} alt='sort' />
                    </div>
                    {retailers.map((item, index) => 
                        <div 
                            key={index}
                            className='retailer'
                            onClick={() => sortByRetailer(index)}
                        >
                            {
                                type === 'bySearchTerm' ?
                                <img src={getRemoteLogo(item)} alt='retailer' />
                                :
                                <span>{item}</span>

                            }
                            <img src={ retailerIndex === index+1 ? directionSort ? sortIconUp : sortIconDown : sort} alt='sort' />
                        </div>
                    )}
                </div>
                <div className='table-row-body-wrapper'>
                    {
                        sortData(data).map((item, index) => 
                                <div className='table-row-body' key={index}>
                                    <div className='title'>
                                        <div className='color' style={{background: item.color}}></div>
                                        <div>{item.title}</div>
                                    </div>
                                    { item.share.map((item, index) => 
                                        <div 
                                            key={index}
                                            className='percent'
                                        >
                                            {Number(item.percent)}%
                                        </div>
                                    )}
                                </div>
                        )
                    }
                </div>
            </div>
        </Modal>
    )
}

export default PopupSerchTerm