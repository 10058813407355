import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import useColorTheme from "../../../../hooks/useColorTheme";

import { changeNameRetailer } from "utils/changeNameRetailer";

function smoothPercentages(data) {
  let smoothedData = [...data];

  for (let i = 1; i < smoothedData.length - 1; i++) {
    if (smoothedData[i].percent === 0) {
      let prev = smoothedData[i - 1].percent;
      let next = null;
      let j = i + 1;

      while (j < smoothedData.length) {
        if (smoothedData[j].percent !== 0) {
          next = smoothedData[j].percent;
          break;
        }
        j++;
      }

      if (next !== null) {
        let step = (next - prev) / (j - i + 1);
        for (let k = i; k < j; k++) {
          smoothedData[k].percent = prev + step * (k - i + 1);
        }
      } else {
        for (let k = i; k < smoothedData.length; k++) {
          smoothedData[k].percent = prev;
        }
        break;
      }
    }
  }
  return { data: smoothedData };
}

const CurrentlyTrendChart = (props) => {
  const { primaryColor, primaryColorRgb } = useColorTheme();
  const { data, type } = props;
  const smoothData = data.data.length > 0 ? smoothPercentages(data.data) : data;

  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);

  useEffect(() => {
    if (data && Object.entries(data).length !== 0) {
      if (type === "byTotal") {
        const pricings = [
          {
            name: "term",
            color: primaryColor,
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: [
                [0, `${primaryColorRgb(0.2)}`],
                [1, `${primaryColorRgb(0)}`],
              ],
            },
            data: smoothData.data.map((item) => Number(item.percent)),
          },
        ];

        const datesMoment = smoothData.data.map((item) => moment(item.date).format("DD MMM YYYY"));

        setPricings(pricings);
        setDates(datesMoment);
      } else {
        const pricings = smoothData.share.map((item) => ({
          name: item.title,
          color: item.color,
          fillColor: 0,
          data: item.data.map((item) => Number(item.percent)),
        }));

        const datesMoment = data.share[0].data.map((item) => moment(item.date).format("DD MMM YYYY"));

        setPricings(pricings);
        setDates(datesMoment);
      }
    }
  }, [data, type]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      reflow: true,
      height: 280,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
        style: {
          fontSize: "10px",
        },
      },
      categories: dates,
    },
    yAxis: {
      title: {
        text: "",
      },
      // labels: {
      //   formatter: function () {
      //     return `${this.value}%`;
      //   },
      // },
      minTickInterval: 0.1,
      labels: {
        format: `{value:.2f}%`,
        style: {
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      followPointer: false,
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        let tooltipData;

        if (type === "byTotal") {
          tooltipData = `
            <div class='wrapper'>
              <div class='box'>
                <div>${this.points[0].y}%</div>
              </div>
            </div>
          `;
        } else {
          tooltipData = this.points
            .sort((a, b) => b.y - a.y)
            .map((item) => {
              return `<div class='wrapper'>
                  <div class='box'>
                      <div class='color' style='background: ${item.color}'></div>
                      <div class='name'>${changeNameRetailer(item.series.options.name)}</div>
                  </div>
                  <div class='price'>${item.y}%</div>
                </div>`;
            });
          tooltipData = tooltipData.join("");
        }

        return `<div class="wrapper-category">
            <div class='title'>Search Trend</div>
            <div class='total-data'>${moment(this.points[0].key).format("DD MMM YY")}</div>
            ${tooltipData}
          </div>`;
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default CurrentlyTrendChart;
