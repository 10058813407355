const url = process.env.REACT_APP_URL;

//Navigation Location Analysis
export const FETCH_NAVIGATION_LOCATION_ANALYSIS = "FETCH_NAVIGATION_LOCATION_ANALYSIS";
export const fetchNavigationLocationAnalysis = (data) => ({
  type: FETCH_NAVIGATION_LOCATION_ANALYSIS,
  request: {
    url: `${url}/v2/ranking/location-analysis`,
    method: "POST",
    data,
  },
});

export const SET_CURRENT_ITEM_TERM_LOCATION = "SET_CURRENT_ITEM_TERM_LOCATION";
export const setsetCurrentItemTermLocation = (data) => {
  return {
    type: SET_CURRENT_ITEM_TERM_LOCATION,
    payload: data,
  };
};

export const SET_CURRENT_SHARE_LOCATION = "SET_CURRENT_SHARE_LOCATION";
export const setCurrentShareLocation = (retailer) => {
  return {
    type: SET_CURRENT_SHARE_LOCATION,
    payload: retailer,
  };
};

export const SET_TERM_PRODUCT = "SET_TERM_PRODUCT";
export const setTermProduct = (product) => {
  return {
    type: SET_TERM_PRODUCT,
    payload: product,
  };
};

export const FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS = "FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS";
export const fetchNavigationLocationAnalysisProducts = (data) => ({
  type: FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
});

export const FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP = "FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP";
export const fetchNavigationLocationAnalysisProductsPopup = (productId, data) => ({
  type: FETCH_NAVIGATION_LOCATION_ANALYSIS_PRODUCTS_POPUP,
  request: {
    url: `${url}/v2/ranking/search-analysis/products/${productId}`,
    method: "POST",
    data,
  },
});

export const SET_SHOW_PRODUCT_LOCATION_POPUP = "SET_SHOW_PRODUCT_LOCATION_POPUP";
export const setShowProductLocationPopup = (isShow) => {
  return {
    type: SET_SHOW_PRODUCT_LOCATION_POPUP,
    payload: isShow,
  };
};

export const FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY = "FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY";
export const fetchNavigationLocationAnalysisSingleDay = (data) => ({
  type: FETCH_NAVIGATION_LOCATION_ANALYSIS_SINGLE_DAY,
  request: {
    url: `${url}/v2/ranking/search-analysis/charts`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_CURRENT_SHARE_LOCATION_SINGLE_DAY = "SET_CURRENT_SHARE_LOCATION_SINGLE_DAY";
export const setCurrentShareLocationSingleDay = (retailer) => {
  return {
    type: SET_CURRENT_SHARE_LOCATION_SINGLE_DAY,
    payload: retailer,
  };
};

export const SET_PRISTINE_ANALYSIS_LOCATION = "SET_PRISTINE_ANALYSIS_LOCATION";

export const FETCH_ANALYSIS_SINGLE_PRODUCT = "FETCH_ANALYSIS_SINGLE_PRODUCT";
export const fetchAnalysisSingleProduct = (data, isSingle) => ({
  type: FETCH_ANALYSIS_SINGLE_PRODUCT,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
    isSingle,
  },
});

export const FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY = "FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY";
export const fetchAnalysisSingleProductSummary = (data) => ({
  type: FETCH_ANALYSIS_SINGLE_PRODUCT_SUMMARY,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    takeLatest: false,
    asPromise: true,
  },
});

export const FETCH_ANALYSIS_SINGLE_PRODUCT_SET_PERIOD = "FETCH_ANALYSIS_SINGLE_PRODUCT_SET_PERIOD";
export const analysisSingleProductSetPeriod = (data) => {
  return {
    type: FETCH_ANALYSIS_SINGLE_PRODUCT_SET_PERIOD,
    payload: data,
  };
};

export const REMOVE_ANALYSIS_PRODUCT = "REMOVE_ANALYSIS_PRODUCT";

export const FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE = "FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE";
export const fetchAnalysisSingleProductCompare = (data) => ({
  type: FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY = "FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY";
export const fetchAnalysisSingleProductCompareSummary = (data) => ({
  type: FETCH_ANALYSIS_SINGLE_PRODUCT_COMPARE_SUMMARY,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    takeLatest: false,
    asPromise: true,
  },
});

export const SET_ANALYSIS_SINGLE_PRODUCT_COMPARE = "SET_ANALYSIS_SINGLE_PRODUCT_COMPARE";
export const analysisSingleProductSetCompare = (data) => {
  return {
    type: SET_ANALYSIS_SINGLE_PRODUCT_COMPARE,
    payload: data,
  };
};
