import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Empty, Popover } from "antd";
//Components
import { Styles } from "./styles";
import RenderNoData from "components/RenderNoData";
//Utils
import useRetailers from "../../../../../../hooks/useRetailers";
import { changeNameRetailer } from "utils/changeNameRetailer";

//Images
import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import gray from "assets/images/gray.svg";
import placeholder from "assets/images/placeholder.svg";

const HighestRated = ({ moversData }) => {

  //Selectors
  const { filter, trendAnalysis, trendAnalysisAll } = useSelector((state) => state.trendAnalysis);

  //byTotal
  const byTotalData = moversData.data.byTotal.result

  const products = [...new Set(byTotalData.flatMap(item => item.title))];

  let avgRatingByProductArr = [];

  for (let p of products) {
    let ratingsCount = 0;
    let ratings = 0;

    for (let item of byTotalData) {
      if (item.title.includes(p)) {
        let totalRatings = parseInt(item.rating1) + parseInt(item.rating2) + parseInt(item.rating3) + parseInt(item.rating4) + parseInt(item.rating5);
        let weightedRatings = (parseInt(item.rating1) * 1) + (parseInt(item.rating2) * 2) + (parseInt(item.rating3) * 3) + (parseInt(item.rating4) * 4) + (parseInt(item.rating5) * 5);
        ratingsCount += totalRatings;
        ratings += weightedRatings;
      }
    }

    if (ratingsCount !== 0) {
      let avgRating = (ratings / ratingsCount).toFixed(2);
      avgRatingByProductArr.push({
        "productTitle": p,
        "avgRating": avgRating
      });
    }
  }

  //byBrand
  const byBrandData = moversData.data.byBrand.result
  const brands = [...new Set(byBrandData.flatMap(item => item.groupName))];

  let avgRatingByBrandArr = [];

  for (let b of brands) {
    let ratingsCount = 0;
    let ratings = 0;

    for (let item of byBrandData) {
      if (item.groupName.includes(b)) {
        let totalRatings = parseInt(item.rating1) + parseInt(item.rating2) + parseInt(item.rating3) + parseInt(item.rating4) + parseInt(item.rating5);
        let weightedRatings = (parseInt(item.rating1) * 1) + (parseInt(item.rating2) * 2) + (parseInt(item.rating3) * 3) + (parseInt(item.rating4) * 4) + (parseInt(item.rating5) * 5);
        ratingsCount += totalRatings;
        ratings += weightedRatings;
      }
    }

    if (ratingsCount !== 0) {
      let avgRating = (ratings / ratingsCount).toFixed(2);
      avgRatingByBrandArr.push({
        "brandName": b,
        "avgRating": avgRating
      });
    }
  }

  //byRetailer
  const byRetailerData = moversData.data.byRetailer.result
  const retailers = [...new Set(byRetailerData.flatMap(item => item.groupName))];

  let byRetailerDataArr = [];

  for (let r of retailers) {
    let ratingsCount = 0;
    let ratings = 0;

    for (let item of byRetailerData) {
      if (item.groupName.includes(r)) {
        let totalRatings = parseInt(item.rating1) + parseInt(item.rating2) + parseInt(item.rating3) + parseInt(item.rating4) + parseInt(item.rating5);
        let weightedRatings = (parseInt(item.rating1) * 1) + (parseInt(item.rating2) * 2) + (parseInt(item.rating3) * 3) + (parseInt(item.rating4) * 4) + (parseInt(item.rating5) * 5);
        ratingsCount += totalRatings;
        ratings += weightedRatings;
      }
    }

    if (ratingsCount !== 0) {
      let avgRating = (ratings / ratingsCount).toFixed(2);
      byRetailerDataArr.push({
        "retailerName": r,
        "avgRating": avgRating
      });
    }
  }


  const { getRemoteLogo } = useRetailers();

  //States
  const [movers, setMovers] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [popupData, setPopupData] = useState({});
  //Constants
  const byReviews = filter[0].value === "count";
  const byTotal = filter[1].value === "total";
  const byBrand = filter[1].value === "brand";
  const byRetailer = filter[1].value === "retailer";
  const title = byReviews ? "Most Rated" : "Highest Rated";

  useEffect(() => {
    if(filter[2].value) {
      if (trendAnalysisAll.data[filter[1].value].topList[filter[0].value].length) {
        setMovers(trendAnalysisAll.data[filter[1].value].topList[filter[0].value]);
        setEmptyData(false);
      } else {
        setEmptyData(true);
        setMovers([]);
      }
    } else {
      if (trendAnalysis.data[filter[1].value].topList[filter[0].value].length) {
        setMovers(trendAnalysis.data[filter[1].value].topList[filter[0].value]);
        setEmptyData(false);
      } else {
        setEmptyData(true);
        setMovers([]);
      }
    } 
  }, [trendAnalysis, trendAnalysisAll, filter]);

  //Process top movers
  if (byTotal && !byReviews) {
    movers.map(mover => {
      avgRatingByProductArr.map(item => {
        if (item.productTitle === mover.title) {
          mover.avgRating = item.avgRating;
        }
      });
    }
    )
    movers.sort((a, b) => b.avgRating - a.avgRating);
  }
  else if (byBrand && !byReviews) {
    movers.map(mover => {
      avgRatingByBrandArr.map(item => {
        if (item.brandName === mover.name) {
          mover.avgRating = item.avgRating;
        }
      });
    }
    )
    movers.sort((a, b) => b.avgRating - a.avgRating);
  } else if (byRetailer && !byReviews) {
    movers.map(mover => {
      byRetailerDataArr.map(item => {
        if (item.retailerName === mover.name) {
          mover.avgRating = item.avgRating;
        }
      });
    }
    )
    movers.sort((a, b) => b.avgRating - a.avgRating);
  }

  if (byTotal && byReviews) {
    movers.map(mover => {
      avgRatingByProductArr.map(item => {
        if (item.productTitle === mover.title) {
          mover.avgRating = item.avgRating;
        }
      });
    }
    )
    movers.sort((a, b) => b.reviewCount - a.reviewCount);
  }
  else if (byBrand && byReviews) {
    movers.map(mover => {
      avgRatingByBrandArr.map(item => {
        if (item.brandName === mover.name) {
          mover.avgRating = item.avgRating;
        }
      });
    }
    )
    movers.sort((a, b) => b.reviewCount - a.reviewCount);
  } else if (byRetailer && byReviews) {
    movers.map(mover => {
      byRetailerDataArr.map(item => {
        if (item.retailerName === mover.name) {
          mover.avgRating = item.avgRating;
        }
      });
    }
    )
    movers.sort((a, b) => b.reviewCount - a.reviewCount);
  }

  const popupContent = () => {
    return (
      <div className="popup-wrapper">
        <div className="popup-row">
          <span>Avg. Rating</span>
          <span>{popupData.avgRating !== null ? popupData.avgRating : 'N/A'}</span>
        </div>
        <div className="popup-row">
          <span>No. of Reviews</span>
          <span>{popupData.reviewCount}</span>
        </div>
        <div className="popup-row">
          <span>{byReviews ? "Reviews" : "Rating"} Change</span>
          <span>
            <img
              className="direction"
              src={filter[2].value ? green : (byReviews ? (popupData.countChange === null || popupData.countChange === 0 ? gray : popupData.countChange > 0 ? green : red) : (popupData.avgChange === null || popupData.avgChange === "0.00" || popupData.avgChange === "-0.00" ? gray : popupData.avgChange > "0.00" ? green : red))}
              style={{
                transform: filter[2].value ? null : (byReviews ? (popupData.countChange === null || popupData.countChange === 0 ? `rotate(90deg)` : null) : (popupData.avgChange === null || popupData.avgChange === "0.00" || popupData.avgChange === "-0.00" ? `rotate(90deg)` : null)),
              }}
              alt="direction"
            />{" "}
            {byReviews ? popupData.countChange : popupData.avgChange === null ? "0.00" : Math.abs(popupData.avgChange)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Styles>
      <div className="filters">
        <span>{title}</span>
      </div>
      <div className="topMovers">
        {emptyData ? (
          <div className="empty-wrapper">
            <div className="no-data-box">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        ) : null}

        {movers.map((item, index) => {
          return (
            <Popover
              key={index}
              content={popupContent}
              title={byTotal ? item.title : changeNameRetailer(item.name || '')}
              trigger="hover"
            >
              <div className="row" key={index} onMouseOver={() => setPopupData(item)}>
                <img
                  className="image"
                  src={byBrand ? placeholder : byRetailer ? getRemoteLogo(item.name) : item.image}
                  alt="banner"
                  style={{ background: byBrand ? item.color : null }}
                />

                <span className="title">{byTotal ? item.title : changeNameRetailer(item.name)}</span>
                <span>
                  {
                    byBrand
                      ? (byReviews ? item.reviewCount : item.avgRating !== null ? item.avgRating : 'N/A')
                      : (byTotal
                        ? (byReviews ? item.reviewCount : item.avgRating !== null ? item.avgRating : 'N/A')
                        : (byRetailer ? (byReviews ? item.reviewCount : item.avgRating !== null ? item.avgRating : 'N/A') : null))
                  }
                </span>
                <img
                  className="direction"
                  src={byReviews ? (item.countChange === null || item.countChange === 0 ? gray : item.countChange > 0 ? green : red) : (item.avgChange === null || item.avgChange === "0.00" || item.avgChange === "-0.00" ? gray : item.avgChange > 0.00 ? green : red)}
                  style={{
                    transform: (byReviews ? (item.countChange === null || item.countChange === 0 ? `rotate(90deg)` : null) : (item.avgChange === null || item.avgChange === "0.00" || item.avgChange === "-0.00" ? `rotate(90deg)` : null)),
                  }}
                  alt="direction"
                />
              </div>
            </Popover>
          );
        })}
      </div>
    </Styles>
  );
};

export default HighestRated;
