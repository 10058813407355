import React, { useEffect, useState } from "react";
import { Slider } from "antd";

const SliderBox = ({ values, setValues }) => {
  const [minmax, setMinmax] = useState({ min: 0, max: 100 });

  useEffect(() => {
    setMinmax({ min: values[0], max: values[1] });
  }, []);

  const handleSliderChange = (newValues) => {
    if (minmax.min === 1) {
      setValues([1, newValues[1]]);
    } else {
      setValues(newValues);
    }
  };

  const handleInputChange = (index, event) => {
    const newValue = Number(event.target.value);
    if (index === 0) {
      setValues([newValue, values[1]]);
    } else {
      setValues([values[0], newValue]);
    }
  };

  return (
    <div>
      <Slider range min={minmax.min} max={minmax.max} value={values} onChange={handleSliderChange} />
      <div className="wrapper-slider-inputs">
        <input type="text" disabled={true} value={values[0]} />
        <div className="separate">-</div>
        <input type="text" disabled={false} value={values[1]} onChange={(e) => handleInputChange(1, e)} />
      </div>
    </div>
  );
};

export default SliderBox;
