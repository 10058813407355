import React from "react";
import { Empty } from "antd";

const RenderNoData = ({ style }) => {
  return (
    <div className="emptyData" style={style}>
      <Empty
        // image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>Looks like we don't have data for this request</span>}
      />
    </div>
  );
};

export default RenderNoData;
